import Footer from "./components/footer";
import Header from "./components/header";
import PrivacyNotice from "./components/privacynotice/privacynotice";

function Layout(props) {
    return (
        <div>
            <Header />
            <div className="content">{props.children}</div>
            <Footer />
            <PrivacyNotice />
        </div>
    );
}

export default Layout;
