import React from "react";
import "./testimonialscard.css";

const TestimonialsCard = ({ testimaonialsInfo }) => {
    return (
        <div className="testimonialscard shadow-sm shadow shadow-lg bg-white mx-auto">
            <div className="card-body text-center">
                <h2
                    className="card-title mb-3"
                    style={{
                        textAlign: "left",
                        fontWeight: "700",
                        fontSize: "20px",
                        color: "#0D0D0D",
                        lineHeight: "24px",
                    }}
                >
                    {testimaonialsInfo.title}
                </h2>

                <p className="testimonial-card-desc1">
                    {testimaonialsInfo.desc1}
                </p>
                {testimaonialsInfo.desc2 && (
                    <p className="testimonial-card-desc2">
                        {testimaonialsInfo.desc2}
                    </p>
                )}
                {testimaonialsInfo.desc3 && (
                    <p className="testimonial-card-desc3">
                        {testimaonialsInfo.desc3}
                    </p>
                )}
                {testimaonialsInfo.desc4 && (
                    <p className="testimonial-card-desc4">
                        {testimaonialsInfo.desc4}
                    </p>
                )}
                {testimaonialsInfo.desc5 && (
                    <p className="testimonial-card-desc5">
                        {testimaonialsInfo.desc5}
                    </p>
                )}

                <h5 className="testimonial-name">{testimaonialsInfo.name}</h5>
                {testimaonialsInfo.occupation && (
                    <p className="testimonial-occupation">
                        {testimaonialsInfo.occupation}
                    </p>
                )}
                {testimaonialsInfo.agency && (
                    <p className="testimonial-agency">
                        {testimaonialsInfo.agency}
                    </p>
                )}
                {testimaonialsInfo.company && (
                    <p className="testimonial-company">
                        {testimaonialsInfo.company}
                    </p>
                )}
            </div>
        </div>
    );
};

export default TestimonialsCard;
