import React from "react";
import "./innerpageheader.css";

const Innerpageheading = ({ pageHeadInfo }) => {
    return (
        <div className="innerHeader">
            <h1>{pageHeadInfo.heading}</h1>
            <div className="holder">
                <div className="text-center">
                    <p>{pageHeadInfo.data}</p>
                </div>
            </div>
        </div>
    );
};

export default Innerpageheading;
