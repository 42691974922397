import React, { useState, useRef } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import "./contactus.css";
import IconButton from "../buttons/iconButton";
import Innerpageheading from "../common/innerpageheading";
import Breadcrumb from "../common/breadcrumb";
import Innerpageinnerheading from "../common/innerpageinnerheading";
import { contactUS } from "../common/breadcrumbs";
import { contactUSPageHeader } from "../common/pageheader";
import { CGI_PATH, CJ_ADDRESS, CJ_PHONE, US_STATES } from "../../constants";
import Toasts from "./Toasts";
import {
    allowASCIIValues,
    checkAndAddFieldErrorMessage,
    isValidEmail,
    normalizeInput,
    removeAlpha,
    submitForm,
} from "../../components/forms/FormHelpers";
import ReCAPTCHA from "react-google-recaptcha";
import { ReCaptchaKey } from "../../constants";

const ContactUs = () => {
    const captchaRef = useRef(null);

    const innerText =
        "Charles Jones provides reliable searches and information services to a growing range of customers, including lawyers and title companies.";

    const defaultForm = {
        full_name: "",
        company_name: "",
        phone: "",
        fax: "",
        email: "",
        street_1: "",
        street_2: "",
        city: "",
        state: "",
        zip: "",
        message: "",
        captcha: "",
    };

    function resetCaptcha() {
        setShowCaptchaError(false);
    }

    const [showSuccessToast, setShowSuccessToast] = useState(false);
    const [showErrorToast, setShowErrorToast] = useState(false);
    const [showCaptchaError, setShowCaptchaError] = useState(false);
    const [form, setForm] = useState(defaultForm);
    const [errors, setErrors] = useState({});

    const setField = (filed, value) => {
        setForm({
            ...form,
            [filed]: value,
        });

        if (!!errors[filed]) {
            setErrors({
                ...errors,
                [filed]: null,
            });
        }
    };

    const buildFormData = () => {
        return {
            type: "medium",
            full_name: form.full_name,
            company_name: form.company_name,
            phone: form.phone,
            fax: form.fax,
            email: form.email,
            address: {
                street_1: form.street_1,
                street_2: form.street_2,
                city: form.city,
                state: form.state,
                zip: form.zip,
            },
            message: form.message,
            token: captchaRef.current.getValue(),
        };
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formErrors = validateForm();

        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
        } else {
            if (
                await submitForm(`${CGI_PATH}contact-us.cgi`, buildFormData())
            ) {
                setForm(defaultForm);
                setShowSuccessToast(true);
                setErrors({});

                captchaRef.current.reset();
            } else {
                setShowErrorToast(true);
            }
        }
    };

    const validateForm = () => {
        const newErrors = {};

        checkAndAddFieldErrorMessage(form, newErrors, "full_name", "name");
        checkAndAddFieldErrorMessage(
            form,
            newErrors,
            "company_name",
            "company"
        );
        checkAndAddFieldErrorMessage(form, newErrors, "email", "email");
        checkAndAddFieldErrorMessage(form, newErrors, "message", "message");
        checkAndAddFieldErrorMessage(
            form,
            newErrors,
            "street_1",
            "street address"
        );
        checkAndAddFieldErrorMessage(form, newErrors, "city", "city");

        if (form.email && !isValidEmail(form.email)) {
            newErrors.email = "Please enter valid email.";
        }

        if (!form.phone || form.phone?.length !== 14) {
            newErrors.phone = "Please enter a valid phone number.";
        }

        if (form.fax && form.fax?.length !== 14) {
            newErrors.fax = "Please enter a valid phone number.";
        }

        if (!form.state || form.state === 0) {
            newErrors.state = "Please select a state.";
        }

        if (!form.zip || form.zip.length < 5) {
            newErrors.zip = "Please enter a valid zipcode.";
        }

        const token = captchaRef.current.getValue();

        if (token === undefined || token === "") {
            newErrors.captcha = "Invalid captcha.";
            setShowCaptchaError(true);
        }

        return newErrors;
    };

    const handleReset = () => {
        setForm(defaultForm);
        setErrors({});
    };

    return (
        <>
            <Innerpageheading pageHeadInfo={contactUSPageHeader()} />
            <Breadcrumb breadcrumbInfo={contactUS()} />
            <Innerpageinnerheading innerText={innerText} />

            <Container className="contact-us-cntr">
                <Row className="justify-content-md-center mb-5">
                    <Col xs={12} md={6}>
                        <Form className="form-background">
                            <Form.Group className="mb-3" controlId="full_name">
                                <Form.Label className="formlabel-title">
                                    Name
                                    <span className="span-required">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="John Carter"
                                    maxLength="100"
                                    pattern="[A-Za-z]"
                                    value={form.full_name}
                                    onChange={(event) =>
                                        setField(
                                            "full_name",
                                            allowASCIIValues(event.target.value)
                                        )
                                    }
                                    className={
                                        !!errors.full_name && "red-border"
                                    }
                                ></Form.Control>
                                <div className="error-message">
                                    {errors.full_name}
                                </div>
                            </Form.Group>
                            <Form.Group
                                className="mb-3"
                                controlId="company_name"
                            >
                                <Form.Label className="formlabel-title">
                                    Company
                                    <span className="span-required">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Company Name"
                                    maxLength="100"
                                    value={form.company_name}
                                    onChange={(event) =>
                                        setField(
                                            "company_name",
                                            allowASCIIValues(event.target.value)
                                        )
                                    }
                                    className={
                                        !!errors.company_name && "red-border"
                                    }
                                ></Form.Control>
                                <div className="error-message">
                                    {errors.company_name}
                                </div>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="phone">
                                <Form.Label className="formlabel-title">
                                    Phone
                                    <span className="span-required">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="(123) 456-7890"
                                    maxLength="14"
                                    minLength="14"
                                    value={form.phone}
                                    onChange={(event) =>
                                        setField(
                                            "phone",
                                            normalizeInput(
                                                event.target.value,
                                                form.phone
                                            )
                                        )
                                    }
                                    className={!!errors.phone && "red-border"}
                                    pattern="(ddd) ddd-ddd"
                                ></Form.Control>
                                <div className="error-message">
                                    {errors.phone}
                                </div>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="fax">
                                <Form.Label className="formlabel-title">
                                    Fax
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="(123) 456-7890"
                                    maxLength="14"
                                    value={form.fax}
                                    onChange={(event) =>
                                        setField(
                                            "fax",
                                            normalizeInput(
                                                event.target.value,
                                                form.fax
                                            )
                                        )
                                    }
                                    pattern="(ddd) ddd-ddd"
                                    className={!!errors.fax && "red-border"}
                                ></Form.Control>
                                <div className="error-message">
                                    {errors.fax}
                                </div>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="email">
                                <Form.Label className="formlabel-title">
                                    Email
                                    <span className="span-required">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="example@email.com"
                                    maxLength="100"
                                    value={form.email}
                                    onChange={(event) =>
                                        setField(
                                            "email",
                                            allowASCIIValues(event.target.value)
                                        )
                                    }
                                    className={!!errors.email && "red-border"}
                                ></Form.Control>
                                <div className="error-message">
                                    {errors.email}
                                </div>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="street_1">
                                <Form.Label className="formlabel-title">
                                    Address
                                    <span className="span-required">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="123 Main Street"
                                    maxLength="100"
                                    value={form.street_1}
                                    onChange={(event) =>
                                        setField(
                                            "street_1",
                                            allowASCIIValues(event.target.value)
                                        )
                                    }
                                    className={
                                        !!errors.street_1 && "red-border"
                                    }
                                ></Form.Control>
                                <div className="error-message">
                                    {errors.street_1}
                                </div>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="street_2">
                                <Form.Label className="formlabel-title">
                                    Address 2
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Suite 123"
                                    maxLength="100"
                                    value={form.street_2}
                                    onChange={(event) =>
                                        setField(
                                            "street_2",
                                            allowASCIIValues(event.target.value)
                                        )
                                    }
                                ></Form.Control>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="city">
                                <Form.Label className="formlabel-title">
                                    City
                                    <span className="span-required">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Trenton"
                                    maxLength="50"
                                    value={form.city}
                                    onChange={(event) =>
                                        setField(
                                            "city",
                                            allowASCIIValues(event.target.value)
                                        )
                                    }
                                    className={!!errors.city && "red-border"}
                                ></Form.Control>
                                <div className="error-message">
                                    {errors.city}
                                </div>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="state">
                                <Form.Label className="formlabel-title">
                                    State
                                    <span className="span-required">*</span>
                                </Form.Label>
                                <Form.Select
                                    placeholder=""
                                    value={form.state}
                                    onChange={(event) =>
                                        setField("state", event.target.value)
                                    }
                                    className={!!errors.state && "red-border"}
                                >
                                    <option value=""> Select </option>(
                                    {US_STATES.map((state) => {
                                        return (
                                            <option
                                                key={state.abbreviation}
                                                value={state.abbreviation}
                                            >
                                                {state.abbreviation}
                                            </option>
                                        );
                                    })}
                                    )
                                </Form.Select>
                                <div className="error-message">
                                    {errors.state}
                                </div>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="zip">
                                <Form.Label className="formlabel-title">
                                    Zipcode
                                    <span className="span-required">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="12345"
                                    maxLength="5"
                                    value={form.zip}
                                    onChange={(event) =>
                                        setField(
                                            "zip",
                                            removeAlpha(event.target.value)
                                        )
                                    }
                                    className={!!errors.zip && "red-border"}
                                ></Form.Control>
                                <div className="error-message">
                                    {errors.zip}
                                </div>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="message">
                                <Form.Label className="formlabel-title">
                                    Message
                                    <span className="span-required">*</span>
                                </Form.Label>
                                <textarea
                                    placeholder="Please type message here..."
                                    maxLength="500"
                                    type="textarea"
                                    id="message"
                                    className={
                                        errors.message
                                            ? "form-control cj-textarea red-border"
                                            : "form-control cj-textarea"
                                    }
                                    rows={7}
                                    onChange={(event) => {
                                        setField(
                                            "message",
                                            allowASCIIValues(event.target.value)
                                        );
                                    }}
                                    value={form.message}
                                ></textarea>
                                <div className="error-message">
                                    {errors.message}
                                </div>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <div>
                                    <ReCAPTCHA
                                        sitekey={ReCaptchaKey}
                                        ref={captchaRef}
                                        onChange={() => resetCaptcha()}
                                    />
                                </div>

                                {showCaptchaError ? (
                                    <div className="error-message">
                                        {errors.captcha}
                                    </div>
                                ) : (
                                    <></>
                                )}

                                <Button
                                    type="submit"
                                    onClick={handleSubmit}
                                    className="btn btn-cj-primary mt-3 form-btn form-submit-btn"
                                >
                                    SUBMIT REQUEST
                                </Button>

                                <Button
                                    type="button"
                                    onClick={handleReset}
                                    className="btn btn-cj-secondary ms-3 mt-3"
                                >
                                    RESET
                                </Button>
                            </Form.Group>
                        </Form>
                    </Col>

                    <Col xs={12} md={6}>
                        <Row>
                            <Col
                                xs={12}
                                md={10}
                                className="ms-md-auto margintop"
                            >
                                <div className="contact-desc">
                                    Get in touch with our Customer Service Team.
                                </div>
                                <div className="row-icon-phone-left contact-desc">
                                    <span className="icon-phone-left"></span>
                                    {CJ_PHONE}
                                </div>
                                <div className="contact-desc">
                                    <span className="icon-location-left"></span>
                                    {CJ_ADDRESS.street},
                                    <br /> &nbsp; &nbsp; &nbsp;
                                    {CJ_ADDRESS.street2}
                                    <br /> &nbsp; &nbsp; &nbsp;
                                    {CJ_ADDRESS.poBox}
                                    <br /> &nbsp; &nbsp; &nbsp;
                                    {CJ_ADDRESS.city}, {CJ_ADDRESS.state}&nbsp;
                                    {CJ_ADDRESS.zip}
                                </div>
                                <div>
                                    <a href="/request-info">
                                        <IconButton
                                            text="REQUEST INFORMATION"
                                            styleClass="btn-cj-secondary"
                                            iconClass="btn-arrow-right"
                                            style={{ paddingBottom: "15px" }}
                                        />
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

            <Toasts
                showErrorToast={showErrorToast}
                setShowErrorToast={setShowErrorToast}
                showSuccessToast={showSuccessToast}
                setShowSuccessToast={setShowSuccessToast}
            />
        </>
    );
};

export default ContactUs;
