import Innerpageheading from "../../../components/common/innerpageheading";
import Breadcrumb from "../../../components/common/breadcrumb";
import JudgmentLienSearch from "./JudgmentLien";
import PatriotNameSearch from "./PatriotName";
import ChildSupportJudgmentSearch from "./ChildSupportJudgment";
import Contact from "../../../components/common/contact";
import { nameSearchesPageHeader } from "../../../components/common/pageheader";
import { nameSearchesBreadcrumb } from "../../../components/common/breadcrumbs";

const NameSearches = () => {
    return (
        <>
            <Innerpageheading pageHeadInfo={nameSearchesPageHeader()} />
            <Breadcrumb breadcrumbInfo={nameSearchesBreadcrumb()} />

            <div className="container pb-5">
                <div className="row pt-6">
                    <div className="col-12">
                        <ChildSupportJudgmentSearch
                            showHeader={false}
                            showBreadcrum={false}
                            showTextExpand={true}
                        ></ChildSupportJudgmentSearch>
                    </div>
                </div>

                <div className="row pt-5">
                    <div className="col-12">
                        <hr stroke="#D4D2E3" className="bottom-content"></hr>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <JudgmentLienSearch
                            showHeader={false}
                            showBreadcrum={false}
                            showTextExpand={true}
                        ></JudgmentLienSearch>
                    </div>
                </div>

                <div className="row pt-5">
                    <div className="col-12">
                        <hr stroke="#D4D2E3" className="bottom-content"></hr>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <PatriotNameSearch
                            showHeader={false}
                            showBreadcrum={false}
                            showTextExpand={true}
                        ></PatriotNameSearch>
                    </div>
                </div>
            </div>
            <div className="row contact-loaction">
                <Contact />
            </div>
        </>
    );
};

export default NameSearches;
