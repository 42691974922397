import React, { useState, useRef } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import Innerpageheading from "../../components/common/innerpageheading";
import Breadcrumb from "../../components/common/breadcrumb";
import { CGI_PATH, CJ_PHONE, US_STATES } from "../../constants";
import { requestInfoPageHeader } from "../../components/common/pageheader";
import { requestInformation } from "../../components/common/breadcrumbs";
import PrivacyPolicy from "../../components/common/PrivacyPolicy";
import Toasts from "../../components/forms/Toasts";
import {
    allowASCIIValues,
    checkAndAddFieldErrorMessage,
    isValidEmail,
    normalizeInput,
    removeAlpha,
    submitForm,
} from "../../components/forms/FormHelpers";
import ReCAPTCHA from "react-google-recaptcha";
import { ReCaptchaKey } from "../../constants";

export default function RequestInfo() {
    const defaultForm = {
        full_name: "",
        company_name: "",
        phone: "",
        fax: "",
        email: "",
        street_1: "",
        street_2: "",
        city: "",
        state: "",
        zip: "",
        message: "",
        contact_method: "email",
        general: true,
        can_sales_contact: true,
        patriot_name_searches: false,
        statewide_lien_searches: false,
        child_support_judgment_searches: false,
        flood_tideland_searches: false,
        county_search_services: false,
        corporate_ucc_searches: false,
        tax_searches: false,
        heard_about_us_type: "other",
        line_of_business_type: "other",
        heard_about_us_value: "",
        line_of_business_value: "",
        captcha: "",
    };

    const captchaRef = useRef(null);
    function resetCaptcha() {
        setShowCaptchaError(false);
    }

    const [showSuccessToast, setShowSuccessToast] = useState(false);
    const [showErrorToast, setShowErrorToast] = useState(false);
    const [showCaptchaError, setShowCaptchaError] = useState(false);

    const [errors, setErrors] = useState({});
    const [form, setForm] = useState(defaultForm);
    const [showOtherLineOfBusinessInput, setShowOtherLineOfBusinessInput] =
        useState(true);
    const [showHeardAboutUsInput, setShowHeardAboutUsInput] = useState(true);

    const setField = (filed, value) => {
        setForm({
            ...form,
            [filed]: value,
        });

        if (!!errors[filed]) {
            setErrors({
                ...errors,
                [filed]: null,
            });
        }
    };

    const buildFormData = () => {
        let productsToSendClient = [];
        let availableProducts = [
            "general",
            "patriot_name_searches",
            "statewide_lien_searches",
            "child_support_judgment_searches",
            "flood_tideland_searches",
            "county_search_services",
            "corporate_ucc_searches",
            "tax_searches",
        ];

        availableProducts.forEach((product) => {
            if (form[product] === true) {
                productsToSendClient.push(product);
            }
        });

        const formToSubmit = {
            type: "large", // tells the backend to look at all the fields provided, this is the large form
            full_name: form.full_name,
            company_name: form.company_name,
            phone: form.phone,
            fax: form.fax,
            email: form.email,
            address: {
                street_1: form.street_1,
                street_2: form.street_2,
                city: form.city,
                state: form.state,
                zip: form.zip,
            },
            contact_method: form.contact_method,
            message: form.message,
            products: productsToSendClient,
            can_sales_contact: form.can_sales_contact,
            line_of_business: {
                type: form.line_of_business_type,
                value:
                    form.line_of_business_type === "other"
                        ? form.line_of_business_value
                        : "",
            },
            heard_about_us: {
                type: form.heard_about_us_type,
                value:
                    form.heard_about_us_type === "other"
                        ? form.heard_about_us_value
                        : "",
            },
            token: captchaRef.current.getValue(),
        };

        return formToSubmit;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateForm();

        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
        } else {
            if (
                await submitForm(`${CGI_PATH}contact-us.cgi`, buildFormData())
            ) {
                setForm(defaultForm);
                setShowSuccessToast(true);
                setErrors({});

                captchaRef.current.reset();
            } else {
                setShowErrorToast(true);
            }
        }
    };

    const validateForm = () => {
        const newErrors = {};

        checkAndAddFieldErrorMessage(form, newErrors, "full_name", "name");
        checkAndAddFieldErrorMessage(
            form,
            newErrors,
            "company_name",
            "company"
        );
        checkAndAddFieldErrorMessage(form, newErrors, "email", "email");
        checkAndAddFieldErrorMessage(
            form,
            newErrors,
            "street_1",
            "street address"
        );
        checkAndAddFieldErrorMessage(form, newErrors, "city", "city");
        checkAndAddFieldErrorMessage(
            form,
            newErrors,
            "contact_method",
            "contact method"
        );

        if (form.email && !isValidEmail(form.email)) {
            newErrors.email = "Please enter valid email.";
        }

        if (!form.phone || form.phone.length !== 14) {
            newErrors.phone = "Please enter a valid phone number.";
        }

        if (
            (form.contact_method === "fax" &&
                (!form.fax || form.fax.length !== 14)) ||
            (form.fax && form.fax.length !== 14)
        ) {
            newErrors.fax = "Please enter a valid phone number.";
        }

        if (!form.state || form.state === 0) {
            newErrors.state = "Please select a state.";
        }

        if (!form.heard_about_us_type || form.heard_about_us_type === 0) {
            newErrors.heard_about_us_type =
                "Please select how you heard about us.";
        }

        if (form.line_of_business_type === "other") {
            checkAndAddFieldErrorMessage(
                form,
                newErrors,
                "line_of_business_value",
                "line of business"
            );
        }

        if (form.heard_about_us_type === "other") {
            checkAndAddFieldErrorMessage(
                form,
                newErrors,
                "heard_about_us_value",
                "value"
            );
        }

        if (form.zip.length < 5) {
            newErrors.zip = "Please enter a valid zipcode.";
        }

        const token = captchaRef.current.getValue();

        if (token === undefined || token === "") {
            newErrors.captcha = "Invalid captcha.";
            setShowCaptchaError(true);
        }

        return newErrors;
    };

    const handleReset = () => {
        setForm(defaultForm);
    };

    return (
        <div>
            <Innerpageheading pageHeadInfo={requestInfoPageHeader()} />
            <Breadcrumb breadcrumbInfo={requestInformation()} />

            <Container>
                <p
                    className="inner-heading-text mt-5 px-3"
                    style={{ marginBottom: "80px" }}
                >
                    To request information from Charles Jones please fill out
                    the form below and we will get back to you with the
                    requested information. If you have any questions concerning
                    the completion of this form, call {CJ_PHONE} and ask for our
                    Customer Service Department.
                </p>

                <Container className="justify-content-md-center mb-5">
                    <Form className="form-background">
                        <h2 className="get-started-subheader mb-5">
                            Contact Information
                        </h2>
                        <Row className="mb-lg-3">
                            <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                                <Form.Group controlId="full_name">
                                    <Form.Label className="formlabel-title">
                                        Name
                                        <span className="span-required">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="John Carter"
                                        maxLength="100"
                                        pattern="[A-Za-z]"
                                        value={form.full_name}
                                        onChange={(event) =>
                                            setField(
                                                "full_name",
                                                allowASCIIValues(
                                                    event.target.value
                                                )
                                            )
                                        }
                                        className={
                                            !!errors.full_name && "red-border"
                                        }
                                    ></Form.Control>
                                    <div className="error-message">
                                        {errors.full_name}
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                                <Form.Group controlId="street_1">
                                    <Form.Label className="formlabel-title">
                                        Address
                                        <span className="span-required">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="123 Main Street"
                                        maxLength="50"
                                        value={form.street_1}
                                        onChange={(event) =>
                                            setField(
                                                "street_1",
                                                allowASCIIValues(
                                                    event.target.value
                                                )
                                            )
                                        }
                                        className={
                                            !!errors.street_1 && "red-border"
                                        }
                                    ></Form.Control>
                                    <div className="error-message">
                                        {errors.street_1}
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="mb-lg-3">
                            <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                                <Form.Group controlId="company_name">
                                    <Form.Label className="formlabel-title">
                                        Company
                                        <span className="span-required">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Company Name"
                                        maxLength="100"
                                        value={form.company_name}
                                        onChange={(event) =>
                                            setField(
                                                "company_name",
                                                allowASCIIValues(
                                                    event.target.value
                                                )
                                            )
                                        }
                                        className={
                                            !!errors.company_name &&
                                            "red-border"
                                        }
                                    ></Form.Control>
                                    <div className="error-message">
                                        {errors.company_name}
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                                <Form.Group controlId="street_2">
                                    <Form.Label className="formlabel-title">
                                        Address 2
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Suite 123"
                                        maxLength="50"
                                        value={form.street_2}
                                        onChange={(event) =>
                                            setField(
                                                "street_2",
                                                allowASCIIValues(
                                                    event.target.value
                                                )
                                            )
                                        }
                                    ></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="mb-lg-3">
                            <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                                <Form.Group controlId="phone">
                                    <Form.Label className="formlabel-title">
                                        Phone
                                        <span className="span-required">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="(123) 456-7890"
                                        maxLength="14"
                                        minLength="14"
                                        value={form.phone}
                                        onChange={(event) =>
                                            setField(
                                                "phone",
                                                normalizeInput(
                                                    event.target.value,
                                                    form.phone
                                                )
                                            )
                                        }
                                        className={
                                            !!errors.phone && "red-border"
                                        }
                                        pattern="(ddd) ddd-ddd"
                                    ></Form.Control>
                                    <div className="error-message">
                                        {errors.phone}
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                                <Form.Group controlId="city">
                                    <Form.Label className="formlabel-title">
                                        City
                                        <span className="span-required">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Trenton"
                                        maxLength="50"
                                        value={form.city}
                                        onChange={(event) =>
                                            setField(
                                                "city",
                                                allowASCIIValues(
                                                    event.target.value
                                                )
                                            )
                                        }
                                        className={
                                            !!errors.city && "red-border"
                                        }
                                    ></Form.Control>
                                    <div className="error-message">
                                        {errors.city}
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="mb-lg-3">
                            <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                                <Form.Group controlId="fax">
                                    <Form.Label className="formlabel-title">
                                        Fax
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="(123) 456-7890"
                                        maxLength="14"
                                        value={form.fax}
                                        onChange={(event) =>
                                            setField(
                                                "fax",
                                                normalizeInput(
                                                    event.target.value,
                                                    form.fax
                                                )
                                            )
                                        }
                                        pattern="(ddd) ddd-ddd"
                                        className={!!errors.fax && "red-border"}
                                    ></Form.Control>
                                    <div className="error-message">
                                        {errors.fax}
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                                <Form.Group controlId="state">
                                    <Form.Label className="formlabel-title">
                                        State
                                        <span className="span-required">*</span>
                                    </Form.Label>
                                    <Form.Select
                                        placeholder=""
                                        value={form.state}
                                        onChange={(event) =>
                                            setField(
                                                "state",
                                                event.target.value
                                            )
                                        }
                                        className={
                                            !!errors.state && "red-border"
                                        }
                                    >
                                        <option value=""> Select </option>(
                                        {US_STATES.map((state) => {
                                            return (
                                                <option
                                                    key={state.abbreviation}
                                                    value={state.abbreviation}
                                                >
                                                    {state.abbreviation}
                                                </option>
                                            );
                                        })}
                                        )
                                    </Form.Select>
                                </Form.Group>
                                <div className="error-message">
                                    {errors.state}
                                </div>
                            </Col>
                        </Row>

                        <Row className="mb-lg-3">
                            <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                                <Form.Group controlId="email">
                                    <Form.Label className="formlabel-title">
                                        Email
                                        <span className="span-required">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="example@email.com"
                                        maxLength="50"
                                        value={form.email}
                                        onChange={(event) =>
                                            setField(
                                                "email",
                                                allowASCIIValues(
                                                    event.target.value
                                                )
                                            )
                                        }
                                        className={
                                            !!errors.email && "red-border"
                                        }
                                    ></Form.Control>
                                    <div className="error-message">
                                        {errors.email}
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                                <Form.Group controlId="zip">
                                    <Form.Label className="formlabel-title">
                                        Zipcode
                                        <span className="span-required">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="12345"
                                        maxLength="5"
                                        value={form.zip}
                                        onChange={(event) =>
                                            setField(
                                                "zip",
                                                removeAlpha(event.target.value)
                                            )
                                        }
                                        className={!!errors.zip && "red-border"}
                                    ></Form.Control>
                                    <div className="error-message">
                                        {errors.zip}
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="mb-lg-3">
                            <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                                <Form.Group controlId="line_of_business_type">
                                    <Form.Label className="formlabel-title">
                                        Line of Business
                                        <span className="span-required">*</span>
                                    </Form.Label>
                                    <Form.Select
                                        value={form.line_of_business_type}
                                        onChange={(event) => {
                                            setShowOtherLineOfBusinessInput(
                                                event.target.value === "other"
                                            );

                                            setField(
                                                "line_of_business_type",
                                                event.target.value
                                            );
                                        }}
                                    >
                                        <option value="other">Other</option>
                                        <option value="banking">Banking</option>
                                        <option value="legal">Legal</option>
                                        <option value="mortgage_lending">
                                            Mortgage Lending
                                        </option>
                                        <option value="title_insurance">
                                            Title Insurance
                                        </option>
                                    </Form.Select>
                                </Form.Group>
                                <div className="error-message">
                                    {errors.line_of_business_type}
                                </div>
                            </Col>
                            <Col
                                xs={12}
                                lg={6}
                                className={
                                    showOtherLineOfBusinessInput
                                        ? "mb-3 mb-lg-0"
                                        : "d-none mb-3 mb-lg-0"
                                }
                            >
                                <Form.Group controlId="line_of_business_value">
                                    <Form.Label className="formlabel-title">
                                        &nbsp;
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Other Business"
                                        maxLength="50"
                                        value={form.line_of_business_value}
                                        onChange={(event) =>
                                            setField(
                                                "line_of_business_value",
                                                allowASCIIValues(
                                                    event.target.value
                                                )
                                            )
                                        }
                                        className={
                                            !!errors.line_of_business_value &&
                                            "red-border"
                                        }
                                    ></Form.Control>
                                    <div className="error-message">
                                        {errors.line_of_business_value}
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="mb-lg-3">
                            <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                                <Form.Group controlId="heard_about_us_type">
                                    <Form.Label className="formlabel-title">
                                        How did you hear about us?
                                        <span className="span-required">*</span>
                                    </Form.Label>
                                    <Form.Select
                                        placeholder=""
                                        value={form.heard_about_us_type}
                                        onChange={(event) => {
                                            setShowHeardAboutUsInput(
                                                event.target.value === "other"
                                            );

                                            setField(
                                                "heard_about_us_type",
                                                event.target.value
                                            );
                                        }}
                                    >
                                        <option value="other">Other</option>
                                        <option value="advertisement">
                                            Advertisement
                                        </option>
                                        <option value="direct_mail">
                                            Direct Mail
                                        </option>
                                        <option value="sales_contact">
                                            Sales Contact
                                        </option>
                                        <option value="trade_show">
                                            Trade Show
                                        </option>
                                    </Form.Select>
                                </Form.Group>
                                <div className="error-message">
                                    {errors.heard_about_us_type}
                                </div>
                            </Col>
                            <Col
                                xs={12}
                                lg={6}
                                className={
                                    showHeardAboutUsInput
                                        ? "mb-3 mb-lg-0"
                                        : "d-none mb-3 mb-lg-0"
                                }
                            >
                                <Form.Group controlId="heard_about_us_value">
                                    <Form.Label className="formlabel-title">
                                        &nbsp;
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Other"
                                        maxLength="50"
                                        value={form.heard_about_us_value}
                                        onChange={(event) => {
                                            setField(
                                                "heard_about_us_value",
                                                allowASCIIValues(
                                                    event.target.value
                                                )
                                            );
                                        }}
                                        className={
                                            !!errors.heard_about_us_value &&
                                            "red-border"
                                        }
                                    ></Form.Control>
                                    <div className="error-message">
                                        {errors.heard_about_us_value}
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>

                        <h2 className="get-started-subheader my-5">
                            Please send me information about:
                        </h2>

                        <Row className="mb-lg-3">
                            <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                                <Form.Group controlId="general">
                                    <Form.Check
                                        type="checkbox"
                                        label="General Information"
                                        checked={form.general}
                                        onChange={(event) => {
                                            setField(
                                                "general",
                                                event.target.checked
                                            );
                                        }}
                                        name="general"
                                        id="general"
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                                <Form.Group controlId="flood_tideland_searches">
                                    <Form.Check
                                        type="checkbox"
                                        label="Flood and Tideland Searches"
                                        checked={form.flood_tideland_searches}
                                        onChange={(event) => {
                                            setField(
                                                "flood_tideland_searches",
                                                event.target.checked
                                            );
                                        }}
                                        name="flood_tideland_searches"
                                        id="flood_tideland_searches"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="mb-lg-3">
                            <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                                <Form.Group controlId="patriot_name_searches">
                                    <Form.Check
                                        type="checkbox"
                                        label="Patriot Name Searches"
                                        checked={form.patriot_name_searches}
                                        onChange={(event) => {
                                            setField(
                                                "patriot_name_searches",
                                                event.target.checked
                                            );
                                        }}
                                        name="patriot_name_searches"
                                        id="patriot_name_searches"
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                                <Form.Group controlId="county_search_services">
                                    <Form.Check
                                        type="checkbox"
                                        label="County Search Services"
                                        checked={form.county_search_services}
                                        onChange={(event) => {
                                            setField(
                                                "county_search_services",
                                                event.target.checked
                                            );
                                        }}
                                        name="county_search_services"
                                        id="county_search_services"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="mb-lg-3">
                            <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                                <Form.Group controlId="statewide_lien_searches">
                                    <Form.Check
                                        type="checkbox"
                                        label="Statewide Lien (Upper Court) Searches"
                                        checked={form.statewide_lien_searches}
                                        onChange={(event) => {
                                            setField(
                                                "statewide_lien_searches",
                                                event.target.checked
                                            );
                                        }}
                                        name="statewide_lien_searches"
                                        id="statewide_lien_searches"
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                                <Form.Group controlId="corporate_ucc_searches">
                                    <Form.Check
                                        type="checkbox"
                                        label="Corporate and UCC Searches"
                                        checked={form.corporate_ucc_searches}
                                        onChange={(event) => {
                                            setField(
                                                "corporate_ucc_searches",
                                                event.target.checked
                                            );
                                        }}
                                        name="corporate_ucc_searches"
                                        id="corporate_ucc_searches"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className="mb-5">
                            <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                                <Form.Group controlId="child_support_judgment_searches">
                                    <Form.Check
                                        type="checkbox"
                                        label="Child Support Judgment Searches"
                                        checked={
                                            form.child_support_judgment_searches
                                        }
                                        onChange={(event) => {
                                            setField(
                                                "child_support_judgment_searches",
                                                event.target.checked
                                            );
                                        }}
                                        name="child_support_judgment_searches"
                                        id="child_support_judgment_searches"
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={6} className="mb-3 mb-lg-0">
                                <Form.Group controlId="tax_searches">
                                    <Form.Check
                                        type="checkbox"
                                        label="Tax Searches"
                                        checked={form.tax_searches}
                                        onChange={(event) => {
                                            setField(
                                                "tax_searches",
                                                event.target.checked
                                            );
                                        }}
                                        name="tax_searches"
                                        id="tax_searches"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <h2 className="get-started-subheader my-5">
                            Contact Information
                        </h2>

                        <h3 className="my-3 get-started-sub-subheader">
                            Send more information to your
                            <span className="span-required">*</span>
                        </h3>

                        <div className="mb-5">
                            <Form.Check
                                inline
                                label="Email"
                                checked={form.contact_method === "email"}
                                name="contact_method"
                                type="radio"
                                value="email"
                                onChange={(event) =>
                                    setField(
                                        "contact_method",
                                        event.target.value
                                    )
                                }
                            />
                            <Form.Check
                                inline
                                label="Regular Mail"
                                checked={form.contact_method === "mail"}
                                name="contact_method"
                                type="radio"
                                value="mail"
                                onChange={(event) =>
                                    setField(
                                        "contact_method",
                                        event.target.value
                                    )
                                }
                            />
                            <Form.Check
                                inline
                                name="contact_method"
                                label="Fax"
                                checked={form.contact_method === "fax"}
                                type="radio"
                                value="fax"
                                onChange={(event) =>
                                    setField(
                                        "contact_method",
                                        event.target.value
                                    )
                                }
                            />
                            <div className="error-message">
                                {errors.contact_method}
                            </div>
                        </div>

                        <h2 className="get-started-subheader my-4">
                            Comments or additional information
                        </h2>

                        <Form.Group controlId="message">
                            <textarea
                                placeholder="Please type message here..."
                                maxLength="500"
                                type="textarea"
                                id="message"
                                className="form-control cj-textarea"
                                rows={7}
                                onChange={(event) => {
                                    setField(
                                        "message",
                                        allowASCIIValues(event.target.value)
                                    );
                                }}
                                value={form.message}
                            ></textarea>
                        </Form.Group>

                        <Form.Group
                            controlId="can_sales_contact"
                            className="my-4"
                        >
                            <Form.Check
                                type="checkbox"
                                checked={form.can_sales_contact}
                                label="Please have a sales person contact me."
                                onChange={(event) => {
                                    setField(
                                        "can_sales_contact",
                                        event.target.checked
                                    );
                                }}
                                name="can_sales_contact"
                                id="can_sales_contact"
                            />
                        </Form.Group>

                        <Form.Group className="mt-3">
                            <div>
                                <ReCAPTCHA
                                    sitekey={ReCaptchaKey}
                                    ref={captchaRef}
                                    onChange={() => resetCaptcha()}
                                />
                            </div>

                            {showCaptchaError ? (
                                <div className="error-message">
                                    {errors.captcha}
                                </div>
                            ) : (
                                <></>
                            )}

                            <Button
                                type="submit"
                                onClick={handleSubmit}
                                className="btn btn-cj-primary ms-3 mt-3 form-btn form-submit-btn"
                            >
                                SUBMIT REQUEST
                            </Button>

                            <Button
                                type="button"
                                onClick={handleReset}
                                className="btn btn-cj-secondary ms-3 mt-3"
                            >
                                RESET
                            </Button>
                        </Form.Group>
                    </Form>
                </Container>

                <PrivacyPolicy showFull={true} />
            </Container>

            <Toasts
                showErrorToast={showErrorToast}
                setShowErrorToast={setShowErrorToast}
                showSuccessToast={showSuccessToast}
                setShowSuccessToast={setShowSuccessToast}
            />
        </div>
    );
}
