import Button from "react-bootstrap/Button";

export default function IconButton({
    text,
    styleClass,
    iconClass,
    onClickAction,
    ...props
}) {
    return (
        <Button
            className={`btn ${styleClass} mt-2 icon-btn`}
            {...props}
            onClick={onClickAction}
        >
            {text.toLowerCase() === "sign on" ? (
                <>
                    SIGN&nbsp;<span className="sign-on-span">ON</span>
                </>
            ) : (
                text
            )}
            <span className={`${iconClass} ms-2`}></span>
        </Button>
    );
}
