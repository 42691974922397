import Innerpageheading from "../../../components/common/innerpageheading";
import Breadcrumb from "../../../components/common/breadcrumb";
import Contact from "../../../components/common/contact";
import { countySearchServicesPageHeader } from "../../../components/common/pageheader";
import { countySearchServicesBreadcrumb } from "../../../components/common/breadcrumbs";
import CountySearch from "./CountySearch";

const CountySearchServices = () => {
    return (
        <>
            <Innerpageheading pageHeadInfo={countySearchServicesPageHeader()} />
            <Breadcrumb breadcrumbInfo={countySearchServicesBreadcrumb()} />

            <div className="container pb-5">
                <div className="row pt-6">
                    <div className="col-12">
                        <CountySearch
                            showHeader={false}
                            showBreadcrum={false}
                            showTextExpand={true}
                        ></CountySearch>
                    </div>
                </div>
            </div>
            <div className="row contact-loaction">
                <Contact />
            </div>
        </>
    );
};

export default CountySearchServices;
