export const executivePageHeader = () => {
    return {
        heading: "Executive Profiles",
        data: "Meet our Leadership Team",
    };
};

export const childSupportJudgmentSearchPageHeader = () => {
    return {
        heading: "Name Searches",
        data: "We provide best in class certified child support, judgment lien, and patriot name search services.",
    };
};

export const solutionSummaryPageHeader = () => {
    return {
        heading: "Solutions",
        data: "We pride ourselves on providing high-quality due diligence solutions in New Jersey and Pennsylvania.",
    };
};

export const solutionSummaryPageSubHeader = () => {
    return "You won’t find a regional public record firm serving the title, legal and lending markets with the resources and expertise of Charles Jones. We pride ourselves on delivering high quality due diligence solutions to assist with real estate settlements in NJ and PA.";
};

export const getStartedPageHeader = () => {
    return {
        heading: "Get Started",
        data: "Ready to begin using Charles Jones products and services? Our Customer Service Specialists will work with you to establish your account, evaluate your needs, answer questions, and help create new product orders.",
    };
};

export const requestAccountPageHeader = () => {
    return {
        heading: "Request an Account",
        data: "Ready to get started with Charles Jones? Please submit the information below.",
    };
};

export const requestIdPageHeader = () => {
    return {
        heading: "Request a CharlesJonesID",
        data: "Complete and submit the below form and Charles Jones will deliver an invitation to create your own CharlesJonesID. This will allow you to create your own credentials for ordering products. An email from Charles Jones Identity with your invitation will be delivered within the next 24 hours.",
    };
};

export const requestInfoPageHeader = () => {
    return {
        heading: "Request Information",
        data: "Charles Jones provides industry-standard, reliable search and information services to a growing range of customers, including lawyers and title companies. Our Customer Service Product Specialists will contact you to discuss your needs and answer any questions.",
    };
};

export const nameSearchesPageHeader = () => {
    return {
        heading: "Name Searches",
        data: "We provide best in class certified child support, judgment lien, and patriot name search services.",
    };
};

export const contactUSPageHeader = () => {
    return {
        heading: "Contact Us",
        data: "Get the answers you’re looking for – we will connect you with our Customer Service and Product Specialists.",
    };
};

export const judgmentLienSearchPageHeader = () => {
    return {
        heading: "Name Searches",
        data: "We provide best in class certified child support, judgment lien, and patriot name search services.",
    };
};

export const patriotNameSearchPageHeader = () => {
    return {
        heading: "Name Searches",
        data: "We provide best in class certified child support, judgment lien, and patriot name search services.",
    };
};

export const aboutUSPageHeader = () => {
    return {
        heading: "About Charles Jones",
        data: "Industry-standard products and due diligence solutions for more than 100 years.",
    };
};

export const newsInsightPageHeader = () => {
    return {
        heading: "News & Insights",
        data: "Our Subject Matter Experts explore market conditions, government regulations and changing needs impacting the products and partners Charles Jones serves.",
    };
};

export const faqsPageHeader = () => {
    return {
        heading: "FAQ",
        data: "Choosing the Right Solutions",
    };
};

export const testimonialsPageHeader = () => {
    return {
        heading: "Testimonials",
        data: "Charles Jones Customers Share stories of success, in their own words.",
    };
};

export const taxAndAssessmentPageHeader = () => {
    return {
        heading: "Tax Searches",
        data: "We provide best in class certified New Jersey tax and assessment and Pennsylvania tax certifications search services.",
    };
};

export const taxCertificationsPageHeader = () => {
    return {
        heading: "Tax Searches",
        data: "We provide best in class certified New Jersey tax and assessment and Pennsylvania tax certifications search services.",
    };
};

export const taxSearchesPageHeader = () => {
    return {
        heading: "Tax Searches",
        data: "We provide best in class certified New Jersey tax and assessment and Pennsylvania tax certifications search services.",
    };
};

export const floodSearchDeterminationPageHeader = () => {
    return {
        heading: "Flood and Tideland Services",
        data: "We provide best in class services.",
    };
};

export const lifeOfLoanFloodMonitoringPageHeader = () => {
    return {
        heading: "Flood and Tideland Services",
        data: "We provide best in class services.",
    };
};

export const tidelandClaimsSearchPageHeader = () => {
    return {
        heading: "Flood and Tideland Services",
        data: "We provide best in class services.",
    };
};

export const tidelandGrantSearchPageHeader = () => {
    return {
        heading: "Flood and Tideland Services",
        data: "We provide best in class services.",
    };
};

export const wetlandDeterminationPageHeader = () => {
    return {
        heading: "Flood and Tideland Services",
        data: "We provide best in class services.",
    };
};
export const floodAndTidelandServicesPageHeader = () => {
    return {
        heading: "Flood and Tideland Services",
        data: "We provide best in class services.",
    };
};
export const countySearchPageHeader = () => {
    return {
        heading: "County Search Services",
        data: "We provide best in class services.",
    };
};
export const countySearchServicesPageHeader = () => {
    return {
        heading: "County Search Services",
        data: "We provide best in class services.",
    };
};

export const termsAndConditionsPageHeader = () => {
    return {
        heading: "Terms and Conditions",
        data: " © First American Financial Corporation. All rights reserved.",
    };
};

export const disabilityStatementPageHeader = () => {
    return {
        heading: "DataTrace Accessibility Statement",
        data: "",
    };
};
export const statusReportPageHeader = () => {
    return {
        heading: "Corporate and UCC Services",
        data: "We provide best in class services.",
    };
};
export const franchiseAndCorporateTaxReportPageHeader = () => {
    return {
        heading: "Corporate and UCC Services",
        data: "We provide best in class services.",
    };
};
export const goodStandingCertificatePageHeader = () => {
    return {
        heading: "Corporate and UCC Services",
        data: "We provide best in class services.",
    };
};
export const legalExistenceCertificatePageHeader = () => {
    return {
        heading: "Corporate and UCC Services",
        data: "We provide best in class services.",
    };
};
export const corporateCopiesPageHeader = () => {
    return {
        heading: "Corporate and UCC Services",
        data: "We provide best in class services.",
    };
};
export const uccPlusPageHeader = () => {
    return {
        heading: "Corporate and UCC Services",
        data: "We provide best in class services.",
    };
};
export const uccStateCertifiedPageHeader = () => {
    return {
        heading: "Corporate and UCC Services",
        data: "We provide best in class services.",
    };
};
export const uccStateReportPageHeader = () => {
    return {
        heading: "Corporate and UCC Services",
        data: "We provide best in class services.",
    };
};
export const corporateAndUCCServicesPageHeader = () => {
    return {
        heading: "Corporate and UCC Services",
        data: "We provide best in class services.",
    };
};
export const dueDiligencePageHeader = () => {
    return {
        heading: "Nationwide Services",
        data: "",
    };
};
export const corporateFilingAndRegisteredAgentPageHeader = () => {
    return {
        heading: "Nationwide Services",
        data: "",
    };
};
export const floodSearchPageHeader = () => {
    return {
        heading: "Nationwide Services",
        data: "",
    };
};
export const additionalLolFloodMonitoringPageHeader = () => {
    return {
        heading: "Nationwide Services",
        data: "",
    };
};
export const additionalServicesPageHeader = () => {
    return {
        heading: "Nationwide Services",
        data: "",
    };
};
export const patriotNameSearchAdditionalPageHeader = () => {
    return {
        heading: "Nationwide Services",
        data: "",
    };
};
export const formsAndLinksPageHeader = () => {
    return {
        heading: "Forms & Links",
        data: "",
    };
};

export const notFoundPageHeader = () => {
    return {
        heading: "Not Found",
        data: "We couldn't locate that page.",
    };
};

export const titlePlantNewsPostPageHeader = () => {
    return {
        heading: "DTS Title Plant",
        data: "You have the power at your fingertips! Complete County Searches yourself - at your convenience.",
    };
};
