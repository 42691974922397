import Innerpageheading from "../../../components/common/innerpageheading";
import Breadcrumb from "../../../components/common/breadcrumb";
import ServiceDetails from "../../../components/solutions/ServiceDetails";
import {
    patriotNameSearchAdditionalPageHeader,
    patriotNameSearchPageHeader,
} from "../../../components/common/pageheader";
import {
    patriotNameSearchAdditionalBreadcrumb,
    patriotNameSearchBreadcrumb,
} from "../../../components/common/breadcrumbs";
import Contact from "../../../components/common/contact";
import { useLocation } from "react-router-dom";

const PatriotNameSearch = ({
    showHeader = true,
    showBreadcrum = true,
    showTextExpand = false,
}) => {
    var bannerImage = "/img/solutions/patriot.jpg";
    var title = "Patriot Name Search";
    var availableFor = ["Nationwide"];
    var certified = "Yes";
    const location = useLocation();
    const isAdditional = location?.pathname?.includes("/nationwide-services");

    var getStartedUrl =
        showHeader === false
            ? isAdditional === false
                ? "/solutions/name-searches/patriot-name-search"
                : "/solutions/nationwide-services/patriot-name-search"
            : "/get-started";

    const TextContent = () => {
        return (
            <>
                <p className="solutions-details-page-p">
                    <span>
                        The Charles Jones Patriot Name Search is a Certified
                        Search{" "}
                    </span>
                    of lists published by the Office of Foreign Assets Control
                    of the U.S. Department of Treasury (“OFAC”), consisting of
                    the "Specially Designated Nationals and Blocked Persons",
                    the “Foreign Sanctions Evaders”, and the comprehensive
                    “Consolidated List”. Charles Jones offers this search as an
                    option when ordering a Statewide Judgment Lien Search or
                    through a separate Service Selection on our online order
                    entry and retrieval system.
                </p>
                <p className="solutions-details-page-p">
                    Effective September 24, 2001, Executive Order 13224 was
                    issued (as amended by Order 13268, and 13608) and requires
                    anyone conducting a financial transaction to determine if
                    the person(s) with whom they are doing business are listed
                    on the Blocked Persons list. The USA PATRIOT Act of 2001
                    requires similar checks and procedures. Later Executive
                    Orders and sanctions have been imposed that require a check
                    of other names that appear on OFAC’s “The Consolidated List”
                    before transacting business.
                </p>
                <p className="solutions-details-page-p">
                    The search also provides business general information on
                    what to do if an individual name or entity appears on your
                    results.
                </p>
            </>
        );
    };

    return (
        <>
            {showHeader === true ? (
                <Innerpageheading
                    pageHeadInfo={
                        isAdditional === true
                            ? patriotNameSearchAdditionalPageHeader()
                            : patriotNameSearchPageHeader()
                    }
                />
            ) : null}

            {showBreadcrum === true ? (
                <Breadcrumb
                    breadcrumbInfo={
                        isAdditional === true
                            ? patriotNameSearchAdditionalBreadcrumb()
                            : patriotNameSearchBreadcrumb()
                    }
                />
            ) : null}

            <div
                className={
                    showHeader === false
                        ? "pt-4 px-5 px-sm-4 px-md-3 px-lg-4 px-xxl-0"
                        : "container pt-6 pb-5 px-5 px-sm-4 px-md-3 px-lg-4 px-xxl-0"
                }
            >
                <ServiceDetails
                    title={title}
                    bannerUrl={bannerImage}
                    availableFor={availableFor}
                    certified={certified}
                    showTextExpand={showTextExpand}
                    textContent={<TextContent></TextContent>}
                    getStartedUrl={getStartedUrl}
                ></ServiceDetails>
            </div>

            {showHeader === true ? (
                <div className="row contact-loaction">
                    <Contact />
                </div>
            ) : null}
        </>
    );
};

export default PatriotNameSearch;
