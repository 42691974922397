import React from "react";
import "./breadcrumb.css";

const Breadcrumb = ({ breadcrumbInfo }) => {
    let items = [];

    for (let i = 0; i < breadcrumbInfo.length; i++) {
        if (breadcrumbInfo[i].url.length > 0) {
            items.push(
                <li key={i} className="breadcrumb-item">
                    <a href={breadcrumbInfo[i].url}>{breadcrumbInfo[i].name}</a>
                </li>
            );
        } else {
            items.push(
                <li
                    key={i}
                    className="breadcrumb-item active"
                    aria-current="page"
                >
                    {breadcrumbInfo[i].name}
                </li>
            );
        }
    }

    return (
        <>
            <nav aria-label="breadcrumb" className="breadcrumb-content">
                <div className="container ps-0">
                    <div className="row">
                        <div className="col-sm-12">
                            <ol className="breadcrumb">{items}</ol>
                        </div>
                    </div>
                </div>
            </nav>
            <hr />
        </>
    );
};

export default Breadcrumb;
