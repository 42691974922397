import Innerpageheading from "../../../components/common/innerpageheading";
import Breadcrumb from "../../../components/common/breadcrumb";
import ServiceDetails from "../../../components/solutions/ServiceDetails";
import Contact from "../../../components/common/contact";
import { childSupportJudgmentSearchPageHeader } from "../../../components/common/pageheader";
import { childSupportJudgmentSearchBreadcrumb } from "../../../components/common/breadcrumbs";

const ChildSupportJudgmentSearch = ({
    showHeader = true,
    showBreadcrum = true,
    showTextExpand = false,
}) => {
    var bannerImage = "/img/solutions/child_support.jpg";
    var title = "Child Support Judgment Search";
    var availableFor = ["New Jersey"];
    var certified = "Yes";

    var getStartedUrl =
        showHeader === false
            ? "/solutions/name-searches/child-support-judgment"
            : "/get-started";

    const TextContent = () => {
        return (
            <>
                <p className="solutions-details-page-p">
                    <span>
                        Rely on our certified New Jersey child support judgment
                        search.&nbsp;
                    </span>
                    NJ State legislation requires attorneys in civil actions to
                    ascertain whether their clients are child support judgment
                    debtors before releasing a monetary award or settlement. To
                    expedite this process and to facilitate compliance with the
                    law, we offer Certified Child Support Judgment Searches.
                </p>
                <p className="solutions-details-page-p">
                    Within 24-48 hours, we will issue a certified child support
                    judgment search confirming whether a child support judgment
                    exists against the name(s) requested. We have been offering
                    searches of child support judgments since they were first
                    filed by the Probation Division of the Superior Court.
                </p>
            </>
        );
    };

    return (
        <>
            {showHeader === true ? (
                <Innerpageheading
                    pageHeadInfo={childSupportJudgmentSearchPageHeader()}
                />
            ) : null}

            {showBreadcrum === true ? (
                <Breadcrumb
                    breadcrumbInfo={childSupportJudgmentSearchBreadcrumb()}
                />
            ) : null}
            <div
                className={
                    showHeader === false
                        ? "pt-4 px-5 px-sm-4 px-md-3 px-lg-4 px-xxl-0"
                        : "container pt-6 pb-5 px-5 px-sm-4 px-md-3 px-lg-4 px-xxl-0"
                }
            >
                <ServiceDetails
                    title={title}
                    bannerUrl={bannerImage}
                    availableFor={availableFor}
                    certified={certified}
                    showTextExpand={showTextExpand}
                    textContent={<TextContent></TextContent>}
                    getStartedUrl={getStartedUrl}
                ></ServiceDetails>
            </div>

            {showHeader === true ? (
                <div className="row contact-loaction">
                    <Contact />
                </div>
            ) : null}
        </>
    );
};

export default ChildSupportJudgmentSearch;
