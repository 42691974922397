import Innerpageheading from "../../../components/common/innerpageheading";
import Breadcrumb from "../../../components/common/breadcrumb";
import ServiceDetails from "../../../components/solutions/ServiceDetails";

import { goodStandingCertificatePageHeader } from "../../../components/common/pageheader";
import { goodStandingCertificateBreadcrumb } from "../../../components/common/breadcrumbs";
import Contact from "../../../components/common/contact";

const GoodStandingCertificate = ({
    showHeader = true,
    showBreadcrum = true,
    showTextExpand = false,
}) => {
    var bannerImage = "/img/solutions/good_standing_certificate.jpg";
    var title = "Good Standing Certificate";
    var availableFor = ["New Jersey"];
    var certified = "No";

    var getStartedUrl =
        showHeader === false
            ? "/solutions/corporate-and-ucc/good-standing"
            : "/get-started";

    const TextContent = () => {
        return (
            <>
                <p className="solutions-details-page-p">
                    The Charles Jones Good Standing Certificate is provided in
                    either Long or Short form, and provides conclusive evidence
                    from the State that the company is active and all reports
                    and taxes are current within two years.
                </p>
            </>
        );
    };

    return (
        <>
            {showHeader === true ? (
                <Innerpageheading
                    pageHeadInfo={goodStandingCertificatePageHeader()}
                />
            ) : null}

            {showBreadcrum === true ? (
                <Breadcrumb
                    breadcrumbInfo={goodStandingCertificateBreadcrumb()}
                />
            ) : null}

            <div
                className={
                    showHeader === false
                        ? "pt-4 px-5 px-sm-4 px-md-3 px-lg-4 px-xxl-0"
                        : "container pt-6 pb-5 px-5 px-sm-4 px-md-3 px-lg-4 px-xxl-0"
                }
            >
                <ServiceDetails
                    title={title}
                    bannerUrl={bannerImage}
                    availableFor={availableFor}
                    certified={certified}
                    showTextExpand={showTextExpand}
                    textContent={<TextContent></TextContent>}
                    getStartedUrl={getStartedUrl}
                ></ServiceDetails>
            </div>

            {showHeader === true ? (
                <div className="row contact-loaction">
                    <Contact />
                </div>
            ) : null}
        </>
    );
};

export default GoodStandingCertificate;
