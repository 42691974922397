import Innerpageheading from "../../../components/common/innerpageheading";
import Breadcrumb from "../../../components/common/breadcrumb";
import Contact from "../../../components/common/contact";
import { additionalServicesPageHeader } from "../../../components/common/pageheader";
import { additionalServicesBreadcrumb } from "../../../components/common/breadcrumbs";

import DueDiligence from "./DueDiligence";
import CorporateFilingAndRegisteredAgent from "./CorporateFilingAndRegisteredAgent";
import FloodSearch from "./FloodSearch";
import LifeOfLoanFloodMonitoring from "../flood-tideland-services/LifeOfLoanFloodMonitoring";
import PatriotNameSearch from "../name-searches/PatriotName";

const AdditionalServices = () => {
    return (
        <>
            <Innerpageheading pageHeadInfo={additionalServicesPageHeader()} />
            <Breadcrumb breadcrumbInfo={additionalServicesBreadcrumb()} />

            <div className="container pb-5">
                <div className="row pt-6">
                    <div className="col-12">
                        <DueDiligence
                            showHeader={false}
                            showBreadcrum={false}
                            showTextExpand={true}
                        ></DueDiligence>
                    </div>
                </div>

                <div className="row pt-5">
                    <div className="col-12">
                        <hr stroke="#D4D2E3" className="bottom-content"></hr>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <CorporateFilingAndRegisteredAgent
                            showHeader={false}
                            showBreadcrum={false}
                            showTextExpand={false}
                        ></CorporateFilingAndRegisteredAgent>
                    </div>
                </div>

                <div className="row pt-5">
                    <div className="col-12">
                        <hr stroke="#D4D2E3" className="bottom-content"></hr>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <FloodSearch
                            showHeader={false}
                            showBreadcrum={false}
                            showTextExpand={true}
                        ></FloodSearch>
                    </div>
                </div>

                <div className="row pt-5">
                    <div className="col-12">
                        <hr stroke="#D4D2E3" className="bottom-content"></hr>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <LifeOfLoanFloodMonitoring
                            showHeader={false}
                            showBreadcrum={false}
                            showTextExpand={false}
                        ></LifeOfLoanFloodMonitoring>
                    </div>
                </div>

                <div className="row pt-5">
                    <div className="col-12">
                        <hr stroke="#D4D2E3" className="bottom-content"></hr>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <PatriotNameSearch
                            showHeader={false}
                            showBreadcrum={false}
                            showTextExpand={true}
                        ></PatriotNameSearch>
                    </div>
                </div>
            </div>
            <div className="row contact-loaction">
                <Contact />
            </div>
        </>
    );
};

export default AdditionalServices;
