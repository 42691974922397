import Innerpageheading from "../../../components/common/innerpageheading";
import Breadcrumb from "../../../components/common/breadcrumb";
import Contact from "../../../components/common/contact";
import { floodAndTidelandServicesPageHeader } from "../../../components/common/pageheader";
import { floodAndTidelandServicesBreadcrumb } from "../../../components/common/breadcrumbs";
import FloodSearchDetermination from "./FloodSearchDetermination";
import LifeOfLoanFloodMonitoring from "./LifeOfLoanFloodMonitoring";
import TidelandClaimsSearch from "./TidelandClaimsSearch";
import TidelandGrantSearch from "./TidelandGrantSearch";
import WetlandDetermination from "./WetlandDetermination";

const FloodAndTidelandServices = () => {
    return (
        <>
            <Innerpageheading
                pageHeadInfo={floodAndTidelandServicesPageHeader()}
            />
            <Breadcrumb breadcrumbInfo={floodAndTidelandServicesBreadcrumb()} />

            <div className="container pb-5">
                <div className="row pt-6">
                    <div className="col-12">
                        <FloodSearchDetermination
                            showHeader={false}
                            showBreadcrum={false}
                            showTextExpand={true}
                        ></FloodSearchDetermination>
                    </div>
                </div>

                <div className="row pt-5">
                    <div className="col-12">
                        <hr stroke="#D4D2E3" className="bottom-content"></hr>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <LifeOfLoanFloodMonitoring
                            showHeader={false}
                            showBreadcrum={false}
                            showTextExpand={true}
                        ></LifeOfLoanFloodMonitoring>
                    </div>
                </div>

                <div className="row pt-5">
                    <div className="col-12">
                        <hr stroke="#D4D2E3" className="bottom-content"></hr>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <TidelandClaimsSearch
                            showHeader={false}
                            showBreadcrum={false}
                            showTextExpand={true}
                        ></TidelandClaimsSearch>
                    </div>
                </div>

                <div className="row pt-5">
                    <div className="col-12">
                        <hr stroke="#D4D2E3" className="bottom-content"></hr>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <TidelandGrantSearch
                            showHeader={false}
                            showBreadcrum={false}
                            showTextExpand={false}
                        ></TidelandGrantSearch>
                    </div>
                </div>

                <div className="row pt-5">
                    <div className="col-12">
                        <hr stroke="#D4D2E3" className="bottom-content"></hr>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <WetlandDetermination
                            showHeader={false}
                            showBreadcrum={false}
                            showTextExpand={true}
                        ></WetlandDetermination>
                    </div>
                </div>
            </div>
            <div className="row contact-loaction">
                <Contact />
            </div>
        </>
    );
};

export default FloodAndTidelandServices;
