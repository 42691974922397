export const executiveTeam = () => {
    return [
        {
            name: "Home",
            url: "/",
        },
        {
            name: "Executive Team",
            url: "",
        },
    ];
};

export const contactUS = () => {
    return [
        {
            name: "Home",
            url: "/",
        },
        {
            name: "Contact Us",
            url: "",
        },
    ];
};

export const childSupportJudgmentSearchBreadcrumb = () => {
    return [
        {
            name: "Home",
            url: "/",
        },
        {
            name: "Solutions",
            url: "/solutions",
        },

        {
            name: "Name Searches",
            url: "/solutions/name-searches",
        },
        {
            name: "Child Support Judgment",
            url: "",
        },
    ];
};

export const solutionSummaryBreadcrumb = () => {
    return [
        {
            name: "Home",
            url: "/",
        },
        {
            name: "Solutions",
            url: "",
        },
    ];
};

export const getStarted = () => {
    return [
        {
            name: "Home",
            url: "/",
        },
        {
            name: "Get Started",
            url: "",
        },
    ];
};

export const requestAccount = () => {
    return [
        {
            name: "Home",
            url: "/",
        },
        {
            name: "Get Started",
            url: "/get-started",
        },
        {
            name: "Request an Account",
            url: "",
        },
    ];
};

export const requestId = () => {
    return [
        {
            name: "Home",
            url: "/",
        },
        {
            name: "Get Started",
            url: "/get-started",
        },
        {
            name: "Request ID",
            url: "",
        },
    ];
};

export const requestInformation = () => {
    return [
        {
            name: "Home",
            url: "/",
        },
        {
            name: "Contact Us",
            url: "/contact-us",
        },
        {
            name: "Request Information",
            url: "",
        },
    ];
};

export const judgmentLienSearchBreadcrumb = () => {
    return [
        {
            name: "Home",
            url: "/",
        },
        {
            name: "Solutions",
            url: "/solutions",
        },
        {
            name: "Name Searches",
            url: "/solutions/name-searches",
        },
        {
            name: "Judgment Lien Search",
            url: "",
        },
    ];
};

export const patriotNameSearchBreadcrumb = () => {
    return [
        {
            name: "Home",
            url: "/",
        },
        {
            name: "Solutions",
            url: "/solutions",
        },
        {
            name: "Name Searches",
            url: "/solutions/name-searches",
        },
        {
            name: "Patriot Name Search",
            url: "",
        },
    ];
};
export const aboutUS = () => {
    return [
        {
            name: "Home",
            url: "/",
        },
        {
            name: "About",
            url: "",
        },
    ];
};

export const newsInsight = () => {
    return [
        {
            name: "Home",
            url: "/",
        },
        {
            name: "News & Insights",
            url: "",
        },
    ];
};

export const faqs = () => {
    return [
        {
            name: "Home",
            url: "/",
        },
        {
            name: "FAQ",
            url: "",
        },
    ];
};

export const testimonials = () => {
    return [
        {
            name: "Home",
            url: "/",
        },
        {
            name: "Testimonials",
            url: "",
        },
    ];
};

export const nameSearchesBreadcrumb = () => {
    return [
        {
            name: "Home",
            url: "/",
        },
        {
            name: "Solutions",
            url: "/solutions",
        },
        {
            name: "Name Searches",
            url: "",
        },
    ];
};

export const taxAndAssessmentBreadcrumb = () => {
    return [
        {
            name: "Home",
            url: "/",
        },
        {
            name: "Solutions",
            url: "/solutions",
        },
        {
            name: "Tax Searches",
            url: "/solutions/tax-searches",
        },
        {
            name: "New Jersey Tax and Assessment Searches",
            url: "",
        },
    ];
};

export const taxCertificationsBreadcrumb = () => {
    return [
        {
            name: "Home",
            url: "/",
        },
        {
            name: "Solutions",
            url: "/solutions",
        },
        {
            name: "Tax Searches",
            url: "/solutions/tax-searches",
        },
        {
            name: "Pennsylvania Tax Certifications",
            url: "",
        },
    ];
};

export const taxSearchesBreadcrumb = () => {
    return [
        {
            name: "Home",
            url: "/",
        },
        {
            name: "Solutions",
            url: "/solutions",
        },
        {
            name: "Tax Searches",
            url: "",
        },
    ];
};

export const floodSearchDeterminationBreadcrumb = () => {
    return [
        {
            name: "Home",
            url: "/",
        },
        {
            name: "Solutions",
            url: "/solutions",
        },
        {
            name: "Flood and Tideland Services",
            url: "/solutions/flood-and-tideland",
        },
        {
            name: "Flood Search Determination",
            url: "",
        },
    ];
};

export const lifeOfLoanFloodMonitoringBreadcrumb = () => {
    return [
        {
            name: "Home",
            url: "/",
        },
        {
            name: "Solutions",
            url: "/solutions",
        },
        {
            name: "Flood and Tideland Services",
            url: "/solutions/flood-and-tideland",
        },
        {
            name: "Life-of-Loan Flood Monitoring",
            url: "",
        },
    ];
};

export const tidelandClaimsSearchBreadcrumb = () => {
    return [
        {
            name: "Home",
            url: "/",
        },
        {
            name: "Solutions",
            url: "/solutions",
        },
        {
            name: "Flood and Tideland Services",
            url: "/solutions/flood-and-tideland",
        },
        {
            name: "Tideland Claims Search",
            url: "",
        },
    ];
};

export const tidelandGrantSearchBreadcrumb = () => {
    return [
        {
            name: "Home",
            url: "/",
        },
        {
            name: "Solutions",
            url: "/solutions",
        },
        {
            name: "Flood and Tideland Services",
            url: "/solutions/flood-and-tideland",
        },
        {
            name: "Tideland Grant Search",
            url: "",
        },
    ];
};

export const wetlandDeterminationBreadcrumb = () => {
    return [
        {
            name: "Home",
            url: "/",
        },
        {
            name: "Solutions",
            url: "/solutions",
        },
        {
            name: "Flood and Tideland Services",
            url: "/solutions/flood-and-tideland",
        },
        {
            name: "Wetland Determination",
            url: "",
        },
    ];
};

export const floodAndTidelandServicesBreadcrumb = () => {
    return [
        {
            name: "Home",
            url: "/",
        },
        {
            name: "Solutions",
            url: "/solutions",
        },
        {
            name: "Flood and Tideland Services",
            url: "",
        },
    ];
};

export const countySearchBreadcrumb = () => {
    return [
        {
            name: "Home",
            url: "/",
        },
        {
            name: "Solutions",
            url: "/solutions",
        },
        {
            name: "County Search Services",
            url: "/solutions/county-search",
        },
        {
            name: "County Search Services",
            url: "",
        },
    ];
};

export const countySearchServicesBreadcrumb = () => {
    return [
        {
            name: "Home",
            url: "/",
        },
        {
            name: "Solutions",
            url: "/solutions",
        },
        {
            name: "County Search Services",
            url: "",
        },
    ];
};

export const statusReportBreadcrumb = () => {
    return [
        {
            name: "Home",
            url: "/",
        },
        {
            name: "Solutions",
            url: "/solutions",
        },
        {
            name: "Corporate and UCC Services",
            url: "/solutions/corporate-and-ucc",
        },
        {
            name: "Status Report",
            url: "",
        },
    ];
};

export const franchiseAndCorporateTaxReportBreadcrumb = () => {
    return [
        {
            name: "Home",
            url: "/",
        },
        {
            name: "Solutions",
            url: "/solutions",
        },
        {
            name: "Corporate and UCC Services",
            url: "/solutions/corporate-and-ucc",
        },
        {
            name: "Franchise Tax/Corporate Tax Lien Report",
            url: "",
        },
    ];
};

export const goodStandingCertificateBreadcrumb = () => {
    return [
        {
            name: "Home",
            url: "/",
        },
        {
            name: "Solutions",
            url: "/solutions",
        },
        {
            name: "Corporate and UCC Services",
            url: "/solutions/corporate-and-ucc",
        },
        {
            name: "Good Standing Certificate",
            url: "",
        },
    ];
};

export const legalExistenceCertificateBreadcrumb = () => {
    return [
        {
            name: "Home",
            url: "/",
        },
        {
            name: "Solutions",
            url: "/solutions",
        },
        {
            name: "Corporate and UCC Services",
            url: "/solutions/corporate-and-ucc",
        },
        {
            name: "Legal Existence (Good Standing) Certificate",
            url: "",
        },
    ];
};

export const corporateCopiesBreadcrumb = () => {
    return [
        {
            name: "Home",
            url: "/",
        },
        {
            name: "Solutions",
            url: "/solutions",
        },
        {
            name: "Corporate and UCC Services",
            url: "/solutions/corporate-and-ucc",
        },
        {
            name: "Corporate Copies",
            url: "",
        },
    ];
};

export const uccPlusBreadcrumb = () => {
    return [
        {
            name: "Home",
            url: "/",
        },
        {
            name: "Solutions",
            url: "/solutions",
        },
        {
            name: "Corporate and UCC Services",
            url: "/solutions/corporate-and-ucc",
        },
        {
            name: "UCC Plus",
            url: "",
        },
    ];
};

export const uccStateCertifiedBreadcrumb = () => {
    return [
        {
            name: "Home",
            url: "/",
        },
        {
            name: "Solutions",
            url: "/solutions",
        },
        {
            name: "Corporate and UCC Services",
            url: "/solutions/corporate-and-ucc",
        },
        {
            name: "UCC State Certified",
            url: "",
        },
    ];
};

export const uccStateReportBreadcrumb = () => {
    return [
        {
            name: "Home",
            url: "/",
        },
        {
            name: "Solutions",
            url: "/solutions",
        },
        {
            name: "Corporate and UCC Services",
            url: "/solutions/corporate-and-ucc",
        },
        {
            name: "UCC State Report",
            url: "",
        },
    ];
};

export const corporateAndUCCServicesBreadcrumb = () => {
    return [
        {
            name: "Home",
            url: "/",
        },
        {
            name: "Solutions",
            url: "/solutions",
        },
        {
            name: "Corporate and UCC Services",
            url: "",
        },
    ];
};

export const dueDiligenceBreadcrumb = () => {
    return [
        {
            name: "Home",
            url: "/",
        },
        {
            name: "Solutions",
            url: "/solutions",
        },
        {
            name: "Nationwide Services",
            url: "/solutions/nationwide-services",
        },
        {
            name: "Due Diligence Services",
            url: "",
        },
    ];
};

export const corporateFilingAndRegisteredAgentBreadcrumb = () => {
    return [
        {
            name: "Home",
            url: "/",
        },
        {
            name: "Solutions",
            url: "/solutions",
        },
        {
            name: "Nationwide Services",
            url: "/solutions/nationwide-services",
        },
        {
            name: "Corporate Filing & Registered Agent Services",
            url: "",
        },
    ];
};

export const floodSearchBreadcrumb = () => {
    return [
        {
            name: "Home",
            url: "/",
        },
        {
            name: "Solutions",
            url: "/solutions",
        },
        {
            name: "Nationwide Services",
            url: "/solutions/nationwide-services",
        },
        {
            name: "Flood Search",
            url: "",
        },
    ];
};

export const additionalLolFloodMonitoringBreadcrumb = () => {
    return [
        {
            name: "Home",
            url: "/",
        },
        {
            name: "Solutions",
            url: "/solutions",
        },
        {
            name: "Nationwide Services",
            url: "/solutions/nationwide-services",
        },
        {
            name: "Life-of-Loan Flood Monitoring",
            url: "",
        },
    ];
};

export const additionalServicesBreadcrumb = () => {
    return [
        {
            name: "Home",
            url: "/",
        },
        {
            name: "Solutions",
            url: "/solutions",
        },
        {
            name: "Nationwide Services",
            url: "",
        },
    ];
};

export const patriotNameSearchAdditionalBreadcrumb = () => {
    return [
        {
            name: "Home",
            url: "/",
        },
        {
            name: "Solutions",
            url: "/solutions",
        },
        {
            name: "Nationwide Services",
            url: "/solutions/nationwide-services",
        },
        {
            name: "Patriot Name Search",
            url: "",
        },
    ];
};

export const termsAndConditions = () => {
    return [
        {
            name: "Home",
            url: "/",
        },
        {
            name: "Terms and Conditions",
            url: "",
        },
    ];
};

export const disabilityStatement = () => {
    return [
        {
            name: "Home",
            url: "/",
        },
        {
            name: "Accessibility Statement",
            url: "",
        },
    ];
};

export const formsAndLinks = () => {
    return [
        {
            name: "Home",
            url: "/",
        },
        {
            name: "Forms & Links",
            url: "",
        },
    ];
};

export const notFound = () => {
    return [
        {
            name: "Home",
            url: "/",
        },
        {
            name: "Not Found",
            url: "",
        },
    ];
};

export const titlePlantNewsPost = () => {
    return [
        {
            name: "Home",
            url: "/",
        },
        {
            name: "Solutions",
            url: "/solutions",
        },
        {
            name: "County Search Services",
            url: "/solutions/county-search",
        },
        {
            name: "County Search Services",
            url: "/solutions/county-search/county-search",
        },
        {
            name: "DTS Title Plant",
            url: "",
        },
    ];
};
