import Innerpageheading from "../../../components/common/innerpageheading";
import Breadcrumb from "../../../components/common/breadcrumb";
import ServiceDetails from "../../../components/solutions/ServiceDetails";

import { taxCertificationsPageHeader } from "../../../components/common/pageheader";
import { taxCertificationsBreadcrumb } from "../../../components/common/breadcrumbs";
import Contact from "../../../components/common/contact";

const TaxCertifications = ({
    showHeader = true,
    showBreadcrum = true,
    showTextExpand = false,
}) => {
    var bannerImage = "/img/solutions/tax_certifications.jpg";
    var title = "Pennsylvania Tax Certifications";
    var availableFor = ["Pennsylvania"];
    var certified = "Yes";

    var getStartedUrl =
        showHeader === false
            ? "/solutions/tax-searches/pa-tax-certifications"
            : "/get-started";

    const TextContent = () => {
        return (
            <>
                <p className="solutions-details-page-p">
                    We have been providing Tax Certifications in Pennsylvania
                    since 1996 that contain all lienable information, including
                    public utility and lien letter content. Because of our
                    experience in Pennsylvania Tax Certifications, we take the
                    guesswork out of searching with a network of experienced
                    searchers, certified results and consistent formatting.
                </p>
                <p className="solutions-details-page-p">
                    Your experience with our PA Tax Search product begins with
                    access to an expansive property database. This will allow
                    you to correctly identify properties you want to order. You
                    have the option of including a Utility Search as well. Our
                    statewide coverage allows you to obtain a Tax Certification
                    and Utility Search statewide right online.
                </p>
                <p className="solutions-details-page-p">
                    We cover more than 3,600 taxing authorities in Pennsylvania.
                </p>
                <p className="solutions-details-page-p">
                    <ul>
                        <li>
                            Write just one check for your tax and utility needs.
                        </li>
                        <li>
                            Concise consistent formatting means you can spend
                            less time reviewing the search since the information
                            is provided the same way each time and in each
                            municipality
                        </li>
                    </ul>
                </p>
            </>
        );
    };

    return (
        <>
            {showHeader === true ? (
                <Innerpageheading
                    pageHeadInfo={taxCertificationsPageHeader()}
                />
            ) : null}

            {showBreadcrum === true ? (
                <Breadcrumb breadcrumbInfo={taxCertificationsBreadcrumb()} />
            ) : null}

            <div
                className={
                    showHeader === false
                        ? "pt-4 px-5 px-sm-4 px-md-3 px-lg-4 px-xxl-0"
                        : "container pt-6 pb-5 px-5 px-sm-4 px-md-3 px-lg-4 px-xxl-0"
                }
            >
                <ServiceDetails
                    title={title}
                    bannerUrl={bannerImage}
                    availableFor={availableFor}
                    certified={certified}
                    showTextExpand={showTextExpand}
                    textContent={<TextContent></TextContent>}
                    getStartedUrl={getStartedUrl}
                ></ServiceDetails>
            </div>

            {showHeader === true ? (
                <div className="row contact-loaction">
                    <Contact />
                </div>
            ) : null}
        </>
    );
};

export default TaxCertifications;
