import Innerpageheading from "../../../components/common/innerpageheading";
import Breadcrumb from "../../../components/common/breadcrumb";
import ServiceDetails from "../../../components/solutions/ServiceDetails";

import { uccPlusPageHeader } from "../../../components/common/pageheader";
import { uccPlusBreadcrumb } from "../../../components/common/breadcrumbs";
import Contact from "../../../components/common/contact";

const UCCPlus = ({
    showHeader = true,
    showBreadcrum = true,
    showTextExpand = false,
}) => {
    var bannerImage = "/img/solutions/ucc_plus.jpg";
    var title = "UCC Plus";
    var availableFor = ["New Jersey"];
    var certified = "No";

    var getStartedUrl =
        showHeader === false
            ? "/solutions/corporate-and-ucc/ucc-plus"
            : "/get-started";

    const TextContent = () => {
        return (
            <>
                <p className="solutions-details-page-p">
                    Charles Jones UCC Plus Search features a unique Dual Index
                    Search, which examines both the NJ State UCC records as well
                    as a proprietary database to complete your search faster,
                    with refined results. Most results and all pertinent copies
                    are returned in 4 hours or less, helping to expedite your
                    business transaction. UCC Plus also offers a fixed fee - no
                    hidden fees, no guesswork - with all copies included.
                </p>
            </>
        );
    };

    return (
        <>
            {showHeader === true ? (
                <Innerpageheading pageHeadInfo={uccPlusPageHeader()} />
            ) : null}

            {showBreadcrum === true ? (
                <Breadcrumb breadcrumbInfo={uccPlusBreadcrumb()} />
            ) : null}

            <div
                className={
                    showHeader === false
                        ? "pt-4 px-5 px-sm-4 px-md-3 px-lg-4 px-xxl-0"
                        : "container pt-6 pb-5 px-5 px-sm-4 px-md-3 px-lg-4 px-xxl-0"
                }
            >
                <ServiceDetails
                    title={title}
                    bannerUrl={bannerImage}
                    availableFor={availableFor}
                    certified={certified}
                    showTextExpand={showTextExpand}
                    textContent={<TextContent></TextContent>}
                    getStartedUrl={getStartedUrl}
                ></ServiceDetails>
            </div>

            {showHeader === true ? (
                <div className="row contact-loaction">
                    <Contact />
                </div>
            ) : null}
        </>
    );
};

export default UCCPlus;
