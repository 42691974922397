import React from "react";
import Innerpageheading from "../common/innerpageheading";
import Breadcrumb from "../common/breadcrumb";
import Contact from "../common/contact";
import { faqs } from "../common/breadcrumbs";
import { faqsPageHeader } from "../common/pageheader";
import Ourexperts from "../common/ourexperts";
import "./faqs.css";
import { allArticles } from "../newsinsights/NewsConstants";
const Faqs = () => {
    return (
        <>
            <Innerpageheading pageHeadInfo={faqsPageHeader()} />
            <Breadcrumb breadcrumbInfo={faqs()} />

            <div className="row faqs-content justify-content-center">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-8 faqs-left-content">
                    <h2 className="faqs-heading">Frequently Asked Questions</h2>
                    <p className="faqs-heading-desc1">
                        Many of the products Charles Jones offers are certified.
                        What does that mean? Simply put, we stand behind our
                        work to help mitigate risks and give you peace of mind
                        at the closing table.
                    </p>
                    <p className="faqs-heading-desc2">
                        Our wide range of products assist the title, legal and
                        lending markets with due diligence and compliance
                        solutions. Many of our products were developed or
                        enhanced to assist in compliance with regulatory
                        challenges for these markets. Below are sources that may
                        provide you with further context of the solutions our
                        products may provide to you.
                    </p>
                    <ul className="faqs-impinfo">
                        <li className="faqs-impinfo-desc">
                            <a href="#question-1">
                                Why might you need to order a Child Support
                                Judgment Search?
                            </a>
                        </li>

                        <li className="faqs-impinfo-desc">
                            <a href="#question-2">
                                Why might you need to order a Patriot Name
                                Search?
                            </a>
                        </li>

                        <li className="faqs-impinfo-desc">
                            <a href="#question-3">
                                {" "}
                                Why might you need to order a Flood Search?
                            </a>
                        </li>

                        <li className="faqs-impinfo-desc">
                            <a href="#question-4">
                                Why might you need to order a Tideland Search?
                            </a>
                        </li>
                    </ul>

                    <div id="question-1" className="faqs-judgment-search">
                        <h4 className="faqs-judgment-search-title">
                            Why might you need to order a Child Support Judgment
                            Search?
                        </h4>
                        <p className="faqs-judgment-search-desc">
                            NJ State legislation requires attorneys in civil
                            actions to ascertain whether their clients are child
                            support judgment debtors before releasing a monetary
                            award or settlement.
                        </p>
                        <a
                            className="faqs-judgment-search-link"
                            href="https://legiscan.com/NJ/text/S2348/id/668620"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            https://legiscan.com/NJ/text/S2348/id/668620
                        </a>
                        <span
                            className="faqs-judgment-search-link faqs-arrow"
                            onClick={() =>
                                window.open(
                                    "https://legiscan.com/NJ/text/S2348/id/668620",
                                    "_blank"
                                )
                            }
                        >
                            {" "}
                            ↗
                        </span>
                    </div>

                    <div id="question-2" className="faqs-patriot-name-search">
                        <h4 className="faqs-patriot-name-search-title">
                            Why might you need to order a Patriot Name Search?
                        </h4>
                        <p className="faqs-patriot-name-search-desc">
                            NJ State legislation requires attorneys in civil
                            actions to ascertain whether their clients are child
                            support judgment debtors before releasing a monetary
                            award or settlement.
                        </p>

                        <div className="faqs-links">
                            <a
                                className="faqs-patriot-name-search-link"
                                href="https://www.treasury.gov/resource-center/sanctions/Programs/Documents/terror.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                https://www.treasury.gov/resource-center/sanctions/Programs/Documents/terror.pdf
                            </a>
                            <span
                                className="faqs-patriot-name-search-link faqs-arrow"
                                onClick={() =>
                                    window.open(
                                        "https://www.treasury.gov/resource-center/sanctions/Programs/Documents/terror.pdf",
                                        "_blank"
                                    )
                                }
                            >
                                {" "}
                                ↘
                            </span>
                        </div>

                        <div className="faqs-links">
                            <a
                                className="faqs-patriot-name-search-link"
                                href="https://www.treasury.gov/resource-center/sanctions/Documents/13268.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                https://www.treasury.gov/resource-center/sanctions/Documents/13268.pdf
                            </a>
                            <span
                                className="faqs-patriot-name-search-link faqs-arrow"
                                onClick={() =>
                                    window.open(
                                        "https://www.treasury.gov/resource-center/sanctions/Documents/13268.pdf",
                                        "_blank"
                                    )
                                }
                            >
                                {" "}
                                ↘
                            </span>
                        </div>

                        <div className="faqs-links">
                            <a
                                className="faqs-patriot-name-search-link"
                                href="https://www.gpo.gov/fdsys/pkg/FR-2012-05-03/pdf/2012-10884.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                https://www.gpo.gov/fdsys/pkg/FR-2012-05-03/pdf/2012-10884.pdf
                            </a>
                            <span
                                className="faqs-patriot-name-search-link faqs-arrow"
                                onClick={() =>
                                    window.open(
                                        "https://www.gpo.gov/fdsys/pkg/FR-2012-05-03/pdf/2012-10884.pdf",
                                        "_blank"
                                    )
                                }
                            >
                                {" "}
                                ↘
                            </span>
                        </div>

                        <div className="faqs-links">
                            <a
                                className="faqs-patriot-name-search-link"
                                href="https://www.treasury.gov/resource-center/sanctions/SDN-List/Pages/consolidated.aspx"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                https://www.treasury.gov/resource-center/sanctions/SDN-List/Pages/consolidated.aspx
                            </a>
                            <span
                                className="faqs-patriot-name-search-link faqs-arrow"
                                onClick={() =>
                                    window.open(
                                        "https://www.treasury.gov/resource-center/sanctions/SDN-List/Pages/consolidated.aspx",
                                        "_blank"
                                    )
                                }
                            >
                                {" "}
                                ↗
                            </span>
                        </div>

                        <div className="faqs-links">
                            <a
                                className="faqs-patriot-name-search-link"
                                href="https://www.congress.gov/bill/107th-congress/house-bill/3162"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                https://www.congress.gov/bill/107th-congress/house-bill/3162
                            </a>
                            <span
                                className="faqs-patriot-name-search-link faqs-arrow"
                                onClick={() =>
                                    window.open(
                                        "https://www.congress.gov/bill/107th-congress/house-bill/3162",
                                        "_blank"
                                    )
                                }
                            >
                                {" "}
                                ↗
                            </span>
                        </div>
                    </div>

                    <div id="question-3" className="faqs-flood-search">
                        <h4 className="faqs-flood-search-title">
                            Why might you need to order a Flood Search?
                        </h4>
                        <p className="faqs-flood-search-desc">
                            Flood Disaster Protection Act -
                        </p>
                        <a
                            className="faqs-flood-search-link"
                            href="https://www.fdic.gov/regulations/laws/rules/6000-2400.html"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            https://www.fdic.gov/regulations/laws/rules/6000-2400.html
                        </a>
                        <span
                            className="faqs-flood-search-link faqs-arrow"
                            onClick={() =>
                                window.open(
                                    "https://www.fdic.gov/regulations/laws/rules/6000-2400.html",
                                    "_blank"
                                )
                            }
                        >
                            {" "}
                            ↗
                        </span>
                    </div>

                    <div id="question-4" className="faqs-tideland-search">
                        <h4 className="faqs-tideland-search-title">
                            Why might you need to order a Tideland Search?
                        </h4>
                        <p className="faqs-tideland-search-desc">
                            O'NEILL v. State Hwy. Dept.
                        </p>
                        <a
                            className="faqs-tideland-search-link"
                            href="http://law.justia.com/cases/new-jersey/supreme-court/1967/50-n-j-307-0.html"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            http://law.justia.com/cases/new-jersey/supreme-court/1967/50-n-j-307-0.html
                        </a>
                        <span
                            className="faqs-tideland-search-link faqs-arrow"
                            onClick={() =>
                                window.open(
                                    "http://law.justia.com/cases/new-jersey/supreme-court/1967/50-n-j-307-0.html",
                                    "_blank"
                                )
                            }
                        >
                            {" "}
                            ↗
                        </span>
                    </div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4">
                    <div className="left-badge">
                        <img
                            src="/img/cj-10years.svg"
                            alt="..."
                            className="cj110-years"
                        />
                    </div>
                    <div className="newsinsight-expert-heading">
                        <h2 className="newsinsight-expert-title">
                            Read news & Insights from our Experts
                        </h2>
                    </div>
                    {allArticles?.map((article) => {
                        return (
                            <div
                                className="newsinsight-expert-info"
                                key={article?.slug}
                            >
                                <Ourexperts
                                    article={article}
                                    homePageArticle={false}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>

            <div className="row contact-loaction">
                <Contact />
            </div>
        </>
    );
};

export default Faqs;
