import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import Layout from "./Layout";
import ContactUs from "./components/forms/contactus";
import RequestAccount from "./pages/request-account";
import RequestInfo from "./pages/request-info";
import RequestId from "./pages/request-id";
import ExecutiveTeam from "./components/executiveteam/executiveteam";
import JudgmentLienSearch from "./pages/solutions/name-searches/JudgmentLien";
import ChildSupportJudgmentSearch from "./pages/solutions/name-searches/ChildSupportJudgment";
import Solutions from "./pages/solutions";

import GetStarted from "./pages/get-started";

import About from "./components/about/about";
import NewsInsight from "./components/newsinsights/newsinsight";
import Faqs from "./components/faqs/faqs";
import Testimonials from "./components/testimonials/testimonials";
import PatriotNameSearch from "./pages/solutions/name-searches/PatriotName";
import NameSearches from "./pages/solutions/name-searches/NameSearches";
import TaxAndAssessment from "./pages/solutions/tax-searches/TaxAndAssessment";
import TaxCertifications from "./pages/solutions/tax-searches/TaxCertifications";
import TaxSearches from "./pages/solutions/tax-searches/TaxSearches";
import FloodSearchDetermination from "./pages/solutions/flood-tideland-services/FloodSearchDetermination";
import LifeOfLoanFloodMonitoring from "./pages/solutions/flood-tideland-services/LifeOfLoanFloodMonitoring";
import TidelandClaimsSearch from "./pages/solutions/flood-tideland-services/TidelandClaimsSearch";
import TidelandGrantSearch from "./pages/solutions/flood-tideland-services/TidelandGrantSearch";
import WetlandDetermination from "./pages/solutions/flood-tideland-services/WetlandDetermination";
import FloodAndTidelandServices from "./pages/solutions/flood-tideland-services/FloodAndTidelandServices";
import CountySearch from "./pages/solutions/county-search-services/CountySearch";
import CountySearchServices from "./pages/solutions/county-search-services/CountySearchServices";
import StatusReport from "./pages/solutions/corporate-ucc-services/StatusReport";
import FranchiseAndCorporateTaxReport from "./pages/solutions/corporate-ucc-services/FranchiseAndCorporateTaxReport";
import GoodStandingCertificate from "./pages/solutions/corporate-ucc-services/GoodStandingCertificate";
import LegalExistenceCertificate from "./pages/solutions/corporate-ucc-services/LegalExistenceCertificate";
import CorporateCopies from "./pages/solutions/corporate-ucc-services/CorporateCopies";
import UCCStateCertified from "./pages/solutions/corporate-ucc-services/UCCStateCertified";
import UCCPlus from "./pages/solutions/corporate-ucc-services/UCCPlus";
import CorporateAndUCCServices from "./pages/solutions/corporate-ucc-services/CorporateAndUCCServices";
import UCCStateReport from "./pages/solutions/corporate-ucc-services/UCCStateReport";
import ScrollToTop from "./components/common/scrolltoTop";
import DueDiligence from "./pages/solutions/nationwide-services/DueDiligence";
import CorporateFilingAndRegisteredAgent from "./pages/solutions/nationwide-services/CorporateFilingAndRegisteredAgent";
import FloodSearch from "./pages/solutions/nationwide-services/FloodSearch";
import AdditionalServices from "./pages/solutions/nationwide-services/AdditionalServices";
import TermsAndConditions from "./components/termsandconditions/termsandconditions";
import DisabilityStatement from "./components/disabilitystatement/disabilitystatement";
import FormsAndLinks from "./components/formsandlinks/formsandlinks";
import NotFound from "./pages/not-found";
import TitlePlantNewsPost from "./components/titleplantnewspost/titleplantnewspost";

function App() {
    return (
        <BrowserRouter>
            <ScrollToTop></ScrollToTop>
            <Layout>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/contact-us" element={<ContactUs />} />
                    <Route path="/get-started" element={<GetStarted />} />
                    <Route
                        path="/request-account"
                        element={<RequestAccount />}
                    />
                    <Route path="/request-info" element={<RequestInfo />} />
                    <Route path="/request-id" element={<RequestId />} />
                    <Route path="/executive-team" element={<ExecutiveTeam />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/news-insight" element={<NewsInsight />} />
                    <Route path="/faq" element={<Faqs />} />
                    <Route path="/testimonials" element={<Testimonials />} />
                    <Route
                        path="/solutions/name-searches"
                        element={<NameSearches />}
                    />
                    <Route
                        path="/solutions/name-searches/judgment-lien-search"
                        element={<JudgmentLienSearch />}
                    />
                    <Route
                        path="/solutions/name-searches/child-support-judgment"
                        element={<ChildSupportJudgmentSearch />}
                    />
                    <Route path="/solutions" element={<Solutions />} />
                    <Route
                        path="/solutions/name-searches/patriot-name-search"
                        element={<PatriotNameSearch />}
                    />
                    <Route
                        path="/solutions/tax-searches/nj-tax-and-assessment-searches"
                        element={<TaxAndAssessment />}
                    />
                    <Route
                        path="/solutions/tax-searches/pa-tax-certifications"
                        element={<TaxCertifications />}
                    />
                    <Route
                        path="/solutions/tax-searches"
                        element={<TaxSearches />}
                    />
                    <Route
                        path="/solutions/flood-and-tideland/flood-search-determination"
                        element={<FloodSearchDetermination />}
                    />
                    <Route
                        path="/solutions/flood-and-tideland/lol-flood-monitoring"
                        element={<LifeOfLoanFloodMonitoring />}
                    />
                    <Route
                        path="/solutions/flood-and-tideland/tideland-claims-search"
                        element={<TidelandClaimsSearch />}
                    />
                    <Route
                        path="/solutions/flood-and-tideland/tideland-grant-search"
                        element={<TidelandGrantSearch />}
                    />
                    <Route
                        path="/solutions/flood-and-tideland/wetland-determination"
                        element={<WetlandDetermination />}
                    />
                    <Route
                        path="/solutions/flood-and-tideland"
                        element={<FloodAndTidelandServices />}
                    />
                    <Route
                        path="/solutions/county-search/county-search"
                        element={<CountySearch />}
                    />
                    <Route
                        path="/solutions/county-search"
                        element={<CountySearchServices />}
                    />
                    <Route
                        path="/solutions/corporate-and-ucc/status-report"
                        element={<StatusReport />}
                    />
                    <Route
                        path="/solutions/corporate-and-ucc/franchise-and-corporate-tax-lien-report"
                        element={<FranchiseAndCorporateTaxReport />}
                    />
                    <Route
                        path="/solutions/corporate-and-ucc/good-standing"
                        element={<GoodStandingCertificate />}
                    />
                    <Route
                        path="/solutions/corporate-and-ucc/legal-existence"
                        element={<LegalExistenceCertificate />}
                    />
                    <Route
                        path="/solutions/corporate-and-ucc/corporate-copies"
                        element={<CorporateCopies />}
                    />
                    <Route
                        path="/solutions/corporate-and-ucc/ucc-plus"
                        element={<UCCPlus />}
                    />
                    <Route
                        path="/solutions/corporate-and-ucc/ucc-state-certified"
                        element={<UCCStateCertified />}
                    />
                    <Route
                        path="/solutions/corporate-and-ucc/ucc-state-report"
                        element={<UCCStateReport />}
                    />
                    <Route
                        path="/solutions/corporate-and-ucc/"
                        element={<CorporateAndUCCServices />}
                    />
                    <Route
                        path="/solutions/nationwide-services/due-diligence"
                        element={<DueDiligence />}
                    />
                    <Route
                        path="/solutions/nationwide-services/corp-filing-registered-agent"
                        element={<CorporateFilingAndRegisteredAgent />}
                    />
                    <Route
                        path="/solutions/nationwide-services/flood-search"
                        element={<FloodSearch />}
                    />
                    <Route
                        path="/solutions/nationwide-services/lol-flood-monitoring"
                        element={<LifeOfLoanFloodMonitoring />}
                    />
                    <Route
                        path="/solutions/nationwide-services/patriot-name-search"
                        element={<PatriotNameSearch />}
                    />
                    <Route
                        path="/solutions/nationwide-services/"
                        element={<AdditionalServices />}
                    />
                    <Route
                        path="/terms-and-conditions"
                        element={<TermsAndConditions />}
                    />
                    <Route
                        path="/disability-statement"
                        element={<DisabilityStatement />}
                    />
                    <Route
                        path="/forms-and-links"
                        element={<FormsAndLinks />}
                    />
                    <Route
                        path="/dts-title-plant"
                        element={<TitlePlantNewsPost />}
                    />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Layout>
        </BrowserRouter>
    );
}

export default App;
