import Innerpageheading from "../../../components/common/innerpageheading";
import Breadcrumb from "../../../components/common/breadcrumb";
import ServiceDetails from "../../../components/solutions/ServiceDetails";

import { floodSearchDeterminationPageHeader } from "../../../components/common/pageheader";
import { floodSearchDeterminationBreadcrumb } from "../../../components/common/breadcrumbs";
import Contact from "../../../components/common/contact";

const FloodSearchDetermination = ({
    showHeader = true,
    showBreadcrum = true,
    showTextExpand = false,
}) => {
    var bannerImage = "/img/solutions/flood_search.jpg";
    var title = "Flood Search Determination";
    var availableFor = ["Nationwide"];
    var certified = "No";

    var getStartedUrl =
        showHeader === false
            ? "/solutions/flood-and-tideland/flood-search-determination"
            : "/get-started";

    const TextContent = () => {
        return (
            <>
                <p className="solutions-details-page-p">
                    A flood search is a determination of whether all or a
                    portion of a designated property is located in a special or
                    moderate flood hazard area (i.e., a 100 or 500 year flood
                    zone) based upon the Flood Insurance Rate Maps issued by the
                    FEMA for the sole purpose of complying with the Flood
                    Disaster Protection Act. (Standard flood hazard searches and
                    certifications are not intended as an opinion as to the
                    likelihood of flooding for a particular property).
                </p>
                <p className="solutions-details-page-p">
                    A Charles Jones flood search certification contains (and has
                    for many years) all of the information required for
                    compliance with federal regulations. Flood Searches are also
                    performed for partial flooding areas to determine if a
                    dwelling or other structure is located in a flood zone. This
                    Dwelling Search can help provide answers when the entire
                    property is not in a flood zone, since insurance is only
                    required for dwellings and insurable improvements.
                </p>
                <p className="solutions-details-page-p">
                    The company is a member of the National Flood Determination
                    Association (NFDA), has over 30 years of experience
                    performing these searches and currently maintains one of the
                    region's largest inventories of map resources and data
                    files.
                </p>
            </>
        );
    };

    return (
        <>
            {showHeader === true ? (
                <Innerpageheading
                    pageHeadInfo={floodSearchDeterminationPageHeader()}
                />
            ) : null}

            {showBreadcrum === true ? (
                <Breadcrumb
                    breadcrumbInfo={floodSearchDeterminationBreadcrumb()}
                />
            ) : null}

            <div
                className={
                    showHeader === false
                        ? "pt-4 px-5 px-sm-4 px-md-3 px-lg-4 px-xxl-0"
                        : "container pt-6 pb-5 px-5 px-sm-4 px-md-3 px-lg-4 px-xxl-0"
                }
            >
                <ServiceDetails
                    title={title}
                    bannerUrl={bannerImage}
                    availableFor={availableFor}
                    certified={certified}
                    showTextExpand={showTextExpand}
                    textContent={<TextContent></TextContent>}
                    getStartedUrl={getStartedUrl}
                ></ServiceDetails>
            </div>

            {showHeader === true ? (
                <div className="row contact-loaction">
                    <Contact />
                </div>
            ) : null}
        </>
    );
};

export default FloodSearchDetermination;
