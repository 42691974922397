import React from "react";
import { CJ_PHONE } from "../../constants";
import { Link } from "react-router-dom";

const PrivacyPolicy = ({ showFull }) => {
    return (
        <div className="px-3 privacy-policy-container">
            <Link
                className="privacy-policy-text-link"
                to="https://www.firstam.com/privacy-policy/"
            >
                PRIVACY NOTICE:
                <span className="privacy-policy-inner-text">
                    Learn more about why we collect this information and how we
                    use it.
                </span>
            </Link>
            {showFull ? (
                <div>
                    <p className="privacy-policy-text">
                        Charles Jones Order Cancellation Policy for Search and
                        Services Requests:
                    </p>
                    <p className="privacy-policy-text">
                        Only partial or incomplete searches or services may be
                        canceled.
                    </p>
                    <p className="privacy-policy-text">
                        If a cancellation request is received prior to
                        completion, Charles Jones reserves the right to charge
                        all costs accrued up until the cancellation of the
                        search or service.
                    </p>
                    <p className="privacy-policy-text">
                        Once a search or service is completed, the full charge
                        will apply.
                    </p>
                    <p className="privacy-policy-text">
                        If you have any questions, please contact our Customer
                        Service Call Center at {CJ_PHONE}.
                    </p>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default PrivacyPolicy;
