import Innerpageheading from "../../../components/common/innerpageheading";
import Breadcrumb from "../../../components/common/breadcrumb";
import ServiceDetails from "../../../components/solutions/ServiceDetails";

import { corporateFilingAndRegisteredAgentPageHeader } from "../../../components/common/pageheader";
import { corporateFilingAndRegisteredAgentBreadcrumb } from "../../../components/common/breadcrumbs";
import Contact from "../../../components/common/contact";

const CorporateFilingAndRegisteredAgent = ({
    showHeader = true,
    showBreadcrum = true,
    showTextExpand = false,
}) => {
    var bannerImage = "/img/solutions/corp_filing.jpg";
    var title = "Corporate Filing & Registered Agent Services";
    var availableFor = ["Nationwide"];
    var certified = "No";

    var getStartedUrl =
        showHeader === false
            ? "/solutions/nationwide-services/corp-filing-registered-agent"
            : "/get-started";

    const TextContent = () => {
        return (
            <>
                <p className="solutions-details-page-p">
                    Charles Jones is your source for filing corporate documents
                    locally and nationwide. Our services include filing
                    submissions and retrievals for all types, also including
                    amendments, mergers, dissolutions, and withdrawals. If you
                    need a commercial registered agent, we can also provide this
                    service nationwide or internationally through our corporate
                    affiliation with NRAI.
                </p>
            </>
        );
    };

    return (
        <>
            {showHeader === true ? (
                <Innerpageheading
                    pageHeadInfo={corporateFilingAndRegisteredAgentPageHeader()}
                />
            ) : null}

            {showBreadcrum === true ? (
                <Breadcrumb
                    breadcrumbInfo={corporateFilingAndRegisteredAgentBreadcrumb()}
                />
            ) : null}

            <div
                className={
                    showHeader === false
                        ? "pt-4 px-5 px-sm-4 px-md-3 px-lg-4 px-xxl-0"
                        : "container pt-6 pb-5 px-5 px-sm-4 px-md-3 px-lg-4 px-xxl-0"
                }
            >
                <ServiceDetails
                    title={title}
                    bannerUrl={bannerImage}
                    availableFor={availableFor}
                    certified={certified}
                    showTextExpand={showTextExpand}
                    textContent={<TextContent></TextContent>}
                    getStartedUrl={getStartedUrl}
                ></ServiceDetails>
            </div>

            {showHeader === true ? (
                <div className="row contact-loaction">
                    <Contact />
                </div>
            ) : null}
        </>
    );
};

export default CorporateFilingAndRegisteredAgent;
