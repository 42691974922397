import Innerpageheading from "../../../components/common/innerpageheading";
import Breadcrumb from "../../../components/common/breadcrumb";
import ServiceDetails from "../../../components/solutions/ServiceDetails";

import { judgmentLienSearchPageHeader } from "../../../components/common/pageheader";
import { judgmentLienSearchBreadcrumb } from "../../../components/common/breadcrumbs";
import Contact from "../../../components/common/contact";

const JudgmentLienSearch = ({
    showHeader = true,
    showBreadcrum = true,
    showTextExpand = false,
}) => {
    var bannerImage = "/img/solutions/judgment_lien.jpg";
    var title = "Judgment Lien Search";
    var availableFor = ["Nationwide"];
    var certified = "Yes";

    var getStartedUrl =
        showHeader === false
            ? "/solutions/name-searches/judgment-lien-search"
            : "/get-started";

    const TextContent = () => {
        return (
            <>
                <p className="solutions-details-page-p">
                    <span>
                        A judgment search or judgment lien search (i.e., Upper
                        Court Judgment Search) in New Jersey consists of a
                        certified search{" "}
                    </span>
                    of judgments, liens, and bankruptcies filed in the NJ
                    Superior Court, the U.S. District Court for NJ and the U.S.
                    Bankruptcy Court for NJ. A judgment search also includes a
                    search of child support judgments filed in New Jersey and
                    CERCLA liens (also known as "Superfund" liens).
                </p>
                <p className="solutions-details-page-p">
                    Judgment searches can be run for any time period up to
                    twenty years, which is the statute of limitations for most
                    judgment liens filed in New Jersey. Judgment liens docketed
                    in the Law Division of the Superior Court extend as a lien
                    on real estate throughout the jurisdiction of the Court, and
                    therefore have "statewide general lien" status. Charles
                    Jones has consistently set the standard and is the leading
                    source for certified judgment searches since it first
                    performed these searches in 1911.
                </p>
            </>
        );
    };

    return (
        <>
            {showHeader === true ? (
                <Innerpageheading
                    pageHeadInfo={judgmentLienSearchPageHeader()}
                />
            ) : null}

            {showBreadcrum === true ? (
                <Breadcrumb breadcrumbInfo={judgmentLienSearchBreadcrumb()} />
            ) : null}

            <div
                className={
                    showHeader === false
                        ? "pt-4 px-5 px-sm-4 px-md-3 px-lg-4 px-xxl-0"
                        : "container pt-6 pb-5 px-5 px-sm-4 px-md-3 px-lg-4 px-xxl-0"
                }
            >
                <ServiceDetails
                    title={title}
                    bannerUrl={bannerImage}
                    availableFor={availableFor}
                    certified={certified}
                    showTextExpand={showTextExpand}
                    textContent={<TextContent></TextContent>}
                    getStartedUrl={getStartedUrl}
                ></ServiceDetails>
            </div>

            {showHeader === true ? (
                <div className="row contact-loaction">
                    <Contact />
                </div>
            ) : null}
        </>
    );
};

export default JudgmentLienSearch;
