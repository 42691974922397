import { Link } from "react-router-dom";
import { CJ_PHONE } from "../../constants";

const tidelandsArticle = {
    title: "What's New in Tidelands: Lagoon Treatment and the Perri Case By Joseph A. Grabas, CTP, NTP",
    slug: "new-in-tidelands",
    fullPageJsx: (
        <div>
            <p>
                While the New Jersey Tidelands laws have remained rather static
                for the past several decades, And the statute has seen minimal
                change, there have been a few interesting cases and decisions
                that, among other things, demonstrate the importance of language
                within grants. Take Panetta v Equity One, 190 NJ 307 (2007),
                for example, which revisited and confirmed long standing real
                property principles. The Panetta Court upheld the age-old legal
                tenet that one corporeal estate cannot be appurtenant to
                another. In other words, “land cannot be appurtenant to land.”
                Therefore, a Tidelands Grant, which is the fee title to land
                under water, is not, and cannot, be appurtenant to the adjacent
                upland. Each parcel, grant and upland, may be conveyed
                separately and are not forever attached and connected. This
                simple rule instructs us that we must always make sure that both
                parcels are specifically described in each deed or mortgage if
                they are intended to be conveyed together. The absence of the
                Grant from the deed or mortgage description will be fatal, as
                Equity One found out.
            </p>
            <p>
                A more recent case, Perri v. State of New Jersey, DEP, Docket
                No. 3926-16T3 (2018)<sup>(1)</sup>, which was upheld in the NJ
                Appellate Division, emphasizes the importance of not only
                identifying tidelands grants, leases and/or licenses, but to
                focus on the exact language in those documents. Perri owned
                property in the Curtis Point section of Brick Township, Ocean
                County. That property benefited from a Grant given to the
                Peninsula Corporation in 1969. The grant ran from the Mean High
                Water Line out to the Pierhead Line. It generally prohibited
                fill beyond the bulkhead line.
            </p>
            <p>
                <em>
                    “This grant is made subject to the limitations that neither
                    the grantee herein nor its successors or assigns shall
                    exclude the tidewaters from lands above described nor fill
                    in, erect a pier or piers or otherwise improve or develop
                    the same, and not appropriate the said lands under water to
                    its own exclusive use, until a permit therefor shall have
                    been issued to it for that purpose.” (emphasis added)
                </em>
            </p>
            <p>
                In 1994, Perri did apply for and receive a permit from the NJDEP
                to replace his bulkhead eighteen (18) inches beyond the existing
                bulkhead located at the Bulkhead Line. Thus “appropriating the
                said lands under water (between the bulkhead and pierhead lines)
                to its own exclusive use.” Perri made a subsequent application
                to the Tidelands Resource Council (a different branch of the
                NJDEP) for a Statement of No Interest to cover the 18”
                extension, since it had dutifully obtained the required permit
                as referenced in the grant. The Tidelands Resource Council
                approved the Statement of No Interest, but the NJDEP
                Commissioner vetoed the Council's approval. Perri filed a
                complaint in Superior Court seeking to invalidate the veto.
                Perri won on summary judgment and the NJDEP appealed to the
                Appellate Court and the trial court decision was upheld.
            </p>
            <p>
                The language found in Tidelands/Riparian documents have changed
                decidedly over the years because of court decisions such as
                this. Needless to say a grant given today, will not contain the
                same permit language found in previous grants. So, it instructs
                us that all grants must be examined carefully from front to back
                in order to truly ascertain the potential risks involved in
                dealing with lands now or formerly flowed by tidal waters. One
                of the primary differences is the way in which the grant maps
                were created (the maps contained within the grants, not to be
                confused with claim maps). Prior to 1972, when an individual or
                entity owning waterfront property was interested in buying the
                land under water adjacent to said property, they would
                commission a survey, showing the MHWL at that time and the area
                under water adjoining same, and then make application to the
                NJDEP in reliance upon said map.
            </p>
            <p>
                Neither the NJDEP nor the Tidelands Resource Council (3) were
                actively involved in the mapping of tidelands, not until O'Neill
                v. State Highway Department, 50 NJ 307 (1967). This decision
                changed the relatively passive nature of the NJDEP regarding
                tidelands claims into a detailed mapping and enforcement of such
                claims. Therefore, the grants issued prior to 1972 did not
                necessarily take into account the subsequently mapped claims
                that are presented today. In other words, the old grants and the
                “new” maps don't always agree and therefore, must be justified.
            </p>
            <p>
                Similarly, the language contained in the older grants does not
                uniformly match the language in today's grants. Except for the
                requirement for upland ownership in accordance with NJSA
                12:3-45a, wherein the grant may be voided in the event that the
                grantee in said grant does not prove to be the upland owner at
                the time of issuance, that still appears in every new grant.
                Which brings us to our second topic, Lagoons. The vast majority
                of Lagoon developments along the Jersey coast are a combination
                of Upland and Tidelands. A lagoon developer purchased a tract of
                upland, such as in the case of Peninsula Corporation and then
                augmented it by purchasing the adjacent tidelands. Then the
                developer bulkheads and fills, excavates and dredges to form the
                lagoons. The fee title to the lagoon beds is vested in the
                developer or possibly transferred to a lagoon association. The
                upland lots are conveyed to lagoon lot purchasers.
            </p>
            <p>
                The State, having already conveyed title through the grant
                cannot claim ownership to the lagoon beds. Even though they are
                underwater and flowed by the tide, they are private property.
                However, because many of the tideland grants obtained by lagoon
                developers are based upon footage along the MHWL, the Attorney
                General has ruled that any tributaries running inland from the
                MHWL, which were not specifically set forth on the Grant Map,
                were NOT conveyed under the grant and therefore are claimed by
                the State of New Jersey.<sup>{"(3)"}</sup>
            </p>
            <p>
                Often it will be found that a former tributary runs through the
                upland lot and continues through the lagoon. When upland owners
                make an application to the TRC to clear record title, they may
                also ask to clear the continuation of the tributary into the
                lagoon. The State cannot convey the lagoon bed because it is
                private property, they are limited to the area claimed as the
                former bed of the tributary.
            </p>

            <p>
                On February 5, 2020, the TRC adopted a new policy regarding
                grants given on Man-made Lagoons. The TRC has agreed that the
                State and the TRC should not convey title (or the appearance of
                conveying title) to property that is not “Tidelands” located
                outside of the applicant's record title. The TRC generally will
                not convey the right to place additional solid fill within any
                tidally flowed Tidelands. Therefore, new lagoon grants will
                require the creation of a new metes and bounds description and a
                completely new title description (part of which would not be
                claimed by the State of NJ) with important additional language,
                so that it will not be misconstrued as establishing fee title
                ownership to any portion of the lagoon outside record title
                beyond that which is claimed by the State.
            </p>

            <p>
                Simply put, the new grants contain additional language which
                significantly limits the nature of the conveyance. Even more
                reason to read every grant, all four corners. Title Insurers,
                Attorneys and Surveyors should visit the new Survey Requirements
                Checklist at:{" "}
                <Link
                    to="https://www.nj.gov/dep/landuse/forms.html"
                    target="_blank"
                >
                    https://www.nj.gov/dep/landuse/forms.html
                </Link>
            </p>

            <p>
                There are 5 common scenarios (described as 1a, 1b, 2a, 2b, and
                2c) for properties within a man-made lagoon community where the
                mapped Tidelands claim from former natural tidal
                creeks/tributaries may have intersected the property in
                question.
            </p>

            <p>
                In the past, the grant exhibit would depict a “box” for the
                entire area subject to the grant, including the area within
                record title. Generally, this box would extend out from the side
                lines of the property beyond record title to include any
                additional claim areas being granted with the condition that any
                area of the grant beyond record title would be subject to the
                rights of the lagoon owner. The TRC had concerns that this could
                be misconstrued as conveying all of the lagoon adjacent to the
                upland and wanted to make it abundantly clear that the grant was
                only conveying rights to the claim area itself, outside of
                record title. Be aware of this newly minted TRC policy regarding
                lagoon grants.
            </p>

            <p>
                A final word of caution when reviewing tidelands grants; the Map
                of Peninsula Park, Section 2B, Map No. F-28 (1970) in the
                Borough of Seaside Park, Ocean County was completely constructed
                out of fill within a Tidelands Grant from 1900 in Liber N page
                363. The property was not filled and subdivided until 1970.
                Within the grant was included the following exception:
                <em>
                    “Excepting out of the above-described tract of land under
                    water the land under water lying within the extension of
                    16th 17th 18th and 19th Avenues and the southerly half of
                    15th and the northerly half of 20th Avenue out to said
                    Exterior wharf line.”
                </em>
            </p>

            <p>
                Unfortunately, the developer in 1970 did not carefully read the
                Tidelands Grant and subdivided the property with a completely
                different road system, rather than extending the roads excepted.
                Because of their failure to properly read the four corners of
                the 1900 Grant, almost every one of the lots on the 1970 map
                have a tidelands claim running through them. The take away
                is…Read The Grant!
            </p>

            <p>
                Joseph A. Grabas, is a 43-year veteran of the NJ title insurance
                industry, author, land title educator, Certified Title
                Professional, National Title Professional and has been
                recognized by the Superior Court of NJ as an expert in land
                title matters. Mr. Grabas has served on the NJ Tidelands
                Resource Council since 2012. The statements, facts and opinions
                set forth above are solely those of the author and do not
                represent the opinions or decisions of the State of New Jersey,
                NJDEP or the Tidelands Resource Council in any way.
            </p>

            <ol>
                <li>
                    <em>
                        <Link
                            to={
                                "https://law.justia.com/cases/new-jersey/appellate-division-unpublished/2018/a3926-16.html"
                            }
                            target="_blank"
                        >
                            https://law.justia.com/cases/new-jersey/appellate-division-unpublished/2018/a3926-16.html
                        </Link>
                    </em>
                </li>
                <li>
                    <em>
                        Previously known as the Resource Development Council,
                        Board of Commerce and Navigation, Board of Riparian
                        Commissioners, and other iterations.
                    </em>
                </li>
                <li>
                    <em>
                        These grants are commonly referred to as “front foot
                        grants.”
                    </em>
                </li>
            </ol>
        </div>
    ),
    cardTitle: "What's New in Tidelands",
    cardSummary:
        "While the New Jersey Tidelands laws have remained rather static for the past several decades, the statute has seen minimal change, there have been interesting cases and decisions that, demonstrate the language within grants.",
};

const tidelandImportanceArticle = {
    title: "Why Tideland Searches Are Important",
    slug: "tideland-importance",
    fullPageJsx: (
        <div>
            <p>
                Tideland searches are very important in real estate transactions
                to uncover if the State of New Jersey has claim to all or a
                portion of the property. A recent story done by ABC7 Eyewitness
                News in New York illustrates the need for a tideland search.
                Click{" "}
                <Link
                    to={
                        "http://7online.com/home/new-jersey-homeowner-finds-state-owns-her-land/786461/"
                    }
                    target="_blank"
                >
                    here
                </Link>{" "}
                to read/view the ABC7 news story.
            </p>
        </div>
    ),
    cardTitle: "Tideland Search Importance",
    cardSummary:
        "Tideland searches are important in real estate transactions to uncover if the State of New Jersey has claim to all or a portion of the property. A story done by ABC7 Eyewitness News illustrates the need for a tideland search.",
};

const dtsTitlePlantsArticle = {
    title: "Did You Know? Many of our DTS title plants are 30 years or older.",
    slug: "dts-title-plant-age",
    fullPageJsx: (
        <div>
            <p>
                <strong>Many of our title plants are 30 years or older!</strong>
            </p>

            <p>
                <em>Work smarter, faster and save money!</em> Once only a
                present owner tool, the majority of our title plants are now
                capable of executing full 30-year searches and higher
                percentages of two-owner searches than ever before. Our
                automated title plants can give you the power to complete County
                Searches yourself - right from your own desktop ... virtually
                anytime, anywhere! Or have us do the work for you as the plants
                also allow us to perform searches and pull copies of deeds and
                mortgages.
            </p>

            <p>Our title plant counties and years of depth include:</p>
            <ul>
                <li>Ocean County: 33</li>
                <li>Hunterdon County: 32</li>
                <li>Monmouth County: 32</li>
                <li>Somerset County: 32</li>
                <li>Warren County: 31</li>
                <li>Atlantic County: 30</li>
                <li>Burlington County: 30</li>
                <li>Camden County: 30</li>
                <li>Morris County: 30</li>
                <li>Sussex County: 30</li>
                <li>Hudson County: 27</li>
                <li>
                    Passaic County:<sup>1</sup> 15
                </li>
                <li>
                    Gloucester County:<sup>2</sup> 14
                </li>
            </ul>

            <p>
                Remember the title plants are open even when the records rooms
                are closed. In this competitive market, get the edge on your
                competition by providing County Searches even when the courts
                are closed! Call us today at {CJ_PHONE}.
            </p>

            <p style={{ fontSize: "13px" }}>
                <sup>1</sup> Plant recordings from 2001 forward; Judgment
                records dating to 1993. <br />
                <sup>2</sup> Plant recordings from 2002 forward; Judgment
                records dating to 1993.
            </p>
        </div>
    ),
    cardTitle: "Many of our DTS title plants are 30 years or older",
    cardSummary:
        "Many of our title plants are 30 years or older! Once only a present owner tool, the majority of our title plants are now capable of executing full 30-year searches and higher percentages of two-owner searches than ever before.",
};

const danielsLawArticle = {
    title: "Navigating Daniel's Law and its Impact",
    slug: "navigating-daniels-law",
    fullPageJsx: (
        <div>
            <p>November 20, 2020.</p>
            <p>
                That's the day Governor Murphy signed Daniel's Law (P.L.2020,
                c.125), which altered the Open Public Records Act (OPRA) — as
                well as other statutes — to prohibit government records
                custodians from disclosing home addresses of judicial officers,
                prosecutors, and law enforcement officers. While still
                supporting protection for these public servants, shortly
                thereafter, the title community began asking some questions
                about the impact to searching real property records.
            </p>
            <p>
                A few years later, many of those questions have been answered
                but some are works in progress.
            </p>
            <p>
                <strong>Moving Forward:</strong>
                <br />
                Much has occurred, however, since that 2020 Fall day and the
                good news is that before long the wonder and unanswered
                questions will be a thing of the past.
            </p>

            <p>
                Recognizing the need to establish a bit of a framework to manage
                the requirements of Daniel's Law and to successfully achieve the
                protection the legislation promises, Governor Murphy signed
                P.L.2021, c.24 into law, amending the law and extending its
                effective date to December 10, 2021. This move ultimately led to
                the December 2, 2021 introduction of P.L.2021 c.371 which
                established the Office of Information Privacy (OIP) within the
                Department of Community Affairs. That same piece of legislation
                also instituted January 12, 2023 as the official date of
                compliance, giving OIP the time needed to meet its mission: to
                develop the guidelines, policies, procedures and general rules
                required to facilitate the implementation of Daniel's Law.
            </p>
            <p>
                That same amendment also amplified the need to address the
                concerns of Title Producers and Title Searchers who require
                access to unredacted records in order to successfully manage the
                transfer of title. To that end, the legislation carved out Title
                Producers and defined a specific class of organizations — Title
                Search Business Entities (TSBE) — entitled to receive complete
                records. Additionally, Section 4a of the law indicates that a
                Title Search Business Entity, “means any person or entity
                organized under the laws of this state or another state for the
                primary purpose of determining the existence of any lien,
                lawsuit, lease, easement, mortgage or other encumbrance or
                restriction, or ownership interest, on any property and
                regularly conducts business with any title insurance company or
                title insurance agent …”
            </p>
            <p>
                Section 4b goes on to detail that any “title search business
                entity conducting business in this State shall register with and
                be subject to regulation by the Department of Banking and
                Insurance (DOBI). The business entities must also register with
                the Division of Revenue and Enterprise Services (DORES) in the
                Department of the Treasury, or county clerk, as appropriate.”
            </p>
            <p>
                Under the new law, TSBE's also fall under the regulatory purview
                of the NJ Department of Banking and Insurance (DOBI). While
                additional regulations may result from this change, initially
                DOBI will serve as where TSBE's can be identified to allow for
                access to unredacted records to facilitate title searching.
            </p>
            <p>
                So, signing the legislation into law was just the first step.
                What has occurred thereafter served to provide OIP and many
                government agencies an opportunity to map out the methods by
                which Covered Persons can apply for protection while also
                establishing a method by which Record Custodians can identify
                redactors responsible for masking the appropriate information.
                With all of that in line, the focus would then become
                identifying/registering the title search business entities
                approved for complete access.
            </p>
            <p>
                <strong>New Director Adds Clarity</strong>
                <br />
                Led by Director Christine Campbell, the OIP mobilized quickly
                and prioritized outlining a plan for building a secure online
                application portal (www.danielslaw.nj.gov) so that Covered and
                Authorized Persons — active and retired judicial officers,
                prosecutors, and law enforcement officers and their immediate
                families seeking protection under Daniel's Law could formally
                request their records be redacted. That portal launched on July
                12, 2022, well in advance of the date of compliance. Director
                Campbell and her office also engaged multiple State, County, and
                Municipal government agencies in order to help each prepare for
                the demands of the law.
            </p>
            <p>
                The law maintains that government agencies must redact
                residential data from public-facing websites and paper records
                “in their control” for Covered Persons who have been approved
                for redaction by OIP. And who handles said redactions? Once an
                agency has identified a redactor, that individual must register
                with the OIP, also through a secure online portal
                (www.danielslawredact.nj.gov) designed and managed by the
                office.
            </p>

            <p>
                <strong>The Takeaway?</strong>
                <br />
                Only records attached to Covered Persons approved by OIP —
                rather than all persons, or all records — shall be redacted and
                those redactions must only be handled by a registered redactor.
                This process is facilitated by a Redaction Notice delivered by
                the OIP direct to the Agency's redactor, and that redactor has
                30 days to comply. This is a process that OIP has instituted,
                and the respective agencies are utilizing today.
            </p>

            <p>
                That the Director focused on a message centered on redacting
                only records attached to Covered Persons was a bit of a relief;
                when the law was first introduced, Charles Jones had some
                concern that entire record sets would be compromised/removed
                rather than individual records. However, meeting the demands of
                the law with surgical redactions provides the protection the law
                outlines without compromising all title research in the process.
                The realization that most records will remain untouched
                significantly altered the possible impact as the only “lawful”
                redactions are those approved by OIP.
            </p>
            <p>
                Nowhere in the law is there a suggestion to remove all records.
                Instead, what exists is a carefully constructed registration and
                vetting process for Covered Persons, a specific channel for
                communicating and executing upon the required redactions and a
                method for those approved for access to view complete records.
            </p>
            <p>
                In short, that's how it all works. Do questions remain?
                Certainly. But those answers will come as the final components
                are put into place and the processes of Daniel's Law become more
                common, including DOBI's registration portal, which is currently
                under construction. That site will allow TSBE's the chance to
                qualify for access to unredacted records while also providing
                Records Custodians a method for verifying that a TSBE has been
                approved for access.
            </p>
            <p style={{ marginBottom: 0, paddingBottom: 0 }}>
                <strong>What's Next?</strong>
                <br />
                Beyond that, there are some other items that only practical
                experience will help crystallize. Such as:
            </p>

            <ul s>
                <li>Will TSBE's be validated by DOBI?</li>
                <ul>
                    <li>What will be required to be identified as a TSBE?</li>
                </ul>
                <li>
                    How will records custodians handle the various requirements
                    in order to provide records to the TSBEs?
                </li>
                <ul>
                    <li>Will two sets of records be maintained?</li>
                </ul>
                <li>How will redacted records be managed?</li>
                <ul>
                    <li>Strict removals?</li>
                    <li>False Addresses?</li>
                    <li>Aliases?</li>
                </ul>
                <li>
                    How will a title searcher be made aware of a potential
                    redacted record that may affect the property in question?
                </li>
                <li>
                    How will unredacted records be made available to qualified
                    TSBE's?
                </li>
                <li>
                    What is the process for 'unredacting' a record when
                    appropriate?
                </li>
            </ul>

            <p>
                There is plenty more to learn, but the path that has been
                created will allow us all to reach those answers, through
                practical experience and further regulatory efforts to yield
                consistency. So far, the NJLTA Legislative Committee has
                remained in contact with the Director of OIP and DOBI to learn
                as much as possible. And, we have yet to encounter a
                circumstance where we haven't been able to acquire the record,
                and information we need. That's thanks to the thoughtful
                approach taken thus far and the framework that has been
                established. Complexity connected to the law is part of the
                landscape now, but we're confident we'll continue to work
                through the nuances successfully.
            </p>
            <p>Stay tuned!</p>
        </div>
    ),
    cardTitle: "Navigating Daniel's Law and its Impact",
    cardSummary:
        "Governor Murphy signed Daniel's Law (P.L.2020, c.125), which altered the Open Public Records Act (OPRA) — as well as other statutes — to prohibit government records custodians from disclosing home addresses of judicial officers, prosecutors, and law enforcement officers.",
};

const allArticles = [
    tidelandsArticle,
    tidelandImportanceArticle,
    dtsTitlePlantsArticle,
    danielsLawArticle,
];

export {
    tidelandsArticle,
    tidelandImportanceArticle,
    dtsTitlePlantsArticle,
    danielsLawArticle,
    allArticles,
};
