import React, { useState, useRef } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import "./contact.css";
import { CGI_PATH, CJ_ADDRESS, CJ_PHONE } from "../../constants";
import Toasts from "../forms/Toasts";
import {
    allowASCIIValues,
    checkAndAddFieldErrorMessage,
    isValidEmail,
    normalizeInput,
    submitForm,
} from "../../components/forms/FormHelpers";
import ReCAPTCHA from "react-google-recaptcha";
import { ReCaptchaKey } from "../../constants";

export const Contact = () => {
    const defaultForm = {
        full_name: "",
        company_name: "",
        phone: "",
        email: "",
        message: "",
        captcha: "",
    };

    const captchaRef = useRef(null);
    const [form, setForm] = useState(defaultForm);
    const [errors, setErrors] = useState({});
    const [showSuccessToast, setShowSuccessToast] = useState(false);
    const [showErrorToast, setShowErrorToast] = useState(false);
    const [showCaptchaError, setShowCaptchaError] = useState(false);

    function resetCaptcha() {
        setShowCaptchaError(false);
    }

    const setField = (filed, value) => {
        setForm({
            ...form,
            [filed]: value,
        });

        if (!!errors[filed]) {
            setErrors({
                ...errors,
                [filed]: null,
            });
        }
    };

    const buildFormData = () => {
        return {
            type: "small",
            full_name: form.full_name,
            company_name: form.company_name,
            phone: form.phone,
            email: form.email,
            message: form.message,
            token: captchaRef.current.getValue(),
        };
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateForm();

        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
        } else {
            if (
                await submitForm(`${CGI_PATH}contact-us.cgi`, buildFormData())
            ) {
                setForm(defaultForm);
                setShowSuccessToast(true);
                setErrors({});

                captchaRef.current.reset();
            } else {
                setShowErrorToast(true);
            }
        }
    };

    const validateForm = () => {
        const newErrors = {};

        checkAndAddFieldErrorMessage(form, newErrors, "full_name", "name");
        checkAndAddFieldErrorMessage(
            form,
            newErrors,
            "company_name",
            "company"
        );
        checkAndAddFieldErrorMessage(form, newErrors, "email", "email");
        checkAndAddFieldErrorMessage(form, newErrors, "message", "message");

        if (form.email && !isValidEmail(form.email)) {
            newErrors.email = "Please enter valid email.";
        }

        if (!form.phone || form.phone?.length !== 14) {
            newErrors.phone = "Please enter a valid phone number.";
        }

        const token = captchaRef.current.getValue();

        if (token === undefined || token === "") {
            newErrors.captcha = "Invalid captcha.";
            setShowCaptchaError(true);
        }
        return newErrors;
    };

    return (
        <>
            <Container>
                <div className="row contactlocationrow xl-max-size">
                    <div className="col-12 col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 contact-form">
                        <Form>
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                    <Form.Group
                                        className="mb-3"
                                        controlId="full_name"
                                    >
                                        <Form.Label className="formlabel-title">
                                            Name
                                            <span className="span-required">
                                                *
                                            </span>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="John Carter"
                                            maxLength="100"
                                            pattern="[A-Za-z]"
                                            value={form.full_name}
                                            onChange={(event) =>
                                                setField(
                                                    "full_name",
                                                    allowASCIIValues(
                                                        event.target.value
                                                    )
                                                )
                                            }
                                            className={
                                                !!errors.full_name &&
                                                "red-border"
                                            }
                                        ></Form.Control>
                                        <div className="error-message">
                                            {errors.full_name}
                                        </div>
                                    </Form.Group>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                    <Form.Group
                                        className="mb-3"
                                        controlId="email"
                                    >
                                        <Form.Label className="formlabel-title">
                                            Email
                                            <span className="span-required">
                                                *
                                            </span>
                                        </Form.Label>
                                        <Form.Control
                                            type="email"
                                            placeholder="example@email.com"
                                            maxLength="100"
                                            value={form.email}
                                            onChange={(event) =>
                                                setField(
                                                    "email",
                                                    allowASCIIValues(
                                                        event.target.value
                                                    )
                                                )
                                            }
                                            className={
                                                !!errors.email && "red-border"
                                            }
                                        ></Form.Control>
                                        <div className="error-message">
                                            {errors.email}
                                        </div>
                                    </Form.Group>
                                </div>

                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                    <Form.Group
                                        className="mb-3"
                                        controlId="phone"
                                    >
                                        <Form.Label className="formlabel-title">
                                            Phone
                                            <span className="span-required">
                                                *
                                            </span>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="(123) 456-7890"
                                            maxLength="14"
                                            minLength="14"
                                            value={form.phone}
                                            onChange={(event) =>
                                                setField(
                                                    "phone",
                                                    normalizeInput(
                                                        event.target.value,
                                                        form.phone
                                                    )
                                                )
                                            }
                                            className={
                                                !!errors.phone && "red-border"
                                            }
                                            pattern="(ddd) ddd-ddd"
                                        ></Form.Control>
                                        <div className="error-message">
                                            {errors.phone}
                                        </div>
                                    </Form.Group>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                    <Form.Group
                                        className="mb-3"
                                        controlId="company_name"
                                    >
                                        <Form.Label className="formlabel-title">
                                            Company
                                            <span className="span-required">
                                                *
                                            </span>
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Company Name"
                                            maxLength="100"
                                            value={form.company_name}
                                            onChange={(event) =>
                                                setField(
                                                    "company_name",
                                                    allowASCIIValues(
                                                        event.target.value
                                                    )
                                                )
                                            }
                                            className={
                                                !!errors.company_name &&
                                                "red-border"
                                            }
                                        ></Form.Control>
                                        <div className="error-message">
                                            {errors.company_name}
                                        </div>
                                    </Form.Group>
                                </div>

                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <Form.Group
                                        className="mb-3"
                                        controlId="message"
                                    >
                                        <Form.Label className="formlabel-title">
                                            Message
                                            <span className="span-required">
                                                *
                                            </span>
                                        </Form.Label>
                                        <textarea
                                            placeholder="Please type message here..."
                                            maxLength="500"
                                            type="textarea"
                                            id="message"
                                            className={
                                                errors.message
                                                    ? "form-control cj-textarea red-border"
                                                    : "form-control cj-textarea"
                                            }
                                            rows={7}
                                            onChange={(event) => {
                                                setField(
                                                    "message",
                                                    allowASCIIValues(
                                                        event.target.value
                                                    )
                                                );
                                            }}
                                            value={form.message}
                                        ></textarea>
                                        <div className="error-message">
                                            {errors.message}
                                        </div>
                                    </Form.Group>
                                </div>

                                <Form.Group className="mb-3" controlId="submit">
                                    <div>
                                        <ReCAPTCHA
                                            sitekey={ReCaptchaKey}
                                            ref={captchaRef}
                                            onChange={() => resetCaptcha()}
                                        />
                                    </div>

                                    {showCaptchaError ? (
                                        <div className="error-message">
                                            {errors.captcha}
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    <Button
                                        type="submit"
                                        onClick={handleSubmit}
                                        tabIndex="6"
                                        className="my-2 btn-submit-form"
                                        style={{
                                            background: "#063b67",
                                            color: "white",
                                            fontSize: "13px",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        SUBMIT
                                    </Button>
                                </Form.Group>
                            </div>
                        </Form>
                    </div>

                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 location">
                        <Row>
                            <Col xs={12} md={10} className="margintop">
                                <h5 className="contactdesc-title">
                                    Contact us
                                </h5>
                                <h4 className="contactdesc-heading">
                                    Questions?
                                </h4>
                                <h4 className="contactdesc-heading">
                                    We’ve got answers.
                                </h4>
                                <div className="contact-desc">
                                    Get in touch with our Customer Service Team.
                                </div>
                                <div className="row-icon-phone-left contact-desc">
                                    <span className="icon-phone-left"></span>
                                    {CJ_PHONE}
                                </div>
                                <div className="contact-desc">
                                    <span className="icon-location-left"></span>
                                    {CJ_ADDRESS.street},
                                    <br /> &nbsp; &nbsp; &nbsp;
                                    {CJ_ADDRESS.street2}
                                    <br /> &nbsp; &nbsp; &nbsp;
                                    {CJ_ADDRESS.poBox}
                                    <br /> &nbsp; &nbsp; &nbsp;
                                    {CJ_ADDRESS.city}, {CJ_ADDRESS.state}&nbsp;
                                    {CJ_ADDRESS.zip}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Container>

            <Toasts
                showErrorToast={showErrorToast}
                setShowErrorToast={setShowErrorToast}
                showSuccessToast={showSuccessToast}
                setShowSuccessToast={setShowSuccessToast}
            />
        </>
    );
};
export default Contact;
