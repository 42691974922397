import Innerpageheading from "../../../components/common/innerpageheading";
import Breadcrumb from "../../../components/common/breadcrumb";
import ServiceDetails from "../../../components/solutions/ServiceDetails";

import { dueDiligencePageHeader } from "../../../components/common/pageheader";
import { dueDiligenceBreadcrumb } from "../../../components/common/breadcrumbs";
import Contact from "../../../components/common/contact";

const DueDiligence = ({
    showHeader = true,
    showBreadcrum = true,
    showTextExpand = false,
}) => {
    var bannerImage = "/img/solutions/due_dilligence.jpg";
    var title = "Due Diligence Services";
    var availableFor = ["Nationwide"];
    var certified = "No";

    var getStartedUrl =
        showHeader === false
            ? "/solutions/nationwide-services/due-diligence"
            : "/get-started";

    const TextContent = () => {
        return (
            <>
                <p className="solutions-details-page-p">
                    We offer products covering a vast range of courts and record
                    types both locally, and nationwide. Our team of
                    professionals has more than 100 years of experience working
                    with legal, lending, title and corporate professionals
                    seeking information provided within our many search and
                    retrieval products.
                </p>
                <p className="solutions-details-page-p">
                    Need a copy of a document? Our Business Entity Document
                    Retrieval Services return the essential filing copies you
                    may need such as Certificates of Incorporation or any
                    documents required for companies conducting business. We
                    also provide Uniform Commercial Code (UCC) Search and
                    Retrieval Services as well as Court Record Search and
                    Retrieval offerings covering record types and jurisdictions
                    across the country. Our time-tested search practices return
                    meaningful results, designed to provide the information and
                    documents vital to any transaction.
                </p>
                <p className="solutions-details-page-p">
                    Take advantage of our online order entry and retrieval -
                    with multiple delivery options.
                </p>
                <p className="solutions-details-page-p">
                    For a snapshot of our offerings - with detailed information
                    on the products and how to order -{" "}
                    <a
                        href="https://sis-web-assets.s3.amazonaws.com/SignOn/SignOnNcsGuide.pdf"
                        target="_blank"
                        rel="noreferrer"
                    >
                        click HERE
                    </a>
                    .
                </p>
            </>
        );
    };

    return (
        <>
            {showHeader === true ? (
                <Innerpageheading pageHeadInfo={dueDiligencePageHeader()} />
            ) : null}

            {showBreadcrum === true ? (
                <Breadcrumb breadcrumbInfo={dueDiligenceBreadcrumb()} />
            ) : null}

            <div
                className={
                    showHeader === false
                        ? "pt-4 px-5 px-sm-4 px-md-3 px-lg-4 px-xxl-0"
                        : "container pt-6 pb-5 px-5 px-sm-4 px-md-3 px-lg-4 px-xxl-0"
                }
            >
                <ServiceDetails
                    title={title}
                    bannerUrl={bannerImage}
                    availableFor={availableFor}
                    certified={certified}
                    showTextExpand={showTextExpand}
                    textContent={<TextContent></TextContent>}
                    getStartedUrl={getStartedUrl}
                ></ServiceDetails>
            </div>

            {showHeader === true ? (
                <div className="row contact-loaction">
                    <Contact />
                </div>
            ) : null}
        </>
    );
};

export default DueDiligence;
