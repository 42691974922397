import Innerpageheading from "../../../components/common/innerpageheading";
import Breadcrumb from "../../../components/common/breadcrumb";
import ServiceDetails from "../../../components/solutions/ServiceDetails";

import { countySearchPageHeader } from "../../../components/common/pageheader";
import { countySearchBreadcrumb } from "../../../components/common/breadcrumbs";
import Contact from "../../../components/common/contact";

const CountySearch = ({
    showHeader = true,
    showBreadcrum = true,
    showTextExpand = false,
}) => {
    var bannerImage = "/img/solutions/county_search.jpg";
    var title = "County Search Services";
    var availableFor = ["New Jersey"];
    var certified = "No";

    var getStartedUrl =
        showHeader === false
            ? "/solutions/county-search/county-search"
            : "/get-started";

    const TextContent = () => {
        return (
            <>
                <p className="solutions-details-page-p">
                    We offer County Search services for all 21 counties in New
                    Jersey. Plus, with the development of DTS Title Plants in
                    numerous counties throughout New Jersey, our County Search
                    Services gives you extra power. We use experienced
                    searchers, plus the power of the Title Plant to expedite
                    completion of your County Searches. Because many of our
                    searches can be completed using the plants, we are not
                    limited by the hours of county record rooms. Seasoned,
                    professional searchers execute your orders, and you receive
                    clear, detailed, type-written title reports quickly.
                </p>
                <p className="solutions-details-page-p">
                    Our searches include a search of the index of county land
                    records from the date that the current title holder(s)
                    acquired the property through the present board date.
                    Primary Types: Present Owner, Full Searches, Foreclosure and
                    Commercial. Projects of any size can be completed in a
                    timely, predictable and professional manner with just one
                    place to call with your questions.
                </p>
                <p className="solutions-details-page-p">
                    For an updated list of our title plant county availability,
                    &nbsp;
                    <a href="/dts-title-plant" rel="noreferrer">
                        click here
                    </a>
                    .
                </p>
            </>
        );
    };

    return (
        <>
            {showHeader === true ? (
                <Innerpageheading pageHeadInfo={countySearchPageHeader()} />
            ) : null}

            {showBreadcrum === true ? (
                <Breadcrumb breadcrumbInfo={countySearchBreadcrumb()} />
            ) : null}

            <div
                className={
                    showHeader === false
                        ? "pt-4 px-5 px-sm-4 px-md-3 px-lg-4 px-xxl-0"
                        : "container pt-6 pb-5 px-5 px-sm-4 px-md-3 px-lg-4 px-xxl-0"
                }
            >
                <ServiceDetails
                    title={title}
                    bannerUrl={bannerImage}
                    availableFor={availableFor}
                    certified={certified}
                    showTextExpand={showTextExpand}
                    textContent={<TextContent></TextContent>}
                    getStartedUrl={getStartedUrl}
                ></ServiceDetails>
            </div>

            {showHeader === true ? (
                <div className="row contact-loaction">
                    <Contact />
                </div>
            ) : null}
        </>
    );
};

export default CountySearch;
