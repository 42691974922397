import Innerpageheading from "../../../components/common/innerpageheading";
import Breadcrumb from "../../../components/common/breadcrumb";
import ServiceDetails from "../../../components/solutions/ServiceDetails";

import { tidelandClaimsSearchPageHeader } from "../../../components/common/pageheader";
import { tidelandClaimsSearchBreadcrumb } from "../../../components/common/breadcrumbs";
import Contact from "../../../components/common/contact";

const TidelandClaimsSearch = ({
    showHeader = true,
    showBreadcrum = true,
    showTextExpand = false,
}) => {
    var bannerImage = "/img/solutions/tideland_claims.jpg";
    var title = "Tideland Claims Search";
    var availableFor = ["New Jersey"];
    var certified = "Yes";

    var getStartedUrl =
        showHeader === false
            ? "/solutions/flood-and-tideland/tideland-claims-search"
            : "/get-started";

    const TextContent = () => {
        return (
            <>
                <p className="solutions-details-page-p">
                    A certified search of riparian rights and claims established
                    by the state of New Jersey to portions of property lying in
                    areas presently or formerly flowed by tidal waters. Searches
                    are made using maps and overlays adopted by the Tidelands
                    Resource Council, tax maps, U.S. Geological Survey maps,
                    computerized index files, and other source materials.
                    Claimed areas are identified by the type of claim and the
                    approximate area claimed.
                </p>
                <p className="solutions-details-page-p">
                    The product was developed in response to the O'Neill
                    decision by the New Jersey Supreme Court, 50 NJ 307
                    (1967), and ensuing legislation, NJSA 13:1B et seq., the
                    State of New Jersey has promulgated over 1600 maps which
                    delineate areas to which the State claims ownership because
                    the lands are presently or were formerly flowed by the tides
                    or tidal streams. The validity of these far-reaching and
                    frequently unsuspected claims of ownership was supported by
                    the U.S. Supreme Court in Phillips Petroleum vs.
                    Mississippi, U.S. Supreme Court, Feb. 23, 1988, Case No.
                    86-870. Many attorneys, title companies and title searchers
                    do not have the time or resources to make an accurate study
                    of these claims maps in relation to their property. But the
                    cost of an improper determination from the maps can be very
                    substantial. With a long history of services to the land
                    title industry, Charles Jones recognizes the need for fast,
                    reliable tideland searches and has developed the resources
                    necessary to provide a comprehensive service.
                </p>
            </>
        );
    };

    return (
        <>
            {showHeader === true ? (
                <Innerpageheading
                    pageHeadInfo={tidelandClaimsSearchPageHeader()}
                />
            ) : null}

            {showBreadcrum === true ? (
                <Breadcrumb breadcrumbInfo={tidelandClaimsSearchBreadcrumb()} />
            ) : null}

            <div
                className={
                    showHeader === false
                        ? "pt-4 px-5 px-sm-4 px-md-3 px-lg-4 px-xxl-0"
                        : "container pt-6 pb-5 px-5 px-sm-4 px-md-3 px-lg-4 px-xxl-0"
                }
            >
                <ServiceDetails
                    title={title}
                    bannerUrl={bannerImage}
                    availableFor={availableFor}
                    certified={certified}
                    showTextExpand={showTextExpand}
                    textContent={<TextContent></TextContent>}
                    getStartedUrl={getStartedUrl}
                ></ServiceDetails>
            </div>

            {showHeader === true ? (
                <div className="row contact-loaction">
                    <Contact />
                </div>
            ) : null}
        </>
    );
};

export default TidelandClaimsSearch;
