import React from "react";
import Innerpageheading from "../common/innerpageheading";
import Breadcrumb from "../common/breadcrumb";
import Contact from "../common/contact";
import { testimonials } from "../common/breadcrumbs";
import { testimonialsPageHeader } from "../common/pageheader";
import "./testimonials.css";
import TestimonialsCard from "./testimonialscard";
import {
    claussInfo,
    uzzolinoInfo,
    brownsInfo,
    petercaseywallInfo,
    fortuneInfo,
} from "../common/contenthelper";

const Testimonials = () => {
    return (
        <>
            <Innerpageheading pageHeadInfo={testimonialsPageHeader()} />
            <Breadcrumb breadcrumbInfo={testimonials()} />

            <div className="row testimonials-content text-center">
                <div
                    className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                    id="uzzolino-data-blog"
                >
                    <TestimonialsCard testimaonialsInfo={uzzolinoInfo()} />
                </div>

                <div
                    className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                    id="wall-data-blog"
                >
                    <TestimonialsCard testimaonialsInfo={fortuneInfo()} />
                </div>

                <div
                    className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                    id="wall-data-blog"
                >
                    <TestimonialsCard
                        testimaonialsInfo={petercaseywallInfo()}
                    />
                </div>

                <div
                    className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                    id="browns-data-blog"
                >
                    <TestimonialsCard testimaonialsInfo={brownsInfo()} />
                </div>
                <div
                    className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                    id="clauss-data-blog"
                >
                    <TestimonialsCard testimaonialsInfo={claussInfo()} />
                </div>
            </div>
            <div className="row contact-loaction">
                <Contact />
            </div>
        </>
    );
};

export default Testimonials;
