import React from "react";
import IconButton from "../buttons/iconButton";
import "./searchcard.css";

const SearchCard = ({ cardInfo }) => {
    return (
        <>
            <div
                className="searchcard shadow-sm shadow shadow-lg bg-white mx-auto"
                style={{ borderRadius: "2rem", marginBottom: "25px" }}
            >
                <div>
                    <img
                        src={cardInfo.badge}
                        className="card-img-top mx-auto d-block text-center"
                        alt="..."
                        style={{
                            width: "60px",
                            height: "60px",
                            marginTop: "35px",
                        }}
                    />
                    <div className="card-body text-center d-block">
                        <h4 className="searc-card-title">{cardInfo.title}</h4>
                        <p className="searcd-card-text">{cardInfo.desc}</p>
                    </div>
                </div>

                <a className="text-center" href={`${cardInfo?.link}`}>
                    <IconButton
                        text={
                            cardInfo?.buttonText
                                ? cardInfo?.buttonText
                                : "LEARN MORE"
                        }
                        styleClass="btn-cj-secondary home-hero-button"
                        iconClass="btn-arrow-right"
                    />
                </a>
            </div>
        </>
    );
};

export default SearchCard;
