import { CJ_ADDRESS, CJ_LLC_NAME, CJ_PHONE } from "../../constants";
import "./index.css";

export default function Footer() {
    return (
        <div className="container footer">
            <div className="row margin-top-5">
                <div className="col-lg-12 col-xl-12 col-md-12 col-xs-12"></div>
            </div>

            <div className="row">
                <div className="col-xxl-4 col-lg-6  col-md-12 col-xs-12 text-align-center-m">
                    <div className="row">
                        <div className="col-lg-12 col-xl-12">
                            <img
                                className="logo-style-guide-top"
                                alt="Logo style guide top"
                                src="/img/cj-logo.svg"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6 col-xl-6 col-md-12 col-xs-12 text-align-center-m">
                            <p className="footer-address">
                                {CJ_ADDRESS.street},
                                <br />
                                {CJ_ADDRESS.street2}
                                <br />
                                {CJ_ADDRESS.poBox}
                                <br />
                                {CJ_ADDRESS.city}, {CJ_ADDRESS.state}&nbsp;
                                {CJ_ADDRESS.zip}
                                <br />
                                {CJ_PHONE}
                            </p>
                        </div>
                        <div className="col-lg-6 col-xl-6 col-md-12 col-xs-12"></div>
                    </div>

                    <div className="row social-media-icon">
                        <div className="col-12">
                            <a
                                href="https://www.facebook.com/signatureinfo/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    className="facebook"
                                    alt="Facebook"
                                    src="/img/facebook.svg"
                                />
                            </a>
                            <a
                                href="https://twitter.com/CharlesJonesLLC"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    className="twitter"
                                    alt="Twitter"
                                    src="/img/twitter.svg"
                                />
                            </a>
                            <a
                                href="https://www.linkedin.com/company/charlesjonesllc"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    className="linked-in"
                                    alt="Linked in"
                                    src="/img/linkedin.svg"
                                />
                            </a>
                        </div>
                    </div>
                </div>

                <div className="col-xxl-8 col-lg-6 ">
                    <div className="justify-content-end">
                        <div className="row ms-xxl-5 ms-5 ms-lg-0 justify-content-center mx-auto footer-link-cntr">
                            <div className="col-6 col-md-3 col-lg-6 d-flex justify-content-end">
                                <div className="row">
                                    <div className="col-12 col-xxl-7">
                                        <div className="footer-service-title">
                                            Solutions
                                        </div>

                                        <div className="footer-service">
                                            <a
                                                href="/solutions/name-searches"
                                                rel="noreferrer"
                                            >
                                                Name Searches
                                            </a>
                                        </div>

                                        <div className="footer-service">
                                            <a
                                                href="/solutions/tax-searches"
                                                rel="noreferrer"
                                            >
                                                Tax Searches
                                            </a>
                                        </div>

                                        <div className="footer-service">
                                            <a
                                                href="/solutions/flood-and-tideland"
                                                rel="noreferrer"
                                            >
                                                Flood &amp; Tideland
                                            </a>
                                        </div>

                                        <div className="footer-service">
                                            <a
                                                href="/solutions/county-search"
                                                rel="noreferrer"
                                            >
                                                County Searches
                                            </a>
                                        </div>

                                        <div className="footer-service">
                                            <a
                                                href="/solutions/corporate-and-ucc/"
                                                rel="noreferrer"
                                            >
                                                Corporate &amp; UCC
                                            </a>
                                        </div>

                                        <div className="footer-service">
                                            <a
                                                href="/solutions/nationwide-services/"
                                                rel="noreferrer"
                                            >
                                                Nationwide Services
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xxl-5">
                                        <div className="col-12">
                                            <div className="footer-service-title mt-3 mt-xxl-0">
                                                Resources
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="footer-service">
                                                <a
                                                    href="/get-started"
                                                    rel="noreferrer"
                                                >
                                                    Get Started
                                                </a>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="footer-service">
                                                <a
                                                    href="/forms-and-links"
                                                    rel="noreferrer"
                                                >
                                                    Forms &amp; Links
                                                </a>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="footer-service">
                                                <a
                                                    href="/news-insight"
                                                    rel="noreferrer"
                                                >
                                                    News
                                                </a>
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="footer-service">
                                                <a
                                                    href="/testimonials"
                                                    rel="noreferrer"
                                                >
                                                    Testimonials
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-6 col-sm-6 col-md-3 col-lg-6">
                                <div className="row">
                                    <div className="col-12  col-xxl-6">
                                        <div className="d-block">
                                            <div className="footer-service-title">
                                                Company
                                            </div>
                                        </div>

                                        <div className="footer-service">
                                            <a href="/about" rel="noreferrer">
                                                About
                                            </a>
                                        </div>

                                        <div className="footer-service">
                                            <a
                                                href="/executive-team"
                                                rel="noreferrer"
                                            >
                                                Executive Profiles
                                            </a>
                                        </div>
                                    </div>

                                    <div className="col-12  col-xxl-6">
                                        <div className="d-block">
                                            <div className="footer-service-title">
                                                Contact
                                            </div>
                                        </div>

                                        <div className="footer-service">
                                            <form
                                                id="signon-form"
                                                action="/SignOn/login.roc"
                                                method="POST"
                                            >
                                                <button
                                                    type="submit"
                                                    style={{
                                                        background: "none",
                                                        border: "none",
                                                        padding: "0",
                                                        color: "#243773",
                                                        fontWeight: "500",
                                                    }}
                                                >
                                                    Sign On
                                                </button>
                                            </form>
                                        </div>

                                        <div className="footer-service">
                                            <a
                                                href="/contact-us"
                                                rel="noreferrer"
                                            >
                                                Contact Us
                                            </a>
                                        </div>

                                        <div className="footer-service">
                                            <a
                                                href="/request-info"
                                                rel="noreferrer"
                                            >
                                                Request Information
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row margin-top-5">
                <div className="col-12"></div>
            </div>

            <div className="row">
                <div className="col-12">
                    <hr stroke="#D4D2E3" className="bottom-content"></hr>
                </div>
            </div>

            <div className="row margin-top-2">
                <div className="col-12">
                    <p className="text-align-center footer-links bottom-content">
                        © 2018-{new Date().getFullYear()} {CJ_LLC_NAME} and/or
                        its affiliates. All rights reserved.&nbsp;&nbsp;
                        <a href="/terms-and-conditions" rel="noreferrer">
                            Terms and Conditions
                        </a>
                        &nbsp;|&nbsp;
                        <a
                            href="https://www.firstam.com/privacy-policy/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Privacy Policy
                        </a>
                        &nbsp;|&nbsp;
                        <a href="/disability-statement" rel="noreferrer">
                            Disability Statement
                        </a>
                    </p>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <p className="text-align-center bottom-content">
                        {CJ_LLC_NAME} is not a consumer reporting agency as such
                        term is defined in the federal Fair Credit Reporting
                        Act, 15 USC 1681 et seq. (&#34;FCRA&#34;). Charles Jones
                        reports do not constitute consumer reports as such term
                        is defined in the FCRA, and accordingly these reports
                        may not be used to determine eligibility for credit,
                        employment, tenant screening or for any other purpose
                        provided for in the FCRA.
                    </p>
                    <p className="text-align-center bottom-content">
                        {CJ_LLC_NAME} - A DataTrace Company
                    </p>
                </div>
            </div>
        </div>
    );
}
