const formsandlinksdata =[
    {
        Title: "Certified Child Support Judgment Search Forms",
        Data: [
            {
                subTitle:"Child Support Status Request Form - Child Support Judgment Search Order Form (last updated 7/1/2024)",
                url: "/PDFs/349.pdf",
            },
        ]
    },
    {
        Title: "Corporate Service Forms",
        Data: [
            {
                subTitle:"Document Retrieval Order Form",
                url: "/PDFs/327.pdf",
            },
            {
                subTitle:"Corporate Filing Request Order Form",
                url: "/PDFs/329.pdf",
            },
            {
                subTitle:"Court Record Request Order Form",
                url: "/PDFs/330.pdf",
            },
            {
                subTitle:"Other Corporate Services Order Form",
                url: "/PDFs/334.pdf",
            },
            {
                subTitle:"UCC Search and/or Retrieval Order Form",
                url: "/PDFs/341.pdf",
            },

        ]
    },
    {
        Title: "Associations",
        Data: [
            {
                subTitle:"American Land Title Association",
                url: "http://www.alta.org/",
            },
            {
                subTitle:"National Public Records Research Association",
                url: "http://www.nprra.org/",
            },
            {
                subTitle:"New Jersey Land Title Association",
                url: "http://www.njlta.org/",
            },
            {
                subTitle:"New Jersey Land Title Association - Agency Section",
                url: "https://njlta.org/page/AgencyMgmtBd",
            },
            {
                subTitle:"New Jersey Land Title Institute",
                url: "http://www.njlti.org/",
            },
            {
                subTitle:"New York State Land Title Association",
                url: "http://www.nyslta.org/",
            },
            {
                subTitle:"Pennsylvania Land Title Association",
                url: "http://www.plta.org/",
            },
        ]
    },
    {
        Title: "Credit",
        Data: [
            {
                subTitle:"Dun & Bradstreet",
                url: "http://www.dnb.com/",
            },
            {
                subTitle:"Equifax",
                url: "http://www.equifax.com/",
            },
            {
                subTitle:"Experian",
                url: "http://www.experian.com/",
            },
            {
                subTitle:"Trans Union",
                url: "http://www.tuc.com/",
            },
        ]
    },
    {
        Title: "Education",
        Data: [
            {
                subTitle:"American Land Title Institute",
                url: "http://www.alta.org/lti/index.cfm",
            },
            {
                subTitle:"LearnTitle, LLC",
                url: "http://learntitle.com/",
            },
            {
                subTitle:"New Jersey Institute for Continuing Legal Education",
                url: "http://www.njicle.com/",
            },
            {
                subTitle:"New Jersey Land Title Institute",
                url: "http://www.njlti.org/",
            },
            {
                subTitle:"The Grabas Institute",
                url: "http://www.continuingeducationnj.com/",
            },
            {
                subTitle:"Title Academy of New Jersey",
                url: "http://www.titleacademyofnewjersey.com/",
            },
        ]
    },
    {
        Title: "Government",
        Data: [
            {
                subTitle:"Federal Emergency Management Agency",
                url: "http://www.fema.gov/",
            },
            {
                subTitle:"National Center for State Courts",
                url: "http://www.ncsc.org/",
            },
            {
                subTitle:"NJ Assessors",
                url: "https://www.state.nj.us/treasury/taxation/lpt/localtax.shtml",
            },
            {
                subTitle:"NJ Department of Banking & Insurance",
                url: "http://www.state.nj.us/dobi/index.html",
            },
            {
                subTitle:"NJ Department of Environmental Protection",
                url: "http://www.state.nj.us/dep/njgs/index.html",
            },
            {
                subTitle:"NJ Legislature Home Page",
                url: "https://www.njleg.state.nj.us/",
            },
            {
                subTitle:"NJ State Courts",
                url: "http://www.judiciary.state.nj.us/",
            },
            {
                subTitle:"Securities Exchange Commission",
                url: "http://www.sec.gov/",
            },
            {
                subTitle:"State of New Jersey",
                url: "http://www.state.nj.us/",
            },
            {
                subTitle:"The Federal Judiciary",
                url: "http://www.uscourts.gov/",
            },
            {
                subTitle:"US Census Bureau",
                url: "http://www.census.gov/",
            },
            {
                subTitle:"US Supreme Court: Opinions Dockets and Court Rules",
                url: "http://www.supremecourtus.gov/",
            },
        ]
    },
    {
        Title: "Legal",
        Data: [
            {
                subTitle:"American Bar Association",
                url: "http://www.abanet.org/",
            },
            {
                subTitle:"Findlaw",
                url: "http://www.findlaw.com/",
            },
            {
                subTitle:"Law News Network",
                url: "http://www.lawnewsnetwork.com/",
            },
            {
                subTitle:"Martindale-Hubbell",
                url: "http://www.martindale.com/",
            },
            {
                subTitle:"New Jersey State Bar Association",
                url: "http://www.njsba.com/",
            },
            {
                subTitle:"The Philadelphia Association of Paralegals",
                url: "http://www.philaparalegals.com/",
            },
            {
                subTitle:"Westlaw, West Publishing",
                url: "https://legal.thomsonreuters.com/en/products/westlaw",
            },
        ]
    },
    {
        Title: "Mortgage / Lending",
        Data: [
            {
                subTitle:"BankRate.com",
                url: "http://www.bankrate.com/",
            },
            {
                subTitle:"Fannie Mae",
                url: "http://www.fanniemae.com/",
            },
            {
                subTitle:"Freddie Mac",
                url: "http://www.freddiemac.com/",
            },
            {
                subTitle:"HSH Associates (Daily Mortgage Rates)",
                url: "http://www.hsh.com/",
            },
            {
                subTitle:"MERS",
                url: "http://www.mersinc.org/",
            },
            {
                subTitle:"Mortgage Bankers Association of America",
                url: "http://www.mbaa.org/",
            },
            {
                subTitle:"Mortgage Interest Rates",
                url: "http://www.interest.com/mortgage/?zip=(null",
            },
            {
                subTitle:"Quicken Mortgage Information",
                url: "http://mortgage.quicken.com/",
            },
        ]
    },
    {
        Title: "Public Records",
        Data: [
            {
                subTitle:"BRB Publishing (public record research library)",
                url: "http://www.brbpub.com/",
            },
            {
                subTitle:"LexisNexis",
                url: "http://www.lexisnexis.com/en-us/gateway.page",
            },
        ]
    },
    {
        Title: "Real Estate",
        Data: [
            {
                subTitle:"Home & Land / Homes.com",
                url: "http://www.homes.com/",
            },
            {
                subTitle:"Inman News features",
                url: "http://www.inman.com/",
            },
            {
                subTitle:"National Association of Realtors",
                url: "http://www.realtor.com/",
            },
        ]
    },
    {
        Title: "Title Insurance",
        Data: [
            {
                subTitle:"American Land Title Association",
                url: "http://www.alta.org/",
            },
            {
                subTitle:"NJ Land Title Association",
                url: "http://www.njlta.org/",
            },
            {
                subTitle:"NJ Land Title Association - Agency Section",
                url: "https://njlta.site-ym.com/page/AgencyMgmtBd",
            },
            {
                subTitle:"Pennsylvania Land Title Association",
                url: "http://www.plta.org/",
            },
        ]
    },
    {
        Title: "Title Search Services",
        Data: [
            {
                subTitle:"Greater Atlantic Legal Services, Inc",
                url: "http://www.greateratlanticlegal.com/",
            },
        ]
    },
]

export default formsandlinksdata;