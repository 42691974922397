import React from "react";
import "./executiveprofile.css";

const ExecutiveProfie = ({ profileInfo, style }) => {
    return (
        <div style={style} className="executive-profiles">
            <div className="row executive-profile-info">
                <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 profile-badge">
                    <img
                        className="profile-dp"
                        src={profileInfo.profieImg}
                        alt=".."
                    />
                </div>
                <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 profile-data">
                    <h4 className="profile-name">{profileInfo.name}</h4>
                    <p className="profile-occupation">
                        {profileInfo.occupation}
                    </p>
                    <div className="profile-smiocns">
                        {profileInfo.twitterLink ? (
                            <a
                                href={profileInfo?.twitterLink}
                                rel="noreferrer"
                                target="_blank"
                            >
                                <img
                                    className="profile-socialmediaicons"
                                    src="/img/twitter.svg"
                                    alt=".."
                                />
                            </a>
                        ) : (
                            <></>
                        )}

                        {profileInfo.linkedInLink ? (
                            <a
                                href={profileInfo?.linkedInLink}
                                rel="noreferrer"
                                target="_blank"
                            >
                                <img
                                    className="profile-socialmediaicons"
                                    src="/img/linkedin.svg"
                                    alt=".."
                                />
                            </a>
                        ) : (
                            <></>
                        )}

                        {profileInfo.instagramLink ? (
                            <a
                                href={profileInfo?.instagramLink}
                                rel="noreferrer"
                                target="_blank"
                            >
                                <img
                                    className="profile-socialmediaicons"
                                    src="/img/instagram.svg"
                                    alt=".."
                                    target="_blank"
                                />
                            </a>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </div>

            <div className="row">
                <p className="profile-desc1">{profileInfo.desc1}</p>
                <p className="profile-desc2">{profileInfo.desc2}</p>
                {profileInfo.desc3 && (
                    <p className="profile-desc3">{profileInfo.desc3}</p>
                )}
                <p className="profile-desc4">{profileInfo.desc4}</p>
                <p className="profile-desc5">{profileInfo.desc5}</p>
            </div>
        </div>
    );
};

export default ExecutiveProfie;
