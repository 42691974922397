import Innerpageheading from "../../../components/common/innerpageheading";
import Breadcrumb from "../../../components/common/breadcrumb";
import ServiceDetails from "../../../components/solutions/ServiceDetails";

import { taxAndAssessmentPageHeader } from "../../../components/common/pageheader";
import { taxAndAssessmentBreadcrumb } from "../../../components/common/breadcrumbs";
import Contact from "../../../components/common/contact";

const TaxAndAssessment = ({
    showHeader = true,
    showBreadcrum = true,
    showTextExpand = false,
}) => {
    var bannerImage = "/img/solutions/tax_assessment_search.jpg";
    var title = " New Jersey Tax and Assessment Searches";
    var availableFor = ["New Jersey"];
    var certified = "Yes";

    var getStartedUrl =
        showHeader === false
            ? "/solutions/tax-searches/nj-tax-and-assessment-searches"
            : "/get-started";

    const TextContent = () => {
        return (
            <>
                <p className="solutions-details-page-p">
                    Beginning in 1985, we offered the first privately produced
                    New Jersey Tax Search, which details tax billing and payment
                    status, utility information and all lienable charges.
                </p>
                <p className="solutions-details-page-p">
                    You can easily identify your property utilizing our
                    expansive statewide database, helping further expedite the
                    processing of your search.
                </p>
                <p className="solutions-details-page-p">
                    Our searches are easy to read and generated in your choice
                    of formats; automated processes and work by experienced
                    professionals allows us to routinely deliver a fast,
                    concise, comprehensive, high-quality certified search.
                </p>
            </>
        );
    };

    return (
        <>
            {showHeader === true ? (
                <Innerpageheading pageHeadInfo={taxAndAssessmentPageHeader()} />
            ) : null}

            {showBreadcrum === true ? (
                <Breadcrumb breadcrumbInfo={taxAndAssessmentBreadcrumb()} />
            ) : null}

            <div
                className={
                    showHeader === false
                        ? "pt-4 px-5 px-sm-4 px-md-3 px-lg-4 px-xxl-0"
                        : "container pt-6 pb-5 px-5 px-sm-4 px-md-3 px-lg-4 px-xxl-0"
                }
            >
                <ServiceDetails
                    title={title}
                    bannerUrl={bannerImage}
                    availableFor={availableFor}
                    certified={certified}
                    showTextExpand={showTextExpand}
                    textContent={<TextContent></TextContent>}
                    getStartedUrl={getStartedUrl}
                ></ServiceDetails>
            </div>

            {showHeader === true ? (
                <div className="row contact-loaction">
                    <Contact />
                </div>
            ) : null}
        </>
    );
};

export default TaxAndAssessment;
