const solutionsData = [
    {
        solutionTitle: "Name Searches",
        solutionTitleImg: "./img/name-searched-accordion-logo.svg",
        services: [
            {
                serviceTitle: "Child Support Judgment Search",
                availableFor: ["New Jersey"],
                certified: "Yes",
                url: "/solutions/name-searches/child-support-judgment",
                showDivider: true,
            },
            {
                serviceTitle: "Judgment Lien Search",
                availableFor: ["New Jersey"],
                certified: "Yes",
                url: "/solutions/name-searches/judgment-lien-search",
                showDivider: true,
            },
            {
                serviceTitle: "Patriot Name Search",
                availableFor: ["Nationwide"],
                certified: "Yes",
                url: "/solutions/name-searches/patriot-name-search",
                showDivider: false,
            },
        ],
    },
    {
        solutionTitle: "Tax Searches",
        solutionTitleImg: "./img/tax-search-accordion-logo.svg",
        services: [
            {
                serviceTitle: "New Jersey Tax and Assessment Searches",
                availableFor: ["New Jersey"],
                certified: "Yes",
                url: "/solutions/tax-searches/nj-tax-and-assessment-searches",
                showDivider: true,
            },
            {
                serviceTitle: "Pennsylvania Tax Certifications",
                availableFor: ["Pennsylvania"],
                certified: "Yes",
                url: "/solutions/tax-searches/pa-tax-certifications",
                showDivider: false,
            },
        ],
    },

    {
        solutionTitle: "Flood and Tideland Services",
        solutionTitleImg: "./img/flood.svg",
        services: [
            {
                serviceTitle: "Flood Search Determination",
                availableFor: ["New Jersey", "Pennsylvania"],
                certified: "No",
                url: "/solutions/flood-and-tideland/flood-search-determination",
                showDivider: true,
            },
            {
                serviceTitle: "Life-of-Loan Flood Monitoring",
                availableFor: ["Nationwide"],
                certified: "No",
                url: "/solutions/flood-and-tideland/lol-flood-monitoring",
                showDivider: true,
            },
            {
                serviceTitle: "Tideland Claims Search",
                availableFor: ["New Jersey"],
                certified: "Yes",
                url: "/solutions/flood-and-tideland/tideland-claims-search",
                showDivider: true,
            },
            {
                serviceTitle: "Tideland Grant Search",
                availableFor: ["New Jersey"],
                certified: "No",
                url: "/solutions/flood-and-tideland/tideland-grant-search",
                showDivider: true,
            },
            {
                serviceTitle: "Wetland Determination",
                availableFor: ["New Jersey"],
                certified: "No",
                url: "/solutions/flood-and-tideland/wetland-determination",
                showDivider: false,
            },
        ],
    },

    {
        solutionTitle: "County Search Services",
        solutionTitleImg: "./img/county-search.svg",
        services: [
            {
                serviceTitle: "County Search Services",
                availableFor: ["New Jersey"],
                certified: "No",
                url: "/solutions/county-search/county-search",
                showDivider: false,
            },
        ],
    },

    {
        solutionTitle: "Corporate and UCC Services",
        solutionTitleImg: "./img/corporate_fare.svg",
        services: [
            {
                serviceTitle: "Status Report",
                availableFor: ["New Jersey", "Pennsylvania"],
                certified: "No",
                url: "/solutions/corporate-and-ucc/status-report",
                showDivider: true,
            },
            {
                serviceTitle: "Franchise Tax/Corporate Tax Lien Report",
                availableFor: ["New Jersey", "Pennsylvania"],
                certified: "No",
                url: "/solutions/corporate-and-ucc/franchise-and-corporate-tax-lien-report",
                showDivider: true,
            },
            {
                serviceTitle: "Good Standing Certificate",
                availableFor: ["New Jersey"],
                certified: "No",
                url: "/solutions/corporate-and-ucc/good-standing",
                showDivider: true,
            },
            {
                serviceTitle: "Legal Existence (Good Standing) Certificate",
                availableFor: ["New Jersey", "Pennsylvania"],
                certified: "No",
                url: "/solutions/corporate-and-ucc/legal-existence",
                showDivider: true,
            },
            {
                serviceTitle: "Corporate Copies",
                availableFor: ["New Jersey", "Pennsylvania"],
                certified: "No",
                url: "/solutions/corporate-and-ucc/corporate-copies",
                showDivider: true,
            },
            {
                serviceTitle: "UCC Plus",
                availableFor: ["New Jersey"],
                certified: "No",
                url: "/solutions/corporate-and-ucc/ucc-plus",
                showDivider: true,
            },
            {
                serviceTitle: "UCC State Certified",
                availableFor: ["New Jersey"],
                certified: "Yes",
                url: "/solutions/corporate-and-ucc/ucc-state-certified",
                showDivider: true,
            },
            {
                serviceTitle: "UCC State Report",
                availableFor: ["Pennsylvania"],
                certified: "No",
                url: "/solutions/corporate-and-ucc/ucc-state-report",
                showDivider: false,
            },
        ],
    },

    {
        solutionTitle: "Nationwide Services",
        solutionTitleImg: "./img/flag.svg",
        services: [
            {
                serviceTitle: "Due Diligence Services",
                availableFor: ["Nationwide"],
                certified: "No",
                url: "/solutions/nationwide-services/due-diligence",
                showDivider: true,
            },
            {
                serviceTitle: "Corporate Filing & Registered Agent Services",
                availableFor: ["Nationwide"],
                certified: "No",
                url: "/solutions/nationwide-services/corp-filing-registered-agent",
                showDivider: true,
            },
            {
                serviceTitle: "Flood Search",
                availableFor: ["Nationwide"],
                certified: "No",
                url: "/solutions/nationwide-services/flood-search",
                showDivider: true,
            },
            {
                serviceTitle: "Life-of-Loan Flood Monitoring",
                availableFor: ["Nationwide"],
                certified: "No",
                url: "/solutions/nationwide-services/lol-flood-monitoring",
                showDivider: true,
            },
            {
                serviceTitle: "Patriot Name Search",
                availableFor: ["Nationwide"],
                certified: "Yes",
                url: "/solutions/nationwide-services/patriot-name-search",
                showDivider: false,
            },
        ],
    },
];

export default solutionsData;
