import React, { useEffect, useState } from "react";
import Innerpageheading from "../common/innerpageheading";
import Breadcrumb from "../common/breadcrumb";
import Contact from "../common/contact";
import { newsInsight } from "../common/breadcrumbs";
import { newsInsightPageHeader } from "../common/pageheader";
import Ourexperts from "../common/ourexperts";
import "./newsinsight.css";
import { useSearchParams } from "react-router-dom";
import FullNewsArticle from "./FullNewsArticle";
import { useNavigate } from "react-router-dom";

import { allArticles, danielsLawArticle } from "./NewsConstants";

const NewsInsight = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [selectedArticle, setSelectedArticle] = useState(null);
    const [otherArticles, setOtherArticles] = useState(null);

    useEffect(() => {
        let articleMatchingSlug = null;
        const articleSearchParam = searchParams.get("article");

        if (!articleSearchParam || articleSearchParam === "") {
            // If they just go to the news page, redirect them to an article.
            navigate({
                pathname: "/news-insight",
                search: `?article=${danielsLawArticle.slug}`,
            });
        } else {
            articleMatchingSlug = allArticles.find(
                (x) => x.slug === articleSearchParam
            );

            // If they look for an article that doesnt exist, banish them to the 404 page.
            if (articleMatchingSlug == null) {
                navigate("/not-found");
            }

            setSelectedArticle(articleMatchingSlug);

            setOtherArticles(
                allArticles.filter((x) => x.slug !== articleSearchParam)
            );
        }
    }, [searchParams, setSelectedArticle, setOtherArticles, navigate]);

    return (
        <>
            <Innerpageheading pageHeadInfo={newsInsightPageHeader()} />
            <Breadcrumb breadcrumbInfo={newsInsight()} />

            <div className="row newsinsight-content justify-content-center">
                <FullNewsArticle
                    title={selectedArticle?.title}
                    articleJsx={selectedArticle?.fullPageJsx}
                ></FullNewsArticle>

                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                    <div className="left-badge">
                        <img
                            src="/img/cj-10years.svg"
                            alt="..."
                            className="cj110-years"
                        />
                    </div>
                    <div className="newsinsight-expert-heading">
                        <h2 className="newsinsight-expert-title">
                            Read news & Insights from our Experts
                        </h2>
                    </div>
                    {otherArticles?.map((article) => {
                        return (
                            <div
                                className="newsinsight-expert-info"
                                key={article?.slug}
                            >
                                <Ourexperts
                                    article={article}
                                    homePageArticle={false}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>

            <div className="row contact-loaction">
                <Contact />
            </div>
        </>
    );
};

export default NewsInsight;
