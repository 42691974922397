import Innerpageheading from "../../../components/common/innerpageheading";
import Breadcrumb from "../../../components/common/breadcrumb";
import ServiceDetails from "../../../components/solutions/ServiceDetails";

import { uccStateReportPageHeader } from "../../../components/common/pageheader";
import { uccStateReportBreadcrumb } from "../../../components/common/breadcrumbs";
import Contact from "../../../components/common/contact";

const UCCStateReport = ({
    showHeader = true,
    showBreadcrum = true,
    showTextExpand = false,
}) => {
    var bannerImage = "/img/solutions/ucc_state_report.jpg";
    var title = "UCC State Report";
    var availableFor = ["Pennsylvania"];
    var certified = "No";

    var getStartedUrl =
        showHeader === false
            ? "/solutions/corporate-and-ucc/ucc-state-report"
            : "/get-started";

    const TextContent = () => {
        return (
            <>
                <p className="solutions-details-page-p">
                    Uniform Commercial Code report issued against the name(s)
                    you request, detailing all findings filed with the
                    Commonwealth.
                </p>
            </>
        );
    };

    return (
        <>
            {showHeader === true ? (
                <Innerpageheading pageHeadInfo={uccStateReportPageHeader()} />
            ) : null}

            {showBreadcrum === true ? (
                <Breadcrumb breadcrumbInfo={uccStateReportBreadcrumb()} />
            ) : null}

            <div
                className={
                    showHeader === false
                        ? "pt-4 px-5 px-sm-4 px-md-3 px-lg-4 px-xxl-0"
                        : "container pt-6 pb-5 px-5 px-sm-4 px-md-3 px-lg-4 px-xxl-0"
                }
            >
                <ServiceDetails
                    title={title}
                    bannerUrl={bannerImage}
                    availableFor={availableFor}
                    certified={certified}
                    showTextExpand={showTextExpand}
                    textContent={<TextContent></TextContent>}
                    getStartedUrl={getStartedUrl}
                ></ServiceDetails>
            </div>

            {showHeader === true ? (
                <div className="row contact-loaction">
                    <Contact />
                </div>
            ) : null}
        </>
    );
};

export default UCCStateReport;
