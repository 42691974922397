import React from "react";
import { Button } from "react-bootstrap";
import "./ourexperts.css";

const Ourexperts = ({ article, homePageArticle }) => {
    const clsname = homePageArticle ? "" : "col-sm-12";

    return (
        <div
            className="article-card shadow-sm shadow shadow-lg bg-white mx-auto mt-5"
            style={{ paddingBottom: 0, height: "93%" }}
        >
            <div className={clsname}>
                <h5 className="expert-heading-title mb-4">
                    {article?.cardTitle}
                </h5>
                <p className="expert-desc">{article?.cardSummary}</p>
            </div>

            <div className="thumbnail-footer text-right">
                {homePageArticle ? (
                    <a href={`/news-insight?article=${article?.slug}`}>
                        <Button
                            type="submit"
                            className="my-2 btn-readmore arrow"
                            style={{
                                color: "#063b67",
                                background: "white",
                                margin: "1%",
                                borderColor: "#063b67",
                                fontSize: "13px",
                                fontWeight: "bold",
                                float: "right",
                            }}
                        >
                            READ MORE
                        </Button>
                    </a>
                ) : (
                    <a href={`news-insight?article=${article?.slug}`}>
                        <img
                            alt=""
                            className="secondary-circle-button"
                            style={{ float: "right" }}
                        />
                    </a>
                )}
            </div>
        </div>
    );
};

export default Ourexperts;
