import Container from "react-bootstrap/Container";
import HomeHero from "../../components/home/homeHero";
import Ourexperts from "../../components/common/ourexperts";
import IconButton from "../../components/buttons/iconButton/IconButton";
import SearchCard from "../../components/home/searchcard";
import ClientCard from "../../components/home/clientcard";
import {
    nameSearch,
    taxSearch,
    floodSearch,
    countySearch,
    corporateSearch,
    additionalServices,
    clientUzzolinoInfo,
    clientWallInfo,
    clientFortuneInfo,
} from "../../components/common/contenthelper";
import {
    danielsLawArticle,
    tidelandImportanceArticle,
    dtsTitlePlantsArticle,
} from "../../components/newsinsights/NewsConstants";

export default function Home() {
    const articles = [
        danielsLawArticle,
        dtsTitlePlantsArticle,
        tidelandImportanceArticle,
    ];

    return (
        <div>
            <HomeHero />

            <Container className="my-0 px-0" fluid>
                <div>
                    <div className="row clientRow xl-max-size justify-content-center">
                        <div className="searchCard-header">
                            <p className="testmonials">OUR SOLUTIONS</p>
                            <h1 className="clientsHeader-title">
                                Regional & National Public Records
                            </h1>
                            <div className="holder">
                                <div className="text-center">
                                    <p className="holder-desc">
                                        No regional public record firm matches
                                        Charles Jones in serving the title,
                                        legal, and lending markets with our
                                        level of resources and expertise. We
                                        pride ourselves on providing
                                        high-quality due diligence solutions in
                                        New Jersey and Pennsylvania.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-sm-3 mt-5">
                            <SearchCard cardInfo={nameSearch()} />
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-sm-3 mt-5">
                            <SearchCard cardInfo={taxSearch()} />
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-sm-3 mt-5">
                            <SearchCard cardInfo={floodSearch()} />
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3">
                            <SearchCard cardInfo={countySearch()} />
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3">
                            <SearchCard cardInfo={corporateSearch()} />
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3">
                            <SearchCard cardInfo={additionalServices()} />
                        </div>

                        <div className="text-center mt-5">
                            <a href="/get-started">
                                <IconButton
                                    id="get-started-button"
                                    text="GET STARTED"
                                    styleClass="btn-cj-transparent btn-get-started"
                                    iconClass="btn-arrow-right-white"
                                    style={{
                                        marginLeft: "1.5rem",
                                        padding: " 24px 36px",
                                        fontSize: "18px",
                                        borderRadius: "40px",
                                    }}
                                />
                            </a>
                        </div>
                    </div>
                </div>

                <div className="house-ad justify-content-center px-5">
                    <div className="row xl-max-size">
                        <div className="col-12 col-md-5 col-lg-6 house-img-cntr">
                            <img
                                className="house-ad-img"
                                src="/img/cj-10years.svg"
                                alt="..."
                            />
                        </div>
                        <div className="col-12 col-md-7 col-lg-6 align-items-center d-flex">
                            <div>
                                <h1>
                                    Embracing the past.&nbsp; Building the
                                    future.
                                </h1>
                                <p>
                                    Charles Jones has been the industry leader
                                    in accurate and dependable due diligence
                                    searches for over a century.
                                </p>
                                <a href="/about">
                                    <IconButton
                                        id="learn-more-button"
                                        text="LEARN MORE"
                                        styleClass="btn-cj-transparent home-hero-button"
                                        iconClass="btn-arrow-right-white"
                                        type="submit"
                                        style={{ marginLeft: "1.5rem" }}
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="client-row-container">
                    <div className="row clientRow xl-max-size justify-content-center">
                        <div className="clientsHeader mt-5">
                            <p className="testmonials">TESTIMONIALS</p>
                            <h1 className="clientsHeader-title">
                                What Our Clients Are Saying
                            </h1>
                            <div className="holder">
                                <div className="text-center">
                                    <p className="holder-desc">
                                        Hear success stories straight from our
                                        customers.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4">
                            <ClientCard clientInfo={clientUzzolinoInfo()} />
                        </div>

                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4">
                            <ClientCard clientInfo={clientFortuneInfo()} />
                        </div>

                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4">
                            <ClientCard clientInfo={clientWallInfo()} />
                        </div>

                        <div className="text-center mt-5">
                            <a href="/testimonials">
                                <IconButton
                                    id="btn-view-all"
                                    text="VIEW ALL"
                                    styleClass="btn-cj-transparent btn-get-started btn-view-all"
                                    iconClass="btn-arrow-right-white"
                                    style={{
                                        marginLeft: "1.5rem",
                                        padding: " 24px 36px",
                                        fontSize: "18px",
                                        borderRadius: "40px",
                                    }}
                                />
                            </a>
                        </div>
                    </div>
                </div>

                <div className="experts-container">
                    <div className="row clientRow xl-max-size justify-content-center">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="row expert-heading-smlg text-center">
                                <h2 className="expert-title">
                                    Read News & Insights From Our Experts.
                                </h2>
                            </div>
                            <div className="row justify-content-center">
                                {articles.map((article) => {
                                    return (
                                        <div
                                            key={article.slug}
                                            className="col-12 col-md-10 col-lg-6"
                                        >
                                            <Ourexperts
                                                article={article}
                                                homePageArticle={true}
                                            />
                                        </div>
                                    );
                                })}
                            </div>

                            <div className="text-center btn-viewall-smlg mt-5">
                                <a href="/news-insight">
                                    <IconButton
                                        id="btn-view-all"
                                        text="VIEW ALL"
                                        styleClass="btn-cj-transparent btn-get-started btn-view-all"
                                        iconClass="btn-arrow-right-white"
                                        style={{
                                            marginLeft: "1.5rem",
                                            padding: "24px 36px",
                                            fontSize: "18px",
                                            borderRadius: "40px",
                                        }}
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}
