import React, { useState, useEffect } from "react";
import "./privacynotice.css";
import IconButton from "../buttons/iconButton";

const PrivacyNotice = () => {
    const [showPrivacyNotice, setshowPrivacyNotice] = useState(false);
    const getckvalue = document.cookie
        .split("; ")
        .find((row) => row.startsWith("CjPrivacyAcknowledge=1"))
        ?.split("=")[1];

    useEffect(() => {
        if (getckvalue && getckvalue === "1") {
            setshowPrivacyNotice(false);
        } else {
            setTimeout(() => {
                setshowPrivacyNotice(true);
            }, 500);
        }
    }, [getckvalue]);

    const handleClose = () => {
        const duration = new Date(Date.now() + 12096e5);
        document.cookie =
            "CjPrivacyAcknowledge=1;secure;expires=" +
            duration.toUTCString() +
            ";domain=." +
            document.domain +
            ";path=/;SameSite=Strict";
        setshowPrivacyNotice(false);
    };

    return (
        <>
            {showPrivacyNotice && (
                <div className="app-privacy-notice m-4">
                    <div className="privacynotice">
                        <div className="row privacy-notice-content align-items-center">
                            <div className="col-12 col-md-10">
                                <h4 className="privacy-notice-title">
                                    PRIVACY NOTICE
                                </h4>
                                <p className="privacy-notice-desc mb-0">
                                    This website uses cookies and similar
                                    technologies to manage your sessions, manage
                                    content, and improve your website
                                    experience. To learn more about these
                                    technologies, your options, and about other
                                    categories of personal information we
                                    collect through this website and how we may
                                    use it, please see our{" "}
                                    <a
                                        className="privacy-policy-link"
                                        href="https://www.firstam.com/privacy-policy/#type-of-information"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        privacy policy
                                    </a>
                                    . This notice is effective for your use of
                                    this website for the next 14 days.
                                </p>
                            </div>
                            <div className="col-12 col-md-2 mt-3 mt-md-0">
                                <IconButton
                                    id="btn-got-it"
                                    text="Got It"
                                    className="btn-cj-transparent btn-get-started btn-got-it mt-0"
                                    style={{
                                        fontSize: "14px",
                                        padding: "20px",
                                        fontWeight: "700",
                                        float: "right",
                                        margin: "0 !important",
                                        marginTop: "0 !important",
                                    }}
                                    onClickAction={() => handleClose()}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default PrivacyNotice;
