import Innerpageheading from "../../../components/common/innerpageheading";
import Breadcrumb from "../../../components/common/breadcrumb";
import ServiceDetails from "../../../components/solutions/ServiceDetails";

import { franchiseAndCorporateTaxReportPageHeader } from "../../../components/common/pageheader";
import { franchiseAndCorporateTaxReportBreadcrumb } from "../../../components/common/breadcrumbs";
import Contact from "../../../components/common/contact";

const FranchiseAndCorporateTaxReport = ({
    showHeader = true,
    showBreadcrum = true,
    showTextExpand = false,
}) => {
    var bannerImage = "/img/solutions/franchise_tax.jpg";
    var title = "Franchise Tax/Corporate Tax Lien Report";
    var availableFor = ["New Jersey", "Pennsylvania"];
    var certified = "No";

    var getStartedUrl =
        showHeader === false
            ? "/solutions/corporate-and-ucc/franchise-and-corporate-tax-lien-report"
            : "/get-started";

    const TextContent = () => {
        return (
            <>
                <p className="solutions-details-page-p">
                    All domestic or foreign corporations transacting business in
                    New Jersey are subject to the payment of franchise taxes.
                    Unpaid franchise taxes constitute a lien on all of the
                    corporation's property for a period of ten years, which is
                    computed from the first day of the year in which the tax is
                    due and payable. However, the lien is imposed on the first
                    day of the year following the year in which the tax is due.
                </p>
                <p className="solutions-details-page-p">
                    Typical State turnaround time: Once you enter your order, we
                    send it directly to the State Tax Department for completion.
                    Typical state turnaround 6-8 weeks.
                </p>
                <p className="solutions-details-page-p">
                    Charles Jones also provides a Pennsylvania report detailing
                    Tax Lien Status for all entities doing business in the
                    Commonwealth. That report outlines Tax Status with PA's
                    Division of Revenue, including notification of any liens
                    filed or franchise taxes due.
                </p>
            </>
        );
    };

    return (
        <>
            {showHeader === true ? (
                <Innerpageheading
                    pageHeadInfo={franchiseAndCorporateTaxReportPageHeader()}
                />
            ) : null}

            {showBreadcrum === true ? (
                <Breadcrumb
                    breadcrumbInfo={franchiseAndCorporateTaxReportBreadcrumb()}
                />
            ) : null}

            <div
                className={
                    showHeader === false
                        ? "pt-4 px-5 px-sm-4 px-md-3 px-lg-4 px-xxl-0"
                        : "container pt-6 pb-5 px-5 px-sm-4 px-md-3 px-lg-4 px-xxl-0"
                }
            >
                <ServiceDetails
                    title={title}
                    bannerUrl={bannerImage}
                    availableFor={availableFor}
                    certified={certified}
                    showTextExpand={showTextExpand}
                    textContent={<TextContent></TextContent>}
                    getStartedUrl={getStartedUrl}
                ></ServiceDetails>
            </div>

            {showHeader === true ? (
                <div className="row contact-loaction">
                    <Contact />
                </div>
            ) : null}
        </>
    );
};

export default FranchiseAndCorporateTaxReport;
