export default function MobileDropdownRow({
    name,
    showGoBackNavigation,
    navigateTo,
    ...props
}) {
    return showGoBackNavigation ? (
        <div {...props} className="mobile-dropdown-row">
            <span
                className="btn-arrow-left me-2"
                style={{ paddingTop: "20px", height: "18px", width: "18px" }}
            ></span>
            <span>Back</span>
        </div>
    ) : (
        <a
            href={navigateTo}
            {...props}
            className="mobile-dropdown-row justify-content-center"
        >
            {name}
            <span
                className="btn-arrow-right ms-auto"
                style={{
                    paddingTop: "20px",
                    height: "18px",
                    width: "18px",
                }}
            ></span>
        </a>
    );
}
