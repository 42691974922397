import Innerpageheading from "../../../components/common/innerpageheading";
import Breadcrumb from "../../../components/common/breadcrumb";
import ServiceDetails from "../../../components/solutions/ServiceDetails";

import { statusReportPageHeader } from "../../../components/common/pageheader";
import { statusReportBreadcrumb } from "../../../components/common/breadcrumbs";
import Contact from "../../../components/common/contact";

const StatusReport = ({
    showHeader = true,
    showBreadcrum = true,
    showTextExpand = false,
}) => {
    var bannerImage = "/img/solutions/corp_status_report.jpg";
    var title = "Status Report";
    var availableFor = ["New Jersey", "Pennsylvania"];
    var certified = "No";

    var getStartedUrl =
        showHeader === false
            ? "/solutions/corporate-and-ucc/status-report"
            : "/get-started";

    const TextContent = () => {
        return (
            <>
                <p className="solutions-details-page-p">
                    You will receive a report that supplies the information you
                    need about a corporation such as: date of formation, name of
                    the registered agent, registered office of the entity and
                    the status (active, dissolved, revoked) of the entity, and
                    principal office address if any. Typical turnaround time:
                    Within 24 hours. Available online in New Jersey and
                    Pennsylvania.
                </p>
            </>
        );
    };

    return (
        <>
            {showHeader === true ? (
                <Innerpageheading pageHeadInfo={statusReportPageHeader()} />
            ) : null}

            {showBreadcrum === true ? (
                <Breadcrumb breadcrumbInfo={statusReportBreadcrumb()} />
            ) : null}

            <div
                className={
                    showHeader === false
                        ? "pt-4 px-5 px-sm-4 px-md-3 px-lg-4 px-xxl-0"
                        : "container pt-6 pb-5 px-5 px-sm-4 px-md-3 px-lg-4 px-xxl-0"
                }
            >
                <ServiceDetails
                    title={title}
                    bannerUrl={bannerImage}
                    availableFor={availableFor}
                    certified={certified}
                    showTextExpand={showTextExpand}
                    textContent={<TextContent></TextContent>}
                    getStartedUrl={getStartedUrl}
                ></ServiceDetails>
            </div>

            {showHeader === true ? (
                <div className="row contact-loaction">
                    <Contact />
                </div>
            ) : null}
        </>
    );
};

export default StatusReport;
