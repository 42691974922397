import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
} from "react-share";

const FullNewsArticle = ({ title, articleJsx }) => {
    const sharingLink = window.location.href;

    return (
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8 newsinsight-content-left">
            <h4 className="newsinsight-title">{title}</h4>
            <div className="newsinsight-socialicons">
                <EmailShareButton url={sharingLink}>
                    <p className="newsinsight-socialmediaicons-share">SHARE</p>
                </EmailShareButton>

                <FacebookShareButton url={sharingLink}>
                    <img
                        className="newsinsight-socialmediaicons"
                        src="/img/facebook-news.svg"
                        alt="..."
                    />
                </FacebookShareButton>

                <TwitterShareButton url={sharingLink}>
                    <img
                        className="newsinsight-socialmediaicons"
                        src="/img/twitter-news.svg"
                        alt="..."
                    />
                </TwitterShareButton>

                <LinkedinShareButton
                    url={sharingLink}
                    source={sharingLink}
                    summary={sharingLink}
                    title="Check Out this Article from Charles Jones"
                >
                    <img
                        className="newsinsight-socialmediaicons"
                        src="/img/linkedIn-news.svg"
                        alt="..."
                    />
                </LinkedinShareButton>
            </div>
            {articleJsx}
        </div>
    );
};

export default FullNewsArticle;
