import Innerpageheading from "../../../components/common/innerpageheading";
import Breadcrumb from "../../../components/common/breadcrumb";
import Contact from "../../../components/common/contact";
import { taxSearchesPageHeader } from "../../../components/common/pageheader";
import { taxSearchesBreadcrumb } from "../../../components/common/breadcrumbs";
import TaxAndAssessment from "./TaxAndAssessment";
import TaxCertifications from "./TaxCertifications";

const TaxSearches = () => {
    return (
        <>
            <Innerpageheading pageHeadInfo={taxSearchesPageHeader()} />
            <Breadcrumb breadcrumbInfo={taxSearchesBreadcrumb()} />

            <div className="container pb-5">
                <div className="row pt-6">
                    <div className="col-12">
                        <TaxAndAssessment
                            showHeader={false}
                            showBreadcrum={false}
                            showTextExpand={true}
                        ></TaxAndAssessment>
                    </div>
                </div>

                <div className="row pt-5">
                    <div className="col-12">
                        <hr stroke="#D4D2E3" className="bottom-content"></hr>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <TaxCertifications
                            showHeader={false}
                            showBreadcrum={false}
                            showTextExpand={true}
                        ></TaxCertifications>
                    </div>
                </div>
            </div>
            <div className="row contact-loaction">
                <Contact />
            </div>
        </>
    );
};

export default TaxSearches;
