import React, { useState } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import { Button } from "react-bootstrap";

const AvailableForItem = ({ item }) => {
    return (
        <div className="row">
            <div className="col">
                <img src="/img/where_to_vote.svg" alt=""></img>{" "}
                <span className="solutions-details-page-info">{item}</span>
            </div>
        </div>
    );
};

const ServiceDetails = ({
    title,
    bannerUrl,
    availableFor,
    certified,
    showTextExpand,
    textContent,
    getStartedUrl = "",
}) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpanded = () => {
        setIsExpanded(!isExpanded);
    };

    const ViewMore = () => {
        if (showTextExpand === true) {
            if (isExpanded) {
                return "solutions-details-view-more-expand";
            } else {
                return "solutions-details-view-more-collapse";
            }
        } else {
            return "";
        }
    };

    return (
        <>
            <div className="">
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-lg-4 col-md-5 col-xl-4 col-sm-12">
                                <img
                                    className="solutions-details-page-img"
                                    src={bannerUrl}
                                    alt=""
                                ></img>
                            </div>
                            <div className="col-lg-8 col-md-7 col-xl-8 col-sm-12">
                                <div className="row">
                                    <div className="col-12">
                                        <span className="solutions-details-page-title">
                                            {title}
                                        </span>
                                    </div>
                                </div>

                                <div className="row pt-2">
                                    <div className="col-lg-4 col-md-6 col-xl-4 col-sm-6 col-6">
                                        <span className="solutions-details-page-details">
                                            Available for
                                        </span>
                                    </div>
                                    <div className="col-lg-8 col-xl-8 col-md-6 col-sm-6 col-6">
                                        <span className="solutions-details-page-details">
                                            certified Search
                                        </span>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-xl-4 col-sm-6 col-6">
                                        {availableFor.map((item, index) => (
                                            <AvailableForItem
                                                key={index}
                                                item={item}
                                            />
                                        ))}
                                    </div>
                                    <div className="col-lg-8 col-xl-8 col-md-6 col-sm-6 col-6">
                                        <div
                                            className={
                                                certified === "No"
                                                    ? "fade-availability"
                                                    : ""
                                            }
                                        >
                                            <span className="solutions-details-page-info">
                                                <img
                                                    src="/img/verified_user.svg"
                                                    alt=""
                                                />
                                                {certified}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="row pt-2">
                                    <div className="col-12">
                                        <a href={"/get-started"}>
                                            <Button
                                                className="btn btn-cj-secondary mt-2 icon-btn"
                                                type="button"
                                            >
                                                GET STARTED
                                                <span className="btn-arrow-right ms-2"></span>
                                            </Button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className={ViewMore()}>
                                <div className="col-12">{textContent}</div>
                            </div>
                        </div>
                    </div>
                </div>

                {showTextExpand === true ? (
                    <div className="row">
                        <div className="col-12">
                            <div
                                onClick={toggleExpanded}
                                className="solutions-details-toggel-all"
                            >
                                <RiArrowDropDownLine
                                    className={
                                        isExpanded
                                            ? "solutions-details-toogle-dropdownline-close"
                                            : "solutions-details-toogle-dropdownline-open"
                                    }
                                ></RiArrowDropDownLine>
                                <span className="solutions-details-toggle-wrapper">
                                    {isExpanded ? "View Less" : "View More"}
                                </span>
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        </>
    );
};

export default ServiceDetails;
