import Innerpageheading from "../../../components/common/innerpageheading";
import Breadcrumb from "../../../components/common/breadcrumb";
import Contact from "../../../components/common/contact";
import { corporateAndUCCServicesPageHeader } from "../../../components/common/pageheader";
import { corporateAndUCCServicesBreadcrumb } from "../../../components/common/breadcrumbs";
import StatusReport from "./StatusReport";
import FranchiseAndCorporateTaxReport from "./FranchiseAndCorporateTaxReport";
import GoodStandingCertificate from "./GoodStandingCertificate";
import LegalExistenceCertificate from "./LegalExistenceCertificate";
import CorporateCopies from "./CorporateCopies";
import UCCPlus from "./UCCPlus";
import UCCStateCertified from "./UCCStateCertified";
import UCCStateReport from "./UCCStateReport";

const CorporateAndUCCServices = () => {
    return (
        <>
            <Innerpageheading
                pageHeadInfo={corporateAndUCCServicesPageHeader()}
            />
            <Breadcrumb breadcrumbInfo={corporateAndUCCServicesBreadcrumb()} />

            <div className="container pb-5">
                <div className="row pt-6">
                    <div className="col-12">
                        <StatusReport
                            showHeader={false}
                            showBreadcrum={false}
                            showTextExpand={true}
                        ></StatusReport>
                    </div>
                </div>

                <div className="row pt-5">
                    <div className="col-12">
                        <hr stroke="#D4D2E3" className="bottom-content"></hr>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <FranchiseAndCorporateTaxReport
                            showHeader={false}
                            showBreadcrum={false}
                            showTextExpand={true}
                        ></FranchiseAndCorporateTaxReport>
                    </div>
                </div>

                <div className="row pt-5">
                    <div className="col-12">
                        <hr stroke="#D4D2E3" className="bottom-content"></hr>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <GoodStandingCertificate
                            showHeader={false}
                            showBreadcrum={false}
                            showTextExpand={false}
                        ></GoodStandingCertificate>
                    </div>
                </div>

                <div className="row pt-5">
                    <div className="col-12">
                        <hr stroke="#D4D2E3" className="bottom-content"></hr>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <LegalExistenceCertificate
                            showHeader={false}
                            showBreadcrum={false}
                            showTextExpand={false}
                        ></LegalExistenceCertificate>
                    </div>
                </div>

                <div className="row pt-5">
                    <div className="col-12">
                        <hr stroke="#D4D2E3" className="bottom-content"></hr>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <CorporateCopies
                            showHeader={false}
                            showBreadcrum={false}
                            showTextExpand={false}
                        ></CorporateCopies>
                    </div>
                </div>

                <div className="row pt-5">
                    <div className="col-12">
                        <hr stroke="#D4D2E3" className="bottom-content"></hr>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <UCCPlus
                            showHeader={false}
                            showBreadcrum={false}
                            showTextExpand={false}
                        ></UCCPlus>
                    </div>
                </div>

                <div className="row pt-5">
                    <div className="col-12">
                        <hr stroke="#D4D2E3" className="bottom-content"></hr>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <UCCStateCertified
                            showHeader={false}
                            showBreadcrum={false}
                            showTextExpand={false}
                        ></UCCStateCertified>
                    </div>
                </div>

                <div className="row pt-5">
                    <div className="col-12">
                        <hr stroke="#D4D2E3" className="bottom-content"></hr>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <UCCStateReport
                            showHeader={false}
                            showBreadcrum={false}
                            showTextExpand={false}
                        ></UCCStateReport>
                    </div>
                </div>
            </div>
            <div className="row contact-loaction">
                <Contact />
            </div>
        </>
    );
};

export default CorporateAndUCCServices;
