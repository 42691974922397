import React from 'react'
import './termsandconditions.css'
import Innerpageheading from '../common/innerpageheading';
import Breadcrumb from '../common/breadcrumb';
import Contact from '../common/contact';
import { termsAndConditionsPageHeader } from '../common/pageheader';
import { termsAndConditions } from '../common/breadcrumbs';

const TermsAndConditions = () => {
    return (
        <>
            <Innerpageheading pageHeadInfo={termsAndConditionsPageHeader()} />
            <Breadcrumb breadcrumbInfo={termsAndConditions()} />

            <div className='terms-conditions-content'>
                <h4 className='terms-conditions-title'>General Use</h4>
                <p className='terms-conditions-desc'>This site and all content in this site may not be copied, reproduced, republished, uploaded, posted, transmitted, distributed, or used for the creation of derivative works without First American's prior written consent, except that First American grants you non-exclusive, non-transferable, limited permission to access and display the Web pages within this site, solely on your computer and for your personal, non-commercial use of this Web site. This permission is conditioned on your not modifying the content displayed on this site, your keeping intact all copyright, trademark, and other proprietary notices, and your acceptance of any terms, conditions, and notices accompanying the content or otherwise set forth in this site. Notwithstanding the foregoing, any software and other materials that are made available for downloading, access, or other use from this site with their own license terms, conditions, and notices will be governed by such terms, conditions, and notices.</p>
                <h4 className='terms-conditions-title1'>Links to Third Party Websites</h4>
                <p className='terms-conditions-desc1'>Certain links on this website will take you away from First American's site. The linked sites are not under the control of First American and First American is not responsible for the contents of any linked site. First American provides these links as a convenience to users and the inclusion of any link does not imply endorsement by First American of any third party site.</p>
            </div>
            <div className='row contact-loaction'>
                <Contact />
            </div>
        </>
    )
}

export default TermsAndConditions;
