import Innerpageheading from "../../../components/common/innerpageheading";
import Breadcrumb from "../../../components/common/breadcrumb";
import ServiceDetails from "../../../components/solutions/ServiceDetails";

import {
    additionalLolFloodMonitoringPageHeader,
    lifeOfLoanFloodMonitoringPageHeader,
} from "../../../components/common/pageheader";
import {
    additionalLolFloodMonitoringBreadcrumb,
    lifeOfLoanFloodMonitoringBreadcrumb,
} from "../../../components/common/breadcrumbs";
import Contact from "../../../components/common/contact";
import { useLocation } from "react-router-dom";

const LifeOfLoanFloodMonitoring = ({
    showHeader = true,
    showBreadcrum = true,
    showTextExpand = false,
}) => {
    var bannerImage = "/img/solutions/lol_flood.jpg";
    var title = "Life-of-Loan Flood Monitoring";
    var availableFor = ["Nationwide"];
    var certified = "No";
    const location = useLocation();
    const isAdditional = location?.pathname?.includes("/nationwide-services");

    var getStartedUrl =
        showHeader === false
            ? isAdditional === false
                ? "/solutions/flood-and-tideland/lol-flood-monitoring"
                : "/solutions/nationwide-services/lol-flood-monitoring"
            : "/get-started";

    const TextContent = () => {
        return (
            <>
                <p className="solutions-details-page-p">
                    The National Flood Insurance Reform Act of 1994 requires
                    that lenders must monitor loans to ensure that flood
                    insurance is continued for the life of the loan and that
                    changes in Flood Insurance Rate Maps be reflected in the
                    requirement for insurance and the amount of premiums paid.
                    To comply with this requirement, Charles Jones is pleased to
                    provide an automated life-of-loan tracking service to
                    identify properties affected by periodic changes in flood
                    maps.
                </p>
            </>
        );
    };

    return (
        <>
            {showHeader === true ? (
                <Innerpageheading
                    pageHeadInfo={
                        isAdditional === false
                            ? lifeOfLoanFloodMonitoringPageHeader()
                            : additionalLolFloodMonitoringPageHeader()
                    }
                />
            ) : null}

            {showBreadcrum === true ? (
                <Breadcrumb
                    breadcrumbInfo={
                        isAdditional === false
                            ? lifeOfLoanFloodMonitoringBreadcrumb()
                            : additionalLolFloodMonitoringBreadcrumb()
                    }
                />
            ) : null}

            <div
                className={
                    showHeader === false
                        ? "pt-4 px-5 px-sm-4 px-md-3 px-lg-4 px-xxl-0"
                        : "container pt-6 pb-5 px-5 px-sm-4 px-md-3 px-lg-4 px-xxl-0"
                }
            >
                <ServiceDetails
                    title={title}
                    bannerUrl={bannerImage}
                    availableFor={availableFor}
                    certified={certified}
                    showTextExpand={showTextExpand}
                    textContent={<TextContent></TextContent>}
                    getStartedUrl={getStartedUrl}
                ></ServiceDetails>
            </div>

            {showHeader === true ? (
                <div className="row contact-loaction">
                    <Contact />
                </div>
            ) : null}
        </>
    );
};

export default LifeOfLoanFloodMonitoring;
