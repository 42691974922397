import React from "react";
import "./innerpageinnerheading.css";

const Innerpageinnerheading = ({ innerText }) => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <p className="inner-heading-text">{innerText}</p>
                </div>
            </div>
        </div>
    );
};

export default Innerpageinnerheading;
