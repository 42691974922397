import React from "react";
import "./executiveteam.css";
import ExecutiveProfie from "./executiveprofie";
import Innerpageheading from "../common/innerpageheading";
import Breadcrumb from "../common/breadcrumb";
import Ourexperts from "../common/ourexperts";
import Contact from "../common/contact";
import IconButton from "../buttons/iconButton";
import { executiveTeam } from "../common/breadcrumbs";
import { executivePageHeader } from "../common/pageheader";
import {
    tidelandImportanceArticle,
    tidelandsArticle,
} from "../newsinsights/NewsConstants";

const ExecutiveTeam = () => {
    const articles = [tidelandImportanceArticle, tidelandsArticle];

    const profile1 = {
        profieImg: "/img/executives/chuck-mauro.jpg",
        linkedInLink: "https://www.linkedin.com/in/chuck-mauro-25771032",
        twitterLink: "https://x.com/CharlesMauro62",
        instagramLink: "https://www.instagram.com/chuckmauro4/",
        name: "Chuck Mauro",
        occupation: "General Manager",
        desc1: "Chuck Mauro is Charles Jones’ General Manager. He began his career in the title insurance industry as an examiner in Kings County (Brooklyn) NY in 1985 and has held many positions over the years including: Director of Customer Service and V.P. of Operations as his employer went through several mergers and acquisitions.",
        desc2: "Chuck graduated summa cum laude from St Francis College in downtown Brooklyn NY with a Bachelor of Arts degree in Economics & Social Sciences.",
        desc3: "On the personal side, he and his wife, Dorothy have two grown daughters.",
        desc4: "In addition, Chuck is an avid sports fan and a NY Rangers season ticket holder. Over the years he has been lucky to meet many of his favorite NY Mets, Jets, Knicks, and Rangers players in person – including Dwight Gooden, Joe Klecko, Earl Monroe, and Jeff Beukeboom - who let Chuck try on his 1994 Stanley Cup championship ring (Chuck was also the team’s victory parade in lower Manhattan when they won that championship in 1994).",
    };

    const profile2 = {
        profieImg: "/img/executives/steve-goldstein.jpg",
        name: "Steven Goldstein",
        occupation: "Vice President of Sales",
        desc1: "Steven Goldstein is Charles Jones’ Vice President of Sales. He worked his way into the title insurance industry in 2000 after many years in the building material business and began his career as Vice President at Trans County Title, where he was responsible for all daily operations. Steven became a member of the Charles Jones team in 2014 as account manager for northern New Jersey and developed strong professional relationships with a wide range of clients and ascended to New Jersey state manager. After a year of successful growth, Steven assumed his current role.",
        desc2: "Steven's vast experience in the industry has been integral to maintaining stellar customer relations as well as company change. He served as the Chairperson of the NJLTA agency section 2011-2012 and volunteered with the American Red Cross for many years.",
        desc4: "In his spare time, he enjoys spending time with his family, being outdoors, traveling, hiking, cooking and any home improvement related project.",
    };

    const profile3 = {
        profieImg: "/img/executives/joe-o-gorman.jpg",
        linkedInLink: "https://www.linkedin.com/in/joe-ogorman-6149041b",
        twitterLink: "https://x.com/colonel_23",
        instagramLink: "https://www.instagram.com/joesnore_23/",
        name: "Joe O'Gorman",
        occupation: "Director of Operations",
        desc1: "Joe O’Gorman is Charles Jones’s Director of Operations. He began his career in the industry back in 1995 when he applied for a tax field searcher position with Current Status, which later became part of the DataTrace family of companies.",
        desc2: "He continued to add to his resume with roles in the Corporate Services, Judgment Search and Product Development Departments before being promoted to his current role.",
        desc4: "Joe graduated from Rider University with a Bachelor of Arts degree in 1993 and in early 2023, Joe was honored by his high school Alma Mater with the Serve Youth Award.",
        desc5: "What you may not know about Joe is that he is an avid golfer who has been fortunate enough to play at some of the country’s most well-known courses. His goal is to play as many of the “Top 100” as he can!",
    };

    return (
        <>
            <Innerpageheading pageHeadInfo={executivePageHeader()} />
            <Breadcrumb breadcrumbInfo={executiveTeam()} />

            <div className="xl-max-size">
                {" "}
                <div className="col executive-team">
                    <ExecutiveProfie profileInfo={profile1} />
                    <ExecutiveProfie profileInfo={profile2} />
                    <ExecutiveProfie
                        style={{ paddingBottom: "0" }}
                        profileInfo={profile3}
                    />
                </div>
            </div>

            <div className="experts-container">
                <div className="row experts-horizontal-view xl-max-size">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="row expert-heading-smlg text-center">
                            <h2 className="expert-title">
                                Read News & Insights From Our Experts.
                            </h2>
                        </div>
                        <div className="row justify-content-center">
                            {articles.map((article) => {
                                return (
                                    <div className="col-12 col-sm-12 col-md-9 col-lg-6 col-xl-5">
                                        <Ourexperts
                                            article={article}
                                            homePageArticle={true}
                                        />
                                    </div>
                                );
                            })}
                        </div>

                        <div className="text-center btn-viewall-smlg mt-5">
                            <a href="/news-insight">
                                <IconButton
                                    id="btn-view-all"
                                    text="VIEW ALL"
                                    styleClass="btn-cj-transparent btn-get-started btn-view-all"
                                    iconClass="btn-arrow-right-white"
                                    style={{
                                        marginLeft: "1.5rem",
                                        padding: "24px 36px",
                                        fontSize: "18px",
                                        borderRadius: "40px",
                                    }}
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row contact-loaction">
                <Contact />
            </div>
        </>
    );
};

export default ExecutiveTeam;
