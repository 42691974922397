const emailregex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const checkAndAddFieldErrorMessage = (
    form,
    errorList,
    fieldName,
    fieldCommonName
) => {
    if (!form[fieldName] || isNullOrEmpty(form[fieldName])) {
        errorList[fieldName] = {};
        errorList[fieldName] = `Please enter a ${fieldCommonName}.`;
    }
};

const submitForm = async (url, formData) => {
    // Remove whitespace from strings
    Object.keys(formData).forEach((k) =>
        typeof formData[k] === "string"
            ? (formData[k] = formData[k].trim())
            : formData[k]
    );

    const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(formData),
    });

    return response?.status && response.status === 200;
};

const normalizeInput = (value, previousValue) => {
    // return nothing if no value
    if (!value) return value;

    // only allows 0-9 inputs
    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
        // returns: "x", "xx", "xxx"
        if (cvLength < 4) return currentValue;

        // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
        if (cvLength < 7)
            return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;

        // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
            3,
            6
        )}-${currentValue.slice(6, 10)}`;
    }
};

const removeAlpha = (value) => {
    return allowASCIIValues(value).replace(/[^\d]/g, "");
};

const allowASCIIValues = (value) => {
    if (value) {
        // eslint-disable-next-line no-control-regex
        return value?.replace(/[^\x20-\x7E]/gi, "");
    }
    return "";
};

const isValidEmail = (value) => {
    return value.match(emailregex);
};

const isNullOrEmpty = (value) => {
    return value === null || value.match(/^ *$/) !== null;
};

export {
    checkAndAddFieldErrorMessage,
    submitForm,
    normalizeInput,
    removeAlpha,
    allowASCIIValues,
    isValidEmail,
};
