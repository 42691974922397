import Innerpageheading from "../../../components/common/innerpageheading";
import Breadcrumb from "../../../components/common/breadcrumb";
import ServiceDetails from "../../../components/solutions/ServiceDetails";

import { wetlandDeterminationPageHeader } from "../../../components/common/pageheader";
import { wetlandDeterminationBreadcrumb } from "../../../components/common/breadcrumbs";
import Contact from "../../../components/common/contact";

const WetlandDetermination = ({
    showHeader = true,
    showBreadcrum = true,
    showTextExpand = false,
}) => {
    var bannerImage = "/img/solutions/wetland.jpg";
    var title = "Wetland Determination";
    var availableFor = ["New Jersey"];
    var certified = "No";

    var getStartedUrl =
        showHeader === false
            ? "/solutions/flood-and-tideland/wetland-determination"
            : "/get-started";

    const TextContent = () => {
        return (
            <>
                <p className="solutions-details-page-p">
                    An opinion regarding the possible existence of wetlands on a
                    designated property. The process involves research of
                    topographic maps, aerial photography, soil survey maps,
                    National Wetland Inventory maps, drainage basin and water
                    supply overlays, and delineations of the specially protected
                    New Jersey Pinelands.
                </p>
                <p className="solutions-details-page-p">
                    These maps permit us to predict the likelihood of wetlands
                    using the standard "three parameter" definition of wetlands
                    based upon: soils, vegetation and hydrography. Since each
                    variable must work together with other factors to establish
                    a wetland, only an on-site inspection can delineate the
                    extent of wetlands with certainty. However, a wetlands
                    opinion provides a low cost, timely report on the likelihood
                    of wetlands and can save significant time and money before a
                    comprehensive investigation is made.
                </p>
            </>
        );
    };

    return (
        <>
            {showHeader === true ? (
                <Innerpageheading
                    pageHeadInfo={wetlandDeterminationPageHeader()}
                />
            ) : null}

            {showBreadcrum === true ? (
                <Breadcrumb breadcrumbInfo={wetlandDeterminationBreadcrumb()} />
            ) : null}

            <div
                className={
                    showHeader === false
                        ? "pt-4 px-5 px-sm-4 px-md-3 px-lg-4 px-xxl-0"
                        : "container pt-6 pb-5 px-5 px-sm-4 px-md-3 px-lg-4 px-xxl-0"
                }
            >
                <ServiceDetails
                    title={title}
                    bannerUrl={bannerImage}
                    availableFor={availableFor}
                    certified={certified}
                    showTextExpand={showTextExpand}
                    textContent={<TextContent></TextContent>}
                    getStartedUrl={getStartedUrl}
                ></ServiceDetails>
            </div>

            {showHeader === true ? (
                <div className="row contact-loaction">
                    <Contact />
                </div>
            ) : null}
        </>
    );
};

export default WetlandDetermination;
