import Innerpageheading from "../../components/common/innerpageheading";
import { getStarted } from "../../components/common/breadcrumbs";
import { getStartedPageHeader } from "../../components/common/pageheader";
import SearchCard from "../../components/home/searchcard";
import Breadcrumb from "../../components/common/breadcrumb";
import Contact from "../../components/common/contact";

export default function GetStarted() {
    const nameSearch = {
        badge: "/img/corporate-ucc.svg",
        title: "Request an Account",
        desc: "Ready to get started with Charles Jones? Please submit the information below.",
        link: "/request-account",
        buttonText: "Request Account",
    };

    const taxSearch = {
        badge: "/img/name-badge.svg",
        title: "New Charles Jones ID",
        desc: "Need to request a new Charles Jones ID? Please submit the information below.",
        link: "/request-id",
        buttonText: "Request ID",
    };

    return (
        <div>
            <Innerpageheading pageHeadInfo={getStartedPageHeader()} />
            <Breadcrumb breadcrumbInfo={getStarted()} />

            <div
                className="row justify-content-center px-0 px-md-5 px-lg-0 xl-max-size mb-5"
                style={{ marginTop: "15px" }}
            >
                <div className="col-10 col-sm-10 col-md-6 col-lg-5 col-xl-4 mt-5">
                    <SearchCard cardInfo={nameSearch} />
                </div>
                <div className="col-10 col-sm-10 col-md-6 col-lg-5 col-xl-4 mt-5">
                    <SearchCard cardInfo={taxSearch} />
                </div>
            </div>

            <div className="row contact-loaction">
                <Contact />
            </div>
        </div>
    );
}
