import React from "react";
import { Toast } from "react-bootstrap";

const Toasts = ({
    setShowSuccessToast,
    showSuccessToast,
    setShowErrorToast,
    showErrorToast,
}) => {
    return (
        <div>
            <div className="toast-floater">
                <Toast
                    onClose={() => setShowSuccessToast(false)}
                    show={showSuccessToast}
                    delay={3000}
                    className="p-3 cj-toast-bg"
                    autohide
                >
                    <Toast.Body className="p-2 cj-toast-text">
                        Thank you for contacting us. Our team will reach out as
                        soon as possible.
                    </Toast.Body>
                </Toast>
            </div>

            <div className="toast-floater">
                <Toast
                    onClose={() => setShowErrorToast(false)}
                    show={showErrorToast}
                    delay={10000}
                    className="p-3 cj-toast-bg-error"
                    autohide
                >
                    <Toast.Body className="py-3 px-2 cj-toast-text-error">
                        We encountered an error while delivering your message.
                        Please contact us directly for assistance.
                    </Toast.Body>
                </Toast>
            </div>
        </div>
    );
};

export default Toasts;
